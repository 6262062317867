<template>
  <div>
   <!-- Modal Revise Request -->
   <b-modal
  id="modal-revise-request"
  v-model="modalRevise"
  cancel-variant="outline-secondary"
  hide-footer
  centered
  title="Revise Request"
>
  <b-form @submit.prevent="sendRevise">
    <div>
      <div class="text-center">
        <p>Are you sure you want to request a revise?</p>
        <b-button type="submit" variant="primary" class="mr-2">Yes</b-button>
        <b-button @click="modalRevise = false" variant="secondary">No</b-button>
      </div>
    </div>
  </b-form>
</b-modal>
    <!-- Modal Messages-->
    <b-modal
      id="modal-messages"
      v-model="modalMessage"
      cancel-variant="outline-secondary"
      hide-footer
      centered
      title="Messages"
    >
      <b-form>
        <div>
          <div class="messages">
            <ul
              :ref="messageListRef"
              v-if="messagesList.length > 0"
              class="list-group"
              style="height:300pxwidth:100%overflow-y:scroll"
            >
              <li
                v-for="item in messagesList"
                :key="item"
                class="list-group-item"
              >
                <div
                  v-if="userId.toString() == item.user_id.toString()"
                  class="float-right"
                >
                  <h5>Me</h5>
                  <span style="font-size:9px">{{
                    dateSimple(
                      item.chat_created_time,
                      "YYYY-MM-DD HH:mm:ss"
                    )
                  }}</span>
                  <p>{{ item.chat_text }}</p>
                </div>
                <div
                  v-if="userId.toString() != item.user_id.toString()"
                  class="float-left"
                >
                  <h5>{{ item.user_fullname }}</h5>
                  <span style="font-size:9px">{{
                    dateSimple(
                      item.chat_created_time,
                      "YYYY-MM-DD HH:mm:ss"
                    )
                  }}</span>
                  <p>{{ item.chat_text }}</p>
                </div>
              </li>
            </ul>
            <div v-if="messagesList.length == 0">
              No message
            </div>
            <br />
            <form id="send_message_form" method="post" action="">
              <b-row>
                <b-col cols="11" md="9">
                  <input
                    v-model="messageBox"
                    type="text"
                    class="form-control"
                    placeholder="Write your message"
                  />
                </b-col>
                <b-col cols="11" md="3"
                  ><input
                    type="button"
                    class="btn btn-primary pull-right"
                    value="Send"
                    @click="sendMessage()"
                /></b-col>
              </b-row>
            </form>
          </div>
        </div>
      </b-form>
    </b-modal>

    <!-- Modal Upload-->
    <b-modal
      id="modal-upload"
      v-model="modalUpload"
      cancel-variant="outline-secondary"
      hide-footer
      centered
      title="Documents"
    >
      <b-form>
        <div>
          <div class="messages">
            <ul
              v-if="documentsList.length > 0"
              class="list-group"
              style="height:300pxwidth:100%overflow-y:scroll"
            >
              <input
                id="materialRequestId"
                ref="invoiceMessage"
                v-model="materialRequestId"
                type="hidden"
                name="materialRequestId"
              />
              <li
  v-for="item in documentsList"
  :key="item"
  class="list-group-item d-flex justify-content-between align-items-center"
  style="cursor: pointer;"
>
  <span style="font-size:9px">{{ item.document_file_name }}</span>
  <div>
    <b-button
  :disabled="item.document_old"
  variant="primary"
  size="sm"
  class="mr-1"
  @click.stop="downloadAttachment(item)"
>
      <feather-icon icon="DownloadIcon" />
    </b-button>
    <b-button variant="danger" size="sm" @click.stop="deleteAttachment(item)">
      <feather-icon icon="TrashIcon" />
    </b-button>
  </div>
</li>
            </ul>
            <div v-if="documentsList.length == 0">
              No Files
            </div>
            <br />
            <form
              id="send_file_form"
              method="post"
              @submit.prevent="sendfiles"
              action=""
            >
              <b-row>
                <b-col cols="11" md="9">
          <b-form-file
            id="file"
            ref="uploadFile"
            placeholder="Choose one file or drop it here..."
drop-placeholder="Drop a file here..."
type="file"
@change="handleFilesUpload"
          />
          <div class="alert alert-info" role="alert">
                Max file upload is 2MB
              </div>
                </b-col>
                <b-col cols="11" md="3"
                  ><input
                    type="button"
                    class="btn btn-primary pull-right"
                    value="Send"
                    @click="sendDocument()"
                /></b-col>
              </b-row>
            </form>
          </div>
        </div>
      </b-form>
    </b-modal>
    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <b-row>
      <b-col cols="12" md="12">
        </b-col>
      <b-col cols="12" md="12">
                <div class="float-right add-btn">
                  <b-button
                    v-if="hasPermission('purchase_request_create') == true"
                    variant="primary"
                    @click="addNew()"
                  >
                    <span class="text-nowrap">Add Purchase Request</span>
                  </b-button>
                </div>
              </b-col>
              </b-row>
      <b-tabs>
        <b-tab title="Local">
      <b-tabs>
        <b-tab title="Asset/FGS">
      <b-tabs>
        <b-tab title="Needs Approval">
          <div class="m-2">
            <!-- Table Top -->
            <b-row>
              <b-col cols="5">
                <label>Tanggal Awal</label>
                <b-form-datepicker id="start-datepicker" v-model="startDateFilterAssetsFGSNA" class="mb-2"></b-form-datepicker>
              </b-col>
              <b-col cols="5">
                <label>Tanggal Akhir</label>
                <b-form-datepicker id="end-datepicker" v-model="endDateFilterAssetsFGSNA" class="mb-2"></b-form-datepicker>
              </b-col>
              <b-col cols="2" class="d-flex align-items-center justify-content-center">
                <b-button variant="primary" @click="exportPR('Assets/FGSNA')" :disabled="isLoadingExportAssetsFGS">
                  <feather-icon icon="DownloadIcon" /> Export
                  <b-spinner small type="grow" v-if="isLoadingExportAssetsFGS"></b-spinner>
                </b-button>
              </b-col>
            </b-row>
            <b-row>
              <!-- Per Page -->
              <b-col
                cols="12"
                md="6"
                class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
              >
                <label>Show</label>
                <v-select
                  v-model="perPageAssetsFGSNA"
                  :options="perPageOptions"
                  :clearable="false"
                  class="per-page-selector d-inline-block mx-50"
                />
                <label>entries</label>
              </b-col>

              <!-- Search -->
              <b-col cols="12" md="6">
                <div class="d-flex align-items-center justify-content-end">
                  <b-form-input
                    v-model="searchQueryAssetsFGSNA"
                    class="d-inline-block mr-1"
                    placeholder="Search..."
                  />
                </div>
              </b-col>
            </b-row>
          </div>
          <b-table
            ref="refPurchaseRequestListTableAssetsFGSNA"
            class="position-relative"
            :items="fetchPurchaseRequestsAssetsFGSNA"
            responsive
            :fields="tableColumnsAssetsFGSNA"
            primary-key="id"
            :sort-by.sync="sortByAssetsFGSNA"
            show-empty
            empty-text="No matching records found"
            :sort-desc.sync="isSortDirDescAssetsFGSNA"
          >
            <!-- Column: Number -->
            <template #cell(Number)="data">
              <div class="text-nowrap">
                <feather-icon icon="RefreshCwIcon" v-if="data.item.purchase_request_is_revised==true" class="danger"/>
                <feather-icon
                  v-if="data.item.purchase_request_is_confirmed_by_laboratory_manager == null"
                  icon="CircleIcon"
                  class="default"
                />
                <feather-icon
                  v-if="
                    data.item.purchase_request_is_confirmed_by_laboratory_manager != null &&
                      data.item.purchase_request_is_finished == null
                  "
                  icon="CircleIcon"
                  class="danger"
                />
                <feather-icon
                  v-if="
                    data.item.purchase_request_is_confirmed_by_fa_coal_manager != null &&
                      data.item.purchase_request_is_finished == null
                  "
                  icon="CircleIcon"
                  class="warning"
                />
                <feather-icon
                  v-if="
                    data.item.purchase_request_is_confirmed_by_president_director != null &&
                      data.item.purchase_request_is_finished == null
                  "
                  icon="CircleIcon"
                  class="primary"
                />
                <feather-icon
                  v-if="
                    data.item.purchase_request_is_confirmed_by_laboratory_manager != null &&
                      data.item.purchase_request_is_finished == true
                  "
                  icon="CircleIcon"
                  class="success"
                />
                <feather-icon
                  v-if="data.item.chats !=null"
                  style="color:#036bfc"
                  icon="MessageCircleIcon"
                />
                <feather-icon
                v-if="data.item.purchase_request_has_files == true"
                icon="FileIcon" />&nbsp;
                <span class="align-text-top text-capitalize">{{
                  data.item.purchase_request_number
                }}</span>
              </div>
            </template>
            <!-- Column: Class -->
            <template #cell(Class)="data">
              <div class="text-nowrap">
                <span class="align-text-top text-capitalize">{{
                  data.item.purchase_request_class
                }}</span>
              </div>
            </template>
            <!-- Column: Category -->
            <template #cell(Category)="data">
              <div class="text-nowrap">
                <feather-icon size="18" class="mr-50" />
                <span class="align-text-top text-capitalize">{{
                  data.item.purchase_request_category
                }}</span>
              </div>
            </template>
            <!-- Column: Type -->
            <template #cell(Type)="data">
              {{
                data.item.purchase_request_is_center == true
                  ? "Head Office"
                  : "Cabang"
              }}
            </template>
            <!-- Column: Cost Center -->
            <template #cell(CostCenter)="data">
              ({{ data.item.cost_center ? data.item.cost_center.branch_code : '-' }})
              {{ data.item.cost_center ? data.item.cost_center.branch_city : '-' }}
            </template>
            <!-- Column: Notes -->
            <template #cell(Notes)="data">
              {{ truncateString(data.item.purchase_request_notes, 30) }}
            </template>
            <!-- Column: Status -->
            <template #cell(Status)="data">
              <b-badge pill class="text-capitalize">
                {{
                  data.item.purchase_request_is_confirmed
                    ? "CONFIRMED"
                    : "UNCONFIRMED"
                }}
              </b-badge>
            </template>
            <!-- Column: DateCreated -->
            <template #cell(DateCreated)="data">
              {{
                dateSimple(
                  data.item.purchase_request_created_time,
                  "YYYY-MM-DD HH:mm:ss"
                )
              }}
            </template>
            <!-- Column: Actions -->
            <template #cell(actions)="data">
              <b-dropdown
                variant="link"
                no-caret
                :right="$store.state.appConfig.isRTL"
              >
                <template #button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="align-middle text-body"
                  />
                </template>
                <b-dropdown-item @click="showDetail(data.item.purchase_request_id)">
                  <feather-icon icon="ZoomInIcon" />
                  <span class="align-middle ml-50">Detail</span>
                </b-dropdown-item>
                <b-dropdown-item @click="showReviseLog(data.item)">
                  <feather-icon icon="RefreshCwIcon" />
                  <span class="align-middle ml-50">Show Revise Log</span>
                </b-dropdown-item>
                <b-dropdown-item
  @click="setRevise(data.item.purchase_request_id)"
  v-if="checkRevise(data.item) && ['president-director', 'vice-president', 'fa-coal-manager', 'operation-director', 'division-head', 'regional-manager', 'laboratory-manager'].includes(userRole)">
  <feather-icon icon="RefreshCwIcon" />
  <span class="align-middle ml-50">Revise</span>
</b-dropdown-item>
          <b-dropdown-item
                  v-if="
                    hasPermission('purchase_request_update') == true &&
                      data.item.purchase_request_is_confirmed_by_purchasing_cabang == null && data.item.purchase_request_is_confirmed_by_laboratory_manager == null
                  "
                  @click="editPurchaseRequest(data.item)"
                >
                  <feather-icon icon="EditIcon" />
                  <span class="align-middle ml-50">Edit</span>
                </b-dropdown-item>
                <b-dropdown-item
                  @click="showMessages(data.item)"
                >
                  <feather-icon icon="MessageCircleIcon" />
                  <span class="align-middle ml-50">Chat</span>
                </b-dropdown-item>
                <b-dropdown-item
                  @click="showDocuments(data.item.purchase_request_id)"
                >
                  <feather-icon icon="FileIcon" />
                  <span class="align-middle ml-50">Attachments</span>
                </b-dropdown-item>
                <!-- Confirm Assets/FGS/Supplies By Purchasing -->
                <b-dropdown-item
                  v-if="
                    hasPermission('purchase_request_confirm_purchasing') == true &&
                    data.item.purchase_request_is_center == false &&
                    ( data.item.purchase_request_category == 'FGS' || data.item.purchase_request_category == 'Assets' || data.item.purchase_request_category == 'Supplies')  &&
                    data.item.purchase_request_is_confirmed_by_purchasing_cabang == null
                  "
                  @click="
                    confirmPurchaseRequestByPurchasing(
                      data.item.purchase_request_id
                    )
                  "
                >
                  <feather-icon icon="CheckIcon" />
                  <span class="align-middle ml-50">Confirm</span>
                </b-dropdown-item>
                <!-- Confirm Supplies By Lab Manager-->
                <b-dropdown-item
                  v-if="
                    hasPermission('purchase_request_confirm_lab_manager') == true &&
                    data.item.purchase_request_is_center == false &&
                    data.item.purchase_request_category == 'Supplies' &&
                    data.item.purchase_request_is_confirmed_by_purchasing_cabang != null &&
                      data.item
                        .purchase_request_is_confirmed_by_laboratory_manager == null
                  "
                  @click="
                    confirmPurchaseRequestByLabManager(
                      data.item.purchase_request_id
                    )
                  "
                >
                  <feather-icon icon="CheckIcon" />
                  <span class="align-middle ml-50">Confirm</span>
                </b-dropdown-item>
                <!-- Confirm Assets/FGS By Lab Manager -->
                <b-dropdown-item
                  v-if="
                    hasPermission('purchase_request_confirm_lab_manager') == true &&
                    data.item.purchase_request_is_center == false &&
                    ( data.item.purchase_request_category == 'FGS' || data.item.purchase_request_category == 'Assets')  &&
                    data.item.purchase_request_is_confirmed_by_purchasing_cabang != null &&
                      data.item
                        .purchase_request_is_confirmed_by_laboratory_manager == null
                  "
                  @click="
                    confirmPurchaseRequestByLabManager(
                      data.item.purchase_request_id
                    )
                  "
                >
                  <feather-icon icon="CheckIcon" />
                  <span class="align-middle ml-50">Confirm</span>
                </b-dropdown-item>
                <!-- Confirm HO By Lab Manager -->
                <b-dropdown-item
                  v-if="
                    hasPermission('purchase_request_confirm_lab_manager') == true &&
                    data.item.purchase_request_is_center == true &&
                      data.item
                        .purchase_request_is_confirmed_by_laboratory_manager == null
                  "
                  @click="
                    confirmPurchaseRequestByLabManager(
                      data.item.purchase_request_id
                    )
                  "
                >
                  <feather-icon icon="CheckIcon" />
                  <span class="align-middle ml-50">Confirm</span>
                </b-dropdown-item>
                <!-- Confirm Assets/FGS By Regional Manager -->
                <b-dropdown-item
                  v-if="
                    hasPermission('purchase_request_confirm_regional_manager') == true &&
                    data.item.purchase_request_is_center == false &&
                    ( data.item.purchase_request_category == 'FGS' || data.item.purchase_request_category == 'Assets')  &&
                    data.item.purchase_request_is_confirmed_by_purchasing_cabang != null &&
                      data.item.purchase_request_is_confirmed_by_laboratory_manager != null &&
                      data.item.purchase_request_is_confirmed_by_regional_manager == null
                  "
                  @click="
                    confirmPurchaseRequestByRegionalManager(
                      data.item.purchase_request_id
                    )
                  "
                >
                  <feather-icon icon="CheckIcon" />
                  <span class="align-middle ml-50">Confirm</span>
                </b-dropdown-item>
                <!-- Confirm Assets/FGS By Division Head -->
                <b-dropdown-item
                  v-if="
                    hasPermission('purchase_request_confirm_division_head') == true &&
                    data.item.purchase_request_is_center == false &&
                    ( data.item.purchase_request_category == 'FGS' || data.item.purchase_request_category == 'Assets')  &&
                    data.item.purchase_request_is_confirmed_by_purchasing_cabang != null &&
                      data.item.purchase_request_is_confirmed_by_laboratory_manager != null &&
                      data.item.purchase_request_is_confirmed_by_regional_manager != null &&
                      data.item.purchase_request_is_confirmed_by_division_head == null
                  "
                  @click="
                    confirmPurchaseRequestByDivisionHead(
                      data.item.purchase_request_id
                    )
                  "
                >
                  <feather-icon icon="CheckIcon" />
                  <span class="align-middle ml-50">Confirm</span>
                </b-dropdown-item>
                <!-- Confirm Assets/FGS By FA Coal Manager -->
                <b-dropdown-item
                  v-if="
                    hasPermission('purchase_request_confirm_fa_coal_manager') ==
                      true &&
                      data.item.purchase_request_is_center == false &&
                    ( data.item.purchase_request_category == 'FGS' || data.item.purchase_request_category == 'Assets')  &&
                    data.item.purchase_request_is_confirmed_by_purchasing_cabang != null &&
                      data.item.purchase_request_is_confirmed_by_laboratory_manager != null &&
                      data.item.purchase_request_is_confirmed_by_regional_manager != null &&
                      data.item.purchase_request_is_confirmed_by_division_head != null&&
                      data.item.purchase_request_is_confirmed_by_fa_coal_manager == null
                  "
                  @click="
                    confirmPurchaseRequestByFACoalManager(
                      data.item.purchase_request_id
                    )
                  "
                >
                  <feather-icon icon="CheckIcon" />
                  <span class="align-middle ml-50">Confirm</span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="
                    hasPermission('purchase_request_confirm_regional_manager') ==
                      true &&
                      data.item.purchase_request_is_center == true &&
                      data.item
                        .purchase_request_is_confirmed_by_laboratory_manager !=
                        null &&
                      data.item.purchase_request_is_confirmed_by_regional_manager ==
                        null
                  "
                  @click="
                    confirmPurchaseRequestByRegionalManager(
                      data.item.purchase_request_id
                    )
                  "
                >
                  <feather-icon icon="CheckIcon" />
                  <span class="align-middle ml-50">Confirm</span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="
                    hasPermission('purchase_request_confirm_division_head') ==
                      true &&
                      data.item.purchase_request_is_center == true &&
                      data.item
                        .purchase_request_is_confirmed_by_laboratory_manager !=
                        null &&
                      data.item.purchase_request_is_confirmed_by_regional_manager !=
                        null &&
                      data.item.purchase_request_is_confirmed_by_division_head ==
                        null
                  "
                  @click="
                    confirmPurchaseRequestByDivisionHead(
                      data.item.purchase_request_id
                    )
                  "
                >
                  <feather-icon icon="CheckIcon" />
                  <span class="align-middle ml-50">Confirm</span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="
                    hasPermission('purchase_request_confirm_fa_coal_manager') ==
                      true &&
                      data.item.purchase_request_is_center == true &&
                      data.item
                        .purchase_request_is_confirmed_by_laboratory_manager !=
                        null &&
                      data.item.purchase_request_is_confirmed_by_regional_manager !=
                        null &&
                      data.item.purchase_request_is_confirmed_by_division_head !=
                        null &&
                      data.item.purchase_request_is_confirmed_by_fa_coal_manager ==
                        null
                  "
                  @click="
                    confirmPurchaseRequestByFACoalManager(
                      data.item.purchase_request_id
                    )
                  "
                >
                  <feather-icon icon="CheckIcon" />
                  <span class="align-middle ml-50">Confirm</span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="
                    hasPermission('purchase_request_confirm_operation_director') ==
                      true &&
                      data.item.purchase_request_is_center == true &&
                      data.item
                        .purchase_request_is_confirmed_by_laboratory_manager !=
                        null &&
                      data.item.purchase_request_is_confirmed_by_regional_manager !=
                        null &&
                      data.item.purchase_request_is_confirmed_by_division_head !=
                        null &&
                      data.item.purchase_request_is_confirmed_by_fa_coal_manager !=
                        null &&
                      data.item
                        .purchase_request_is_confirmed_by_operation_director == null
                  "
                  @click="
                    confirmPurchaseRequestByOperationDirector(
                      data.item.purchase_request_id
                    )
                  "
                >
                  <feather-icon icon="CheckIcon" />
                  <span class="align-middle ml-50">Confirm</span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="
                    hasPermission('purchase_request_confirm_vice_president') ==
                      true &&
                      data.item.purchase_request_is_center == true &&
                      data.item
                        .purchase_request_is_confirmed_by_laboratory_manager !=
                        null &&
                      data.item.purchase_request_is_confirmed_by_regional_manager !=
                        null &&
                      data.item.purchase_request_is_confirmed_by_division_head !=
                        null &&
                      data.item.purchase_request_is_confirmed_by_fa_coal_manager !=
                        null &&
                      data.item
                        .purchase_request_is_confirmed_by_operation_director !=
                        null &&
                      data.item.purchase_request_is_confirmed_by_vice_president ==
                        null
                  "
                  @click="
                    confirmPurchaseRequestByVicePresident(
                      data.item.purchase_request_id
                    )
                  "
                >
                  <feather-icon icon="CheckIcon" />
                  <span class="align-middle ml-50">Confirm</span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="
                    hasPermission('purchase_request_confirm_president_director') ==
                      true &&
                      data.item.purchase_request_is_center == true &&
                      data.item
                        .purchase_request_is_confirmed_by_laboratory_manager !=
                        null &&
                      data.item.purchase_request_is_confirmed_by_regional_manager !=
                        null &&
                      data.item.purchase_request_is_confirmed_by_division_head !=
                        null &&
                      data.item.purchase_request_is_confirmed_by_fa_coal_manager !=
                        null &&
                      data.item
                        .purchase_request_is_confirmed_by_operation_director !=
                        null &&
                      data.item.purchase_request_is_confirmed_by_vice_president !=
                        null &&
                      data.item
                        .purchase_request_is_confirmed_by_president_director == null
                  "
                  @click="
                    confirmPurchaseRequestByPresidentDirector(
                      data.item.purchase_request_id
                    )
                  "
                >
                  <feather-icon icon="CheckIcon" />
                  <span class="align-middle ml-50">Confirm</span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="
                    hasPermission('purchase_request_confirm_procurement_pusat') ==
                      true &&
                      data.item.purchase_request_is_center == true &&
                      data.item
                        .purchase_request_is_confirmed_by_laboratory_manager !=
                        null &&
                      data.item.purchase_request_is_confirmed_by_regional_manager !=
                        null &&
                      data.item.purchase_request_is_confirmed_by_division_head !=
                        null &&
                      data.item.purchase_request_is_confirmed_by_fa_coal_manager !=
                        null &&
                      data.item
                        .purchase_request_is_confirmed_by_operation_director !=
                        null &&
                      data.item.purchase_request_is_confirmed_by_vice_president !=
                        null &&
                      data.item
                        .purchase_request_is_confirmed_by_president_director !=
                        null &&
                      data.item
                        .purchase_request_is_confirmed_by_procurement_pusat == null
                  "
                  @click="
                    confirmPurchaseRequestByProcurementPusat(
                      data.item.purchase_request_id
                    )
                  "
                >
                  <feather-icon icon="CheckIcon" />
                  <span class="align-middle ml-50">Confirm</span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="userRole=='administrator'"
                  @click="deletePurchaseRequest(data.item.purchase_request_id)"
                >
                  <feather-icon icon="TrashIcon" />
                  <span class="align-middle ml-50">Delete</span>
                </b-dropdown-item>
              </b-dropdown>
            </template>
          </b-table>
          <div class="mx-2 mb-2">
            <b-row>
              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-start"
              >
                <span class="text-muted"
                  >Showing {{ dataMeta.fromAssetsFGSNA }} to {{ dataMeta.toAssetsFGSNA}} of
                  {{ dataMeta.ofAssetsFGSNA }} entries</span
                >
                <span style="padding-left: 10px;">
                <feather-icon
                  icon="CircleIcon"
                  class="default"
                /> = Just Created,
                <feather-icon
                  icon="CircleIcon"
                  class="danger"
                /> =  Accepted By Lab Manager,
                <feather-icon
                  icon="CircleIcon"
                  class="warning"
                /> =  Accepted By FA Coal Manager,
                <feather-icon
                  icon="CircleIcon"
                  class="primary"
                /> =  Accepted By President Director
                <feather-icon
                  icon="CircleIcon"
                  class="success"
                />
                 = Finished,
                <feather-icon
                  style="color:#036bfc"
                  icon="MessageCircleIcon"
                /> = Message,
                <feather-icon
                icon="FileIcon" /> = Attachment
              </span>
              </b-col>
              <!-- Pagination -->
              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-end"
              >
                <b-pagination
                  v-model="currentPageAssetsFGSNA"
                  :total-rows="totalPurchaseRequestsAssetsFGSNA"
                  :per-page="perPageAssetsFGSNA"
                  first-number
                  last-number
                  class="mb-0 mt-1 mt-sm-0"
                  prev-class="prev-item"
                  next-class="next-item"
                >
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </b-col>
            </b-row>
          </div>
        </b-tab>
        <b-tab title="On Going">
          <div class="m-2">
            <!-- Table Top -->
            <b-row>
              <b-col cols="5">
                <label>Tanggal Awal</label>
                <b-form-datepicker id="start-datepicker" v-model="startDateFilterAssetsFGS" class="mb-2"></b-form-datepicker>
              </b-col>
              <b-col cols="5">
                <label>Tanggal Akhir</label>
                <b-form-datepicker id="end-datepicker" v-model="endDateFilterAssetsFGS" class="mb-2"></b-form-datepicker>
              </b-col>
              <b-col cols="2" class="d-flex align-items-center justify-content-center">
                <b-button variant="primary" @click="exportPR('Assets/FGS')" :disabled="isLoadingExportAssetsFGS">
                  <feather-icon icon="DownloadIcon" /> Export
                  <b-spinner small type="grow" v-if="isLoadingExportAssetsFGS"></b-spinner>
                </b-button>
              </b-col>
            </b-row>
            <b-row>
              <!-- Per Page -->
              <b-col
                cols="12"
                md="6"
                class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
              >
                <label>Show</label>
                <v-select
                  v-model="perPageAssetsFGS"
                  :options="perPageOptions"
                  :clearable="false"
                  class="per-page-selector d-inline-block mx-50"
                />
                <label>entries</label>
              </b-col>

              <!-- Search -->
              <b-col cols="12" md="6">
                <div class="d-flex align-items-center justify-content-end">
                  <b-form-input
                    v-model="searchQueryAssetsFGS"
                    class="d-inline-block mr-1"
                    placeholder="Search..."
                  />
                </div>
              </b-col>
            </b-row>
          </div>
          <b-table
            ref="refPurchaseRequestListTableAssetsFGS"
            class="position-relative"
            :items="fetchPurchaseRequestsAssetsFGS"
            responsive
            :fields="tableColumnsAssetsFGS"
            primary-key="id"
            :sort-by.sync="sortByAssetsFGS"
            show-empty
            empty-text="No matching records found"
            :sort-desc.sync="isSortDirDescAssetsFGS"
          >
            <!-- Column: Number -->
            <template #cell(Number)="data">
              <div class="text-nowrap"><feather-icon icon="RefreshCwIcon" v-if="data.item.purchase_request_is_revised==true" class="danger"/>
                <feather-icon
                  v-if="data.item.purchase_request_is_confirmed_by_laboratory_manager == null"
                  icon="CircleIcon"
                  class="default"
                />
                <feather-icon
                  v-if="
                    data.item.purchase_request_is_confirmed_by_laboratory_manager != null &&
                      data.item.purchase_request_is_finished == null
                  "
                  icon="CircleIcon"
                  class="danger"
                />
                <feather-icon
                  v-if="
                    data.item.purchase_request_is_confirmed_by_fa_coal_manager != null &&
                      data.item.purchase_request_is_finished == null
                  "
                  icon="CircleIcon"
                  class="warning"
                />
                <feather-icon
                  v-if="
                    data.item.purchase_request_is_confirmed_by_president_director != null &&
                      data.item.purchase_request_is_finished == null
                  "
                  icon="CircleIcon"
                  class="primary"
                />
                <feather-icon
                  v-if="
                    data.item.purchase_request_is_confirmed_by_laboratory_manager != null &&
                      data.item.purchase_request_is_finished == true
                  "
                  icon="CircleIcon"
                  class="success"
                />
                <feather-icon
                  v-if="data.item.chats !=null"
                  style="color:#036bfc"
                  icon="MessageCircleIcon"
                />
                <feather-icon
                v-if="data.item.purchase_request_has_files == true"
                icon="FileIcon" />&nbsp;
                <span class="align-text-top text-capitalize">{{
                  data.item.purchase_request_number
                }}</span>
              </div>
            </template>
            <!-- Column: Class -->
            <template #cell(Class)="data">
              <div class="text-nowrap">
                <span class="align-text-top text-capitalize">{{
                  data.item.purchase_request_class
                }}</span>
              </div>
            </template>
            <!-- Column: Category -->
            <template #cell(Category)="data">
              <div class="text-nowrap">
                <feather-icon size="18" class="mr-50" />
                <span class="align-text-top text-capitalize">{{
                  data.item.purchase_request_category
                }}</span>
              </div>
            </template>
            <!-- Column: Type -->
            <template #cell(Type)="data">
              {{
                data.item.purchase_request_is_center == true
                  ? "Head Office"
                  : "Cabang"
              }}
            </template>
            <!-- Column: Cost Center -->
            <template #cell(CostCenter)="data">
              ({{ data.item.cost_center ? data.item.cost_center.branch_code : '-' }})
              {{ data.item.cost_center ? data.item.cost_center.branch_city : '-' }}
            </template>
            <!-- Column: Notes -->
            <template #cell(Notes)="data">
              {{ truncateString(data.item.purchase_request_notes, 30) }}
            </template>
            <!-- Column: Status -->
            <template #cell(Status)="data">
              <b-badge pill class="text-capitalize">
                {{
                  data.item.purchase_request_is_confirmed
                    ? "CONFIRMED"
                    : "UNCONFIRMED"
                }}
              </b-badge>
            </template>
            <!-- Column: DateCreated -->
            <template #cell(DateCreated)="data">
              {{
                dateSimple(
                  data.item.purchase_request_created_time,
                  "YYYY-MM-DD HH:mm:ss"
                )
              }}
            </template>
            <!-- Column: Actions -->
            <template #cell(actions)="data">
              <b-dropdown
                variant="link"
                no-caret
                :right="$store.state.appConfig.isRTL"
              >
                <template #button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="align-middle text-body"
                  />
                </template>

                <b-dropdown-item @click="showDetail(data.item.purchase_request_id)">
                  <feather-icon icon="ZoomInIcon" />
                  <span class="align-middle ml-50">Detail</span>
                </b-dropdown-item>
                <b-dropdown-item @click="showReviseLog(data.item)">
                  <feather-icon icon="RefreshCwIcon" />
                  <span class="align-middle ml-50">Show Revise Log</span>
                </b-dropdown-item>
                <b-dropdown-item
  @click="setRevise(data.item.purchase_request_id)"
  v-if="checkRevise(data.item) && ['president-director', 'vice-president', 'fa-coal-manager', 'operation-director', 'division-head', 'regional-manager', 'laboratory-manager'].includes(userRole)">
  <feather-icon icon="RefreshCwIcon" />
  <span class="align-middle ml-50">Revise</span>
</b-dropdown-item>
          <b-dropdown-item
                  v-if="
                    hasPermission('purchase_request_update') == true &&
                      data.item.purchase_request_is_confirmed_by_purchasing_cabang == null && data.item.purchase_request_is_confirmed_by_laboratory_manager == null
                  "
                  @click="editPurchaseRequest(data.item)"
                >
                  <feather-icon icon="EditIcon" />
                  <span class="align-middle ml-50">Edit</span>
                </b-dropdown-item>
                <b-dropdown-item
                  @click="showMessages(data.item)"
                >
                  <feather-icon icon="MessageCircleIcon" />
                  <span class="align-middle ml-50">Chat</span>
                </b-dropdown-item>
                <b-dropdown-item
                  @click="showDocuments(data.item.purchase_request_id)"
                >
                  <feather-icon icon="FileIcon" />
                  <span class="align-middle ml-50">Attachments</span>
                </b-dropdown-item>
                <!-- Confirm Assets/FGS/Supplies By Purchasing -->
                <b-dropdown-item
                  v-if="
                    hasPermission('purchase_request_confirm_purchasing') == true &&
                    data.item.purchase_request_is_center == false &&
                    ( data.item.purchase_request_category == 'FGS' || data.item.purchase_request_category == 'Assets' || data.item.purchase_request_category == 'Supplies')  &&
                    data.item.purchase_request_is_confirmed_by_purchasing_cabang == null
                  "
                  @click="
                    confirmPurchaseRequestByPurchasing(
                      data.item.purchase_request_id
                    )
                  "
                >
                  <feather-icon icon="CheckIcon" />
                  <span class="align-middle ml-50">Confirm</span>
                </b-dropdown-item>
                <!-- Confirm Supplies By Lab Manager-->
                <b-dropdown-item
                  v-if="
                    hasPermission('purchase_request_confirm_lab_manager') == true &&
                    data.item.purchase_request_is_center == false &&
                    data.item.purchase_request_category == 'Supplies' &&
                    data.item.purchase_request_is_confirmed_by_purchasing_cabang != null &&
                      data.item
                        .purchase_request_is_confirmed_by_laboratory_manager == null
                  "
                  @click="
                    confirmPurchaseRequestByLabManager(
                      data.item.purchase_request_id
                    )
                  "
                >
                  <feather-icon icon="CheckIcon" />
                  <span class="align-middle ml-50">Confirm</span>
                </b-dropdown-item>
                <!-- Confirm Assets/FGS By Lab Manager -->
                <b-dropdown-item
                  v-if="
                    hasPermission('purchase_request_confirm_lab_manager') == true &&
                    data.item.purchase_request_is_center == false &&
                    ( data.item.purchase_request_category == 'FGS' || data.item.purchase_request_category == 'Assets')  &&
                    data.item.purchase_request_is_confirmed_by_purchasing_cabang != null &&
                      data.item
                        .purchase_request_is_confirmed_by_laboratory_manager == null
                  "
                  @click="
                    confirmPurchaseRequestByLabManager(
                      data.item.purchase_request_id
                    )
                  "
                >
                  <feather-icon icon="CheckIcon" />
                  <span class="align-middle ml-50">Confirm</span>
                </b-dropdown-item>
                <!-- Confirm HO By Lab Manager -->
                <b-dropdown-item
                  v-if="
                    hasPermission('purchase_request_confirm_lab_manager') == true &&
                    data.item.purchase_request_is_center == true &&
                      data.item
                        .purchase_request_is_confirmed_by_laboratory_manager == null
                  "
                  @click="
                    confirmPurchaseRequestByLabManager(
                      data.item.purchase_request_id
                    )
                  "
                >
                  <feather-icon icon="CheckIcon" />
                  <span class="align-middle ml-50">Confirm</span>
                </b-dropdown-item>
                <!-- Confirm Assets/FGS By Regional Manager -->
                <b-dropdown-item
                  v-if="
                    hasPermission('purchase_request_confirm_regional_manager') == true &&
                    data.item.purchase_request_is_center == false &&
                    ( data.item.purchase_request_category == 'FGS' || data.item.purchase_request_category == 'Assets')  &&
                    data.item.purchase_request_is_confirmed_by_purchasing_cabang != null &&
                      data.item.purchase_request_is_confirmed_by_laboratory_manager != null &&
                      data.item.purchase_request_is_confirmed_by_regional_manager == null
                  "
                  @click="
                    confirmPurchaseRequestByRegionalManager(
                      data.item.purchase_request_id
                    )
                  "
                >
                  <feather-icon icon="CheckIcon" />
                  <span class="align-middle ml-50">Confirm</span>
                </b-dropdown-item>
                <!-- Confirm Assets/FGS By Division Head -->
                <b-dropdown-item
                  v-if="
                    hasPermission('purchase_request_confirm_division_head') == true &&
                    data.item.purchase_request_is_center == false &&
                    ( data.item.purchase_request_category == 'FGS' || data.item.purchase_request_category == 'Assets')  &&
                    data.item.purchase_request_is_confirmed_by_purchasing_cabang != null &&
                      data.item.purchase_request_is_confirmed_by_laboratory_manager != null &&
                      data.item.purchase_request_is_confirmed_by_regional_manager != null &&
                      data.item.purchase_request_is_confirmed_by_division_head == null
                  "
                  @click="
                    confirmPurchaseRequestByDivisionHead(
                      data.item.purchase_request_id
                    )
                  "
                >
                  <feather-icon icon="CheckIcon" />
                  <span class="align-middle ml-50">Confirm</span>
                </b-dropdown-item>
                <!-- Confirm Assets/FGS By FA Coal Manager -->
                <b-dropdown-item
                  v-if="
                    hasPermission('purchase_request_confirm_fa_coal_manager') ==
                      true &&
                      data.item.purchase_request_is_center == false &&
                    ( data.item.purchase_request_category == 'FGS' || data.item.purchase_request_category == 'Assets')  &&
                    data.item.purchase_request_is_confirmed_by_purchasing_cabang != null &&
                      data.item.purchase_request_is_confirmed_by_laboratory_manager != null &&
                      data.item.purchase_request_is_confirmed_by_regional_manager != null &&
                      data.item.purchase_request_is_confirmed_by_division_head != null&&
                      data.item.purchase_request_is_confirmed_by_fa_coal_manager == null
                  "
                  @click="
                    confirmPurchaseRequestByFACoalManager(
                      data.item.purchase_request_id
                    )
                  "
                >
                  <feather-icon icon="CheckIcon" />
                  <span class="align-middle ml-50">Confirm</span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="
                    hasPermission('purchase_request_confirm_regional_manager') ==
                      true &&
                      data.item.purchase_request_is_center == true &&
                      data.item
                        .purchase_request_is_confirmed_by_laboratory_manager !=
                        null &&
                      data.item.purchase_request_is_confirmed_by_regional_manager ==
                        null
                  "
                  @click="
                    confirmPurchaseRequestByRegionalManager(
                      data.item.purchase_request_id
                    )
                  "
                >
                  <feather-icon icon="CheckIcon" />
                  <span class="align-middle ml-50">Confirm</span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="
                    hasPermission('purchase_request_confirm_division_head') ==
                      true &&
                      data.item.purchase_request_is_center == true &&
                      data.item
                        .purchase_request_is_confirmed_by_laboratory_manager !=
                        null &&
                      data.item.purchase_request_is_confirmed_by_regional_manager !=
                        null &&
                      data.item.purchase_request_is_confirmed_by_division_head ==
                        null
                  "
                  @click="
                    confirmPurchaseRequestByDivisionHead(
                      data.item.purchase_request_id
                    )
                  "
                >
                  <feather-icon icon="CheckIcon" />
                  <span class="align-middle ml-50">Confirm</span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="
                    hasPermission('purchase_request_confirm_fa_coal_manager') ==
                      true &&
                      data.item.purchase_request_is_center == true &&
                      data.item
                        .purchase_request_is_confirmed_by_laboratory_manager !=
                        null &&
                      data.item.purchase_request_is_confirmed_by_regional_manager !=
                        null &&
                      data.item.purchase_request_is_confirmed_by_division_head !=
                        null &&
                      data.item.purchase_request_is_confirmed_by_fa_coal_manager ==
                        null
                  "
                  @click="
                    confirmPurchaseRequestByFACoalManager(
                      data.item.purchase_request_id
                    )
                  "
                >
                  <feather-icon icon="CheckIcon" />
                  <span class="align-middle ml-50">Confirm</span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="
                    hasPermission('purchase_request_confirm_operation_director') ==
                      true &&
                      data.item.purchase_request_is_center == true &&
                      data.item
                        .purchase_request_is_confirmed_by_laboratory_manager !=
                        null &&
                      data.item.purchase_request_is_confirmed_by_regional_manager !=
                        null &&
                      data.item.purchase_request_is_confirmed_by_division_head !=
                        null &&
                      data.item.purchase_request_is_confirmed_by_fa_coal_manager !=
                        null &&
                      data.item
                        .purchase_request_is_confirmed_by_operation_director == null
                  "
                  @click="
                    confirmPurchaseRequestByOperationDirector(
                      data.item.purchase_request_id
                    )
                  "
                >
                  <feather-icon icon="CheckIcon" />
                  <span class="align-middle ml-50">Confirm</span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="
                    hasPermission('purchase_request_confirm_vice_president') ==
                      true &&
                      data.item.purchase_request_is_center == true &&
                      data.item
                        .purchase_request_is_confirmed_by_laboratory_manager !=
                        null &&
                      data.item.purchase_request_is_confirmed_by_regional_manager !=
                        null &&
                      data.item.purchase_request_is_confirmed_by_division_head !=
                        null &&
                      data.item.purchase_request_is_confirmed_by_fa_coal_manager !=
                        null &&
                      data.item
                        .purchase_request_is_confirmed_by_operation_director !=
                        null &&
                      data.item.purchase_request_is_confirmed_by_vice_president ==
                        null
                  "
                  @click="
                    confirmPurchaseRequestByVicePresident(
                      data.item.purchase_request_id
                    )
                  "
                >
                  <feather-icon icon="CheckIcon" />
                  <span class="align-middle ml-50">Confirm</span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="
                    hasPermission('purchase_request_confirm_president_director') ==
                      true &&
                      data.item.purchase_request_is_center == true &&
                      data.item
                        .purchase_request_is_confirmed_by_laboratory_manager !=
                        null &&
                      data.item.purchase_request_is_confirmed_by_regional_manager !=
                        null &&
                      data.item.purchase_request_is_confirmed_by_division_head !=
                        null &&
                      data.item.purchase_request_is_confirmed_by_fa_coal_manager !=
                        null &&
                      data.item
                        .purchase_request_is_confirmed_by_operation_director !=
                        null &&
                      data.item.purchase_request_is_confirmed_by_vice_president !=
                        null &&
                      data.item
                        .purchase_request_is_confirmed_by_president_director == null
                  "
                  @click="
                    confirmPurchaseRequestByPresidentDirector(
                      data.item.purchase_request_id
                    )
                  "
                >
                  <feather-icon icon="CheckIcon" />
                  <span class="align-middle ml-50">Confirm</span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="
                    hasPermission('purchase_request_confirm_procurement_pusat') ==
                      true &&
                      data.item.purchase_request_is_center == true &&
                      data.item
                        .purchase_request_is_confirmed_by_laboratory_manager !=
                        null &&
                      data.item.purchase_request_is_confirmed_by_regional_manager !=
                        null &&
                      data.item.purchase_request_is_confirmed_by_division_head !=
                        null &&
                      data.item.purchase_request_is_confirmed_by_fa_coal_manager !=
                        null &&
                      data.item
                        .purchase_request_is_confirmed_by_operation_director !=
                        null &&
                      data.item.purchase_request_is_confirmed_by_vice_president !=
                        null &&
                      data.item
                        .purchase_request_is_confirmed_by_president_director !=
                        null &&
                      data.item
                        .purchase_request_is_confirmed_by_procurement_pusat == null
                  "
                  @click="
                    confirmPurchaseRequestByProcurementPusat(
                      data.item.purchase_request_id
                    )
                  "
                >
                  <feather-icon icon="CheckIcon" />
                  <span class="align-middle ml-50">Confirm</span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="userRole=='administrator'"
                  @click="deletePurchaseRequest(data.item.purchase_request_id)"
                >
                  <feather-icon icon="TrashIcon" />
                  <span class="align-middle ml-50">Delete</span>
                </b-dropdown-item>
              </b-dropdown>
            </template>
          </b-table>
          <div class="mx-2 mb-2">
            <b-row>
              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-start"
              >
                <span class="text-muted"
                  >Showing {{ dataMeta.fromAssetsFGS }} to {{ dataMeta.toAssetsFGS }} of
                  {{ dataMeta.ofAssetsFGS }} entries</span
                >
                <span style="padding-left: 10px;">
                <feather-icon
                  icon="CircleIcon"
                  class="default"
                /> = Just Created,
                <feather-icon
                  icon="CircleIcon"
                  class="danger"
                /> =  Accepted By Lab Manager,
                <feather-icon
                  icon="CircleIcon"
                  class="warning"
                /> =  Accepted By FA Coal Manager,
                <feather-icon
                  icon="CircleIcon"
                  class="primary"
                /> =  Accepted By President Director
                <feather-icon
                  icon="CircleIcon"
                  class="success"
                />
                 = Finished,
                <feather-icon
                  style="color:#036bfc"
                  icon="MessageCircleIcon"
                /> = Message,
                <feather-icon
                icon="FileIcon" /> = Attachment
              </span>
              </b-col>
              <!-- Pagination -->
              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-end"
              >
                <b-pagination
                  v-model="currentPageAssetsFGS"
                  :total-rows="totalPurchaseRequestsAssetsFGS"
                  :per-page="perPageAssetsFGS"
                  first-number
                  last-number
                  class="mb-0 mt-1 mt-sm-0"
                  prev-class="prev-item"
                  next-class="next-item"
                >
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </b-col>
            </b-row>
          </div>
        </b-tab>
        <b-tab title="Finished">
          <div class="m-2">
            <!-- Table Top -->
            <b-row>
              <b-col cols="5">
                <label>Tanggal Awal</label>
                <b-form-datepicker id="start-datepicker-finished" v-model="startDateFilterFinishedAssetsFGS" class="mb-2"></b-form-datepicker>
              </b-col>
              <b-col cols="5">
                <label>Tanggal Akhir</label>
                <b-form-datepicker id="end-datepicker-finished" v-model="endDateFilterFinishedAssetsFGS" class="mb-2"></b-form-datepicker>
              </b-col>
              <b-col cols="2" class="d-flex align-items-center justify-content-center">
                <b-button variant="primary" @click="exportPRFinishedAssetsFGS()" :disabled="isLoadingExportFinishedAssetsFGS">
                  <feather-icon icon="DownloadIcon" /> Export
                  <b-spinner small type="grow" v-if="isLoadingExportFinishedAssetsFGS"></b-spinner>
                </b-button>
              </b-col>
            </b-row>
            <b-row>
              <!-- Per Page -->
              <b-col
                cols="12"
                md="6"
                class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
              >
                <label>Show</label>
                <v-select
                  v-model="perPageFinishedAssetsFGS"
                  :options="perPageOptions"
                  :clearable="false"
                  class="per-page-selector d-inline-block mx-50"
                />
                <label>entries</label>
              </b-col>

              <!-- Search -->
              <b-col cols="12" md="6">
                <div class="d-flex align-items-center justify-content-end">
                  <b-form-input
                    v-model="searchQueryFinishedAssetsFGS"
                    class="d-inline-block mr-1"
                    placeholder="Search..."
                  />
                </div>
              </b-col>
            </b-row>
          </div>
          <b-table
            ref="refPurchaseRequestListTableFinishedAssetsFGS"
            class="position-relative"
            :items="fetchPurchaseRequestsFinishedAssetsFGS"
            responsive
            :fields="tableColumnsFinishedAssetsFGS"
            primary-key="id"
            :sort-by.sync="sortByAssetsFGS"
            show-empty
            empty-text="No matching records found"
            :sort-desc.sync="isSortDirDescAssetsFGS"
          >
            <!-- Column: Number -->
            <template #cell(Number)="data">
              <div class="text-nowrap">
                <feather-icon
                  v-if="data.item.purchase_request_is_confirmed == null"
                  icon="CircleIcon"
                  class="default"
                />
                <feather-icon
                  v-if="
                    data.item.purchase_request_is_confirmed == true &&
                      data.item.purchase_request_is_finished == null
                  "
                  icon="CircleIcon"
                  class="danger"
                />
                <feather-icon
                  v-if="
                    data.item.purchase_request_is_confirmed == true &&
                      data.item.purchase_request_is_finished == true
                  "
                  icon="CircleIcon"
                  class="success"
                />
                <feather-icon
                v-if="data.item.purchase_request_has_files == true"
                icon="FileIcon" />&nbsp;
                <span class="align-text-top text-capitalize">{{
                  data.item.purchase_request_number
                }}</span>
              </div>
            </template>
            <!-- Column: Class -->
            <template #cell(Class)="data">
              <div class="text-nowrap">
                <span class="align-text-top text-capitalize">{{
                  data.item.purchase_request_class
                }}</span>
              </div>
            </template>
            <!-- Column: Category -->
            <template #cell(Category)="data">
              <div class="text-nowrap">
                <feather-icon size="18" class="mr-50" />
                <span class="align-text-top text-capitalize">{{
                  data.item.purchase_request_category
                }}</span>
              </div>
            </template>
            <!-- Column: Type -->
            <template #cell(Type)="data">
              {{
                data.item.purchase_request_is_center == true
                  ? "Head Office"
                  : "Cabang"
              }}
            </template>
            <!-- Column: Cost Center -->
            <template #cell(CostCenter)="data">
              ({{ data.item.cost_center ? data.item.cost_center.branch_code : '-' }})
              {{ data.item.cost_center ? data.item.cost_center.branch_city : '-' }}
            </template>
            <!-- Column: Notes -->
            <template #cell(Notes)="data">
              {{ truncateString(data.item.purchase_request_notes, 30) }}
            </template>
            <!-- Column: Status -->
            <template #cell(Status)="data">
              <b-badge pill class="text-capitalize">
                {{
                  data.item.purchase_request_is_confirmed
                    ? "CONFIRMED"
                    : "UNCONFIRMED"
                }}
              </b-badge>
            </template>
            <!-- Column: DateCreated -->
            <template #cell(DateCreated)="data">
              {{
                dateSimple(
                  data.item.purchase_request_created_time,
                  "YYYY-MM-DD HH:mm:ss"
                )
              }}
            </template>
            <!-- Column: Actions -->
            <template #cell(actions)="data">
              <b-dropdown
                variant="link"
                no-caret
                :right="$store.state.appConfig.isRTL"
              >
                <template #button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="align-middle text-body"
                  />
                </template>

                <b-dropdown-item @click="showDetail(data.item.purchase_request_id)">
                  <feather-icon icon="ZoomInIcon" />
                  <span class="align-middle ml-50">Detail</span>
                </b-dropdown-item>
                <b-dropdown-item @click="showReviseLog(data.item)">
                  <feather-icon icon="RefreshCwIcon" />
                  <span class="align-middle ml-50">Show Revise Log</span>
                </b-dropdown-item>
                <b-dropdown-item
                  @click="showMessages(data.item)"
                >
                  <feather-icon icon="MessageCircleIcon" />
                  <span class="align-middle ml-50">Chat</span>
                </b-dropdown-item>

                <b-dropdown-item
                  @click="showDocuments(data.item.purchase_request_id)"
                >
                  <feather-icon icon="FileIcon" />
                  <span class="align-middle ml-50">Attachments</span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="
                    hasPermission('purchase_request_confirm_lab_manager') == true &&
                      data.item
                        .purchase_request_is_confirmed_by_laboratory_manager == null
                  "
                  @click="
                    confirmPurchaseRequestByLabManager(
                      data.item.purchase_request_id
                    )
                  "
                >
                  <feather-icon icon="CheckIcon" />
                  <span class="align-middle ml-50">Confirm</span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="
                    hasPermission('purchase_request_confirm_regional_manager') ==
                      true &&
                      data.item.purchase_request_is_center == true &&
                      data.item
                        .purchase_request_is_confirmed_by_laboratory_manager !=
                        null &&
                      data.item.purchase_request_is_confirmed_by_regional_manager ==
                        null
                  "
                  @click="
                    confirmPurchaseRequestByRegionalManager(
                      data.item.purchase_request_id
                    )
                  "
                >
                  <feather-icon icon="CheckIcon" />
                  <span class="align-middle ml-50">Confirm</span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="
                    hasPermission('purchase_request_confirm_division_head') ==
                      true &&
                      data.item.purchase_request_is_center == true &&
                      data.item
                        .purchase_request_is_confirmed_by_laboratory_manager !=
                        null &&
                      data.item.purchase_request_is_confirmed_by_regional_manager !=
                        null &&
                      data.item.purchase_request_is_confirmed_by_division_head ==
                        null
                  "
                  @click="
                    confirmPurchaseRequestByDivisionHead(
                      data.item.purchase_request_id
                    )
                  "
                >
                  <feather-icon icon="CheckIcon" />
                  <span class="align-middle ml-50">Confirm</span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="
                    hasPermission('purchase_request_confirm_fa_coal_manager') ==
                      true &&
                      data.item.purchase_request_is_center == true &&
                      data.item
                        .purchase_request_is_confirmed_by_laboratory_manager !=
                        null &&
                      data.item.purchase_request_is_confirmed_by_regional_manager !=
                        null &&
                      data.item.purchase_request_is_confirmed_by_division_head !=
                        null &&
                      data.item.purchase_request_is_confirmed_by_fa_coal_manager ==
                        null
                  "
                  @click="
                    confirmPurchaseRequestByFACoalManager(
                      data.item.purchase_request_id
                    )
                  "
                >
                  <feather-icon icon="CheckIcon" />
                  <span class="align-middle ml-50">Confirm</span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="
                    hasPermission('purchase_request_confirm_operation_director') ==
                      true &&
                      data.item.purchase_request_is_center == true &&
                      data.item
                        .purchase_request_is_confirmed_by_laboratory_manager !=
                        null &&
                      data.item.purchase_request_is_confirmed_by_regional_manager !=
                        null &&
                      data.item.purchase_request_is_confirmed_by_division_head !=
                        null &&
                      data.item.purchase_request_is_confirmed_by_fa_coal_manager !=
                        null &&
                      data.item
                        .purchase_request_is_confirmed_by_operation_director == null
                  "
                  @click="
                    confirmPurchaseRequestByOperationDirector(
                      data.item.purchase_request_id
                    )
                  "
                >
                  <feather-icon icon="CheckIcon" />
                  <span class="align-middle ml-50">Confirm</span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="
                    hasPermission('purchase_request_confirm_vice_president') ==
                      true &&
                      data.item.purchase_request_is_center == true &&
                      data.item
                        .purchase_request_is_confirmed_by_laboratory_manager !=
                        null &&
                      data.item.purchase_request_is_confirmed_by_regional_manager !=
                        null &&
                      data.item.purchase_request_is_confirmed_by_division_head !=
                        null &&
                      data.item.purchase_request_is_confirmed_by_fa_coal_manager !=
                        null &&
                      data.item
                        .purchase_request_is_confirmed_by_operation_director !=
                        null &&
                      data.item.purchase_request_is_confirmed_by_vice_president ==
                        null
                  "
                  @click="
                    confirmPurchaseRequestByVicePresident(
                      data.item.purchase_request_id
                    )
                  "
                >
                  <feather-icon icon="CheckIcon" />
                  <span class="align-middle ml-50">Confirm</span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="
                    hasPermission('purchase_request_confirm_president_director') ==
                      true &&
                      data.item.purchase_request_is_center == true &&
                      data.item
                        .purchase_request_is_confirmed_by_laboratory_manager !=
                        null &&
                      data.item.purchase_request_is_confirmed_by_regional_manager !=
                        null &&
                      data.item.purchase_request_is_confirmed_by_division_head !=
                        null &&
                      data.item.purchase_request_is_confirmed_by_fa_coal_manager !=
                        null &&
                      data.item
                        .purchase_request_is_confirmed_by_operation_director !=
                        null &&
                      data.item.purchase_request_is_confirmed_by_vice_president !=
                        null &&
                      data.item
                        .purchase_request_is_confirmed_by_president_director == null
                  "
                  @click="
                    confirmPurchaseRequestByPresidentDirector(
                      data.item.purchase_request_id
                    )
                  "
                >
                  <feather-icon icon="CheckIcon" />
                  <span class="align-middle ml-50">Confirm</span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="
                    hasPermission('purchase_request_confirm_procurement_pusat') ==
                      true &&
                      data.item.purchase_request_is_center == true &&
                      data.item
                        .purchase_request_is_confirmed_by_laboratory_manager !=
                        null &&
                      data.item.purchase_request_is_confirmed_by_regional_manager !=
                        null &&
                      data.item.purchase_request_is_confirmed_by_division_head !=
                        null &&
                      data.item.purchase_request_is_confirmed_by_fa_coal_manager !=
                        null &&
                      data.item
                        .purchase_request_is_confirmed_by_operation_director !=
                        null &&
                      data.item.purchase_request_is_confirmed_by_vice_president !=
                        null &&
                      data.item
                        .purchase_request_is_confirmed_by_president_director !=
                        null &&
                      data.item
                        .purchase_request_is_confirmed_by_procurement_pusat == null
                  "
                  @click="
                    confirmPurchaseRequestByProcurementPusat(
                      data.item.purchase_request_id
                    )
                  "
                >
                  <feather-icon icon="CheckIcon" />
                  <span class="align-middle ml-50">Confirm</span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="userRole=='administrator'"
                  @click="deletePurchaseRequest(data.item.purchase_request_id)"
                >
                  <feather-icon icon="TrashIcon" />
                  <span class="align-middle ml-50">Delete</span>
                </b-dropdown-item>
              </b-dropdown>
            </template>
          </b-table>
          <div class="mx-2 mb-2">
            <b-row>
              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-start"
              >
                <span class="text-muted"
                  >Showing {{ dataMetaFinished.fromAssetsFGS }} to {{ dataMetaFinished.toAssetsFGS }} of
                  {{ dataMetaFinished.ofAssetsFGS }} entries</span
                ><span style="padding-left: 10px;">
                <feather-icon
                  icon="CircleIcon"
                  class="default"
                /> = Just Created,
                <feather-icon
                  icon="CircleIcon"
                  class="danger"
                /> =  Accepted By Lab Manager,
                <feather-icon
                  icon="CircleIcon"
                  class="warning"
                /> =  Accepted By FA Coal Manager,
                <feather-icon
                  icon="CircleIcon"
                  class="primary"
                /> =  Accepted By President Director,
                <feather-icon
                  icon="CircleIcon"
                  class="success"
                />
                 = Finished
                <feather-icon
                  style="color:#036bfc"
                  icon="MessageCircleIcon"
                /> = Message,
                <feather-icon
                icon="FileIcon" /> = Attachment
              </span>
              </b-col>
              <!-- Pagination -->
              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-end"
              >
                <b-pagination
                  v-model="currentPageFinishedAssetsFGS"
                  :total-rows="totalPurchaseRequestsFinishedAssetsFGS"
                  :per-page="perPageFinishedAssetsFGS"
                  first-number
                  last-number
                  class="mb-0 mt-1 mt-sm-0"
                  prev-class="prev-item"
                  next-class="next-item"
                >
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </b-col>
            </b-row>
          </div>
        </b-tab>
        </b-tabs>
        </b-tab>
        <b-tab title="Supplies">
      <b-tabs>
        <b-tab title="Needs Approval">
          <div class="m-2">
            <!-- Table Top -->
            <b-row>
              <b-col cols="5">
                <label>Tanggal Awal</label>
                <b-form-datepicker id="start-datepicker" v-model="startDateFilterSuppliesNA" class="mb-2"></b-form-datepicker>
              </b-col>
              <b-col cols="5">
                <label>Tanggal Akhir</label>
                <b-form-datepicker id="end-datepicker" v-model="endDateFilterSuppliesNA" class="mb-2"></b-form-datepicker>
              </b-col>
              <b-col cols="2" class="d-flex align-items-center justify-content-center">
                <b-button variant="primary" @click="exportPR('SuppliesNA')" :disabled="isLoadingExportSupplies">
                  <feather-icon icon="DownloadIcon" /> Export
                  <b-spinner small type="grow" v-if="isLoadingExportSupplies"></b-spinner>
                </b-button>
              </b-col>
            </b-row>
            <b-row>
              <!-- Per Page -->
              <b-col
                cols="12"
                md="6"
                class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
              >
                <label>Show</label>
                <v-select
                  v-model="perPageSuppliesNA"
                  :options="perPageOptions"
                  :clearable="false"
                  class="per-page-selector d-inline-block mx-50"
                />
                <label>entries</label>
              </b-col>

              <!-- Search -->
              <b-col cols="12" md="6">
                <div class="d-flex align-items-center justify-content-end">
                  <b-form-input
                    v-model="searchQuerySuppliesNA"
                    class="d-inline-block mr-1"
                    placeholder="Search..."
                  />
                </div>
              </b-col>
            </b-row>
          </div>
          <b-table
            ref="refPurchaseRequestListTableSuppliesNA"
            class="position-relative"
            :items="fetchPurchaseRequestsSuppliesNA"
            responsive
            :fields="tableColumnsSuppliesNA"
            primary-key="id"
            :sort-by.sync="sortBySuppliesNA"
            show-empty
            empty-text="No matching records found"
            :sort-desc.sync="isSortDirDescSupplies"
          >
            <!-- Column: Number -->
            <template #cell(Number)="data">
              <div class="text-nowrap">
                <feather-icon
                  v-if="data.item.chats !=null"
                  style="color:#036bfc"
                  icon="MessageCircleIcon"
                /><feather-icon icon="RefreshCwIcon" v-if="data.item.purchase_request_is_revised==true" class="danger"/>
                <feather-icon
                  v-if="data.item.purchase_request_is_confirmed_by_laboratory_manager == null"
                  icon="CircleIcon"
                  class="default"
                />
                <feather-icon
                  v-if="
                    data.item.purchase_request_is_confirmed_by_laboratory_manager != null &&
                      data.item.purchase_request_is_finished == null
                  "
                  icon="CircleIcon"
                  class="danger"
                />
                <feather-icon
                  v-if="
                    data.item.purchase_request_is_confirmed_by_fa_coal_manager != null &&
                      data.item.purchase_request_is_finished == null
                  "
                  icon="CircleIcon"
                  class="warning"
                />
                <feather-icon
                  v-if="
                    data.item.purchase_request_is_confirmed_by_president_director != null &&
                      data.item.purchase_request_is_finished == null
                  "
                  icon="CircleIcon"
                  class="primary"
                />
                <feather-icon
                  v-if="
                    data.item.purchase_request_is_confirmed_by_laboratory_manager != null &&
                      data.item.purchase_request_is_finished == true
                  "
                  icon="CircleIcon"
                  class="success"
                />
                <feather-icon
                v-if="data.item.purchase_request_has_files == true"
                icon="FileIcon" />&nbsp;
                <span class="align-text-top text-capitalize">{{
                  data.item.purchase_request_number
                }}</span>
              </div>
            </template>
            <!-- Column: Class -->
            <template #cell(Class)="data">
              <div class="text-nowrap">
                <span class="align-text-top text-capitalize">{{
                  data.item.purchase_request_class
                }}</span>
              </div>
            </template>
            <!-- Column: Category -->
            <template #cell(Category)="data">
              <div class="text-nowrap">
                <feather-icon size="18" class="mr-50" />
                <span class="align-text-top text-capitalize">{{
                  data.item.purchase_request_category
                }}</span>
              </div>
            </template>
            <!-- Column: Type -->
            <template #cell(Type)="data">
              {{
                data.item.purchase_request_is_center == true
                  ? "Head Office"
                  : "Cabang"
              }}
            </template>
            <!-- Column: Cost Center -->
            <template #cell(CostCenter)="data">
              ({{ data.item.cost_center ? data.item.cost_center.branch_code : '-' }})
              {{ data.item.cost_center ? data.item.cost_center.branch_city : '-' }}
            </template>
            <!-- Column: Notes -->
            <template #cell(Notes)="data">
              {{ truncateString(data.item.purchase_request_notes, 30) }}
            </template>
            <!-- Column: Status -->
            <template #cell(Status)="data">
              <b-badge pill class="text-capitalize">
                {{
                  data.item.purchase_request_is_confirmed
                    ? "CONFIRMED"
                    : "UNCONFIRMED"
                }}
              </b-badge>
            </template>
            <!-- Column: DateCreated -->
            <template #cell(DateCreated)="data">
              {{
                dateSimple(
                  data.item.purchase_request_created_time,
                  "YYYY-MM-DD HH:mm:ss"
                )
              }}
            </template>
            <!-- Column: Actions -->
            <template #cell(actions)="data">
              <b-dropdown
                variant="link"
                no-caret
                :right="$store.state.appConfig.isRTL"
              >
                <template #button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="align-middle text-body"
                  />
                </template>

                <b-dropdown-item @click="showDetail(data.item.purchase_request_id)">
                  <feather-icon icon="ZoomInIcon" />
                  <span class="align-middle ml-50">Detail</span>
                </b-dropdown-item>
                <b-dropdown-item @click="showReviseLog(data.item)">
                  <feather-icon icon="RefreshCwIcon" />
                  <span class="align-middle ml-50">Show Revise Log</span>
                </b-dropdown-item>
          <b-dropdown-item
                  v-if="
                    hasPermission('purchase_request_update') == true &&
                      data.item.purchase_request_is_confirmed_by_purchasing_cabang == null && data.item.purchase_request_is_confirmed_by_laboratory_manager == null
                  "
                  @click="editPurchaseRequest(data.item)"
                >
                  <feather-icon icon="EditIcon" />
                  <span class="align-middle ml-50">Edit</span>
                </b-dropdown-item>
                <b-dropdown-item
                  @click="showMessages(data.item)"
                >
                  <feather-icon icon="MessageCircleIcon" />
                  <span class="align-middle ml-50">Chat</span>
                </b-dropdown-item>
                <b-dropdown-item
                  @click="showDocuments(data.item.purchase_request_id)"
                >
                  <feather-icon icon="FileIcon" />
                  <span class="align-middle ml-50">Attachments</span>
                </b-dropdown-item>
                <!-- Confirm Assets/FGS/Supplies By Purchasing -->
                <b-dropdown-item
                  v-if="
                    hasPermission('purchase_request_confirm_purchasing') == true &&
                    data.item.purchase_request_is_center == false &&
                    ( data.item.purchase_request_category == 'FGS' || data.item.purchase_request_category == 'Assets' || data.item.purchase_request_category == 'Supplies')  &&
                    data.item.purchase_request_is_confirmed_by_purchasing_cabang == null
                  "
                  @click="
                    confirmPurchaseRequestByPurchasing(
                      data.item.purchase_request_id
                    )
                  "
                >
                  <feather-icon icon="CheckIcon" />
                  <span class="align-middle ml-50">Confirm</span>
                </b-dropdown-item>
                <!-- Confirm Supplies By Lab Manager-->
                <b-dropdown-item
                  v-if="
                    hasPermission('purchase_request_confirm_lab_manager') == true &&
                    data.item.purchase_request_is_center == false &&
                    data.item.purchase_request_category == 'Supplies' &&
                    data.item.purchase_request_is_confirmed_by_purchasing_cabang != null &&
                      data.item
                        .purchase_request_is_confirmed_by_laboratory_manager == null
                  "
                  @click="
                    confirmPurchaseRequestByLabManager(
                      data.item.purchase_request_id
                    )
                  "
                >
                  <feather-icon icon="CheckIcon" />
                  <span class="align-middle ml-50">Confirm</span>
                </b-dropdown-item>
                <!-- Confirm Assets/FGS By Lab Manager -->
                <b-dropdown-item
                  v-if="
                    hasPermission('purchase_request_confirm_lab_manager') == true &&
                    data.item.purchase_request_is_center == false &&
                    ( data.item.purchase_request_category == 'FGS' || data.item.purchase_request_category == 'Assets')  &&
                    data.item.purchase_request_is_confirmed_by_purchasing_cabang != null &&
                      data.item
                        .purchase_request_is_confirmed_by_laboratory_manager == null
                  "
                  @click="
                    confirmPurchaseRequestByLabManager(
                      data.item.purchase_request_id
                    )
                  "
                >
                  <feather-icon icon="CheckIcon" />
                  <span class="align-middle ml-50">Confirm</span>
                </b-dropdown-item>
                <!-- Confirm HO By Lab Manager -->
                <b-dropdown-item
                  v-if="
                    hasPermission('purchase_request_confirm_lab_manager') == true &&
                    data.item.purchase_request_is_center == true &&
                      data.item
                        .purchase_request_is_confirmed_by_laboratory_manager == null
                  "
                  @click="
                    confirmPurchaseRequestByLabManager(
                      data.item.purchase_request_id
                    )
                  "
                >
                  <feather-icon icon="CheckIcon" />
                  <span class="align-middle ml-50">Confirm</span>
                </b-dropdown-item>
                <!-- Confirm Assets/FGS By Regional Manager -->
                <b-dropdown-item
                  v-if="
                    hasPermission('purchase_request_confirm_regional_manager') == true &&
                    data.item.purchase_request_is_center == false &&
                    ( data.item.purchase_request_category == 'FGS' || data.item.purchase_request_category == 'Assets')  &&
                    data.item.purchase_request_is_confirmed_by_purchasing_cabang != null &&
                      data.item.purchase_request_is_confirmed_by_laboratory_manager != null &&
                      data.item.purchase_request_is_confirmed_by_regional_manager == null
                  "
                  @click="
                    confirmPurchaseRequestByRegionalManager(
                      data.item.purchase_request_id
                    )
                  "
                >
                  <feather-icon icon="CheckIcon" />
                  <span class="align-middle ml-50">Confirm</span>
                </b-dropdown-item>
                <!-- Confirm Assets/FGS By Division Head -->
                <b-dropdown-item
                  v-if="
                    hasPermission('purchase_request_confirm_division_head') == true &&
                    data.item.purchase_request_is_center == false &&
                    ( data.item.purchase_request_category == 'FGS' || data.item.purchase_request_category == 'Assets')  &&
                    data.item.purchase_request_is_confirmed_by_purchasing_cabang != null &&
                      data.item.purchase_request_is_confirmed_by_laboratory_manager != null &&
                      data.item.purchase_request_is_confirmed_by_regional_manager != null &&
                      data.item.purchase_request_is_confirmed_by_division_head == null
                  "
                  @click="
                    confirmPurchaseRequestByDivisionHead(
                      data.item.purchase_request_id
                    )
                  "
                >
                  <feather-icon icon="CheckIcon" />
                  <span class="align-middle ml-50">Confirm</span>
                </b-dropdown-item>
                <!-- Confirm Assets/FGS By FA Coal Manager -->
                <b-dropdown-item
                  v-if="
                    hasPermission('purchase_request_confirm_fa_coal_manager') ==
                      true &&
                      data.item.purchase_request_is_center == false &&
                    ( data.item.purchase_request_category == 'FGS' || data.item.purchase_request_category == 'Assets')  &&
                    data.item.purchase_request_is_confirmed_by_purchasing_cabang != null &&
                      data.item.purchase_request_is_confirmed_by_laboratory_manager != null &&
                      data.item.purchase_request_is_confirmed_by_regional_manager != null &&
                      data.item.purchase_request_is_confirmed_by_division_head != null&&
                      data.item.purchase_request_is_confirmed_by_fa_coal_manager == null
                  "
                  @click="
                    confirmPurchaseRequestByFACoalManager(
                      data.item.purchase_request_id
                    )
                  "
                >
                  <feather-icon icon="CheckIcon" />
                  <span class="align-middle ml-50">Confirm</span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="
                    hasPermission('purchase_request_confirm_regional_manager') ==
                      true &&
                      data.item.purchase_request_is_center == true &&
                      data.item
                        .purchase_request_is_confirmed_by_laboratory_manager !=
                        null &&
                      data.item.purchase_request_is_confirmed_by_regional_manager ==
                        null
                  "
                  @click="
                    confirmPurchaseRequestByRegionalManager(
                      data.item.purchase_request_id
                    )
                  "
                >
                  <feather-icon icon="CheckIcon" />
                  <span class="align-middle ml-50">Confirm</span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="
                    hasPermission('purchase_request_confirm_division_head') ==
                      true &&
                      data.item.purchase_request_is_center == true &&
                      data.item
                        .purchase_request_is_confirmed_by_laboratory_manager !=
                        null &&
                      data.item.purchase_request_is_confirmed_by_regional_manager !=
                        null &&
                      data.item.purchase_request_is_confirmed_by_division_head ==
                        null
                  "
                  @click="
                    confirmPurchaseRequestByDivisionHead(
                      data.item.purchase_request_id
                    )
                  "
                >
                  <feather-icon icon="CheckIcon" />
                  <span class="align-middle ml-50">Confirm</span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="
                    hasPermission('purchase_request_confirm_fa_coal_manager') ==
                      true &&
                      data.item.purchase_request_is_center == true &&
                      data.item
                        .purchase_request_is_confirmed_by_laboratory_manager !=
                        null &&
                      data.item.purchase_request_is_confirmed_by_regional_manager !=
                        null &&
                      data.item.purchase_request_is_confirmed_by_division_head !=
                        null &&
                      data.item.purchase_request_is_confirmed_by_fa_coal_manager ==
                        null
                  "
                  @click="
                    confirmPurchaseRequestByFACoalManager(
                      data.item.purchase_request_id
                    )
                  "
                >
                  <feather-icon icon="CheckIcon" />
                  <span class="align-middle ml-50">Confirm</span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="
                    hasPermission('purchase_request_confirm_operation_director') ==
                      true &&
                      data.item.purchase_request_is_center == true &&
                      data.item
                        .purchase_request_is_confirmed_by_laboratory_manager !=
                        null &&
                      data.item.purchase_request_is_confirmed_by_regional_manager !=
                        null &&
                      data.item.purchase_request_is_confirmed_by_division_head !=
                        null &&
                      data.item.purchase_request_is_confirmed_by_fa_coal_manager !=
                        null &&
                      data.item
                        .purchase_request_is_confirmed_by_operation_director == null
                  "
                  @click="
                    confirmPurchaseRequestByOperationDirector(
                      data.item.purchase_request_id
                    )
                  "
                >
                  <feather-icon icon="CheckIcon" />
                  <span class="align-middle ml-50">Confirm</span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="
                    hasPermission('purchase_request_confirm_vice_president') ==
                      true &&
                      data.item.purchase_request_is_center == true &&
                      data.item
                        .purchase_request_is_confirmed_by_laboratory_manager !=
                        null &&
                      data.item.purchase_request_is_confirmed_by_regional_manager !=
                        null &&
                      data.item.purchase_request_is_confirmed_by_division_head !=
                        null &&
                      data.item.purchase_request_is_confirmed_by_fa_coal_manager !=
                        null &&
                      data.item
                        .purchase_request_is_confirmed_by_operation_director !=
                        null &&
                      data.item.purchase_request_is_confirmed_by_vice_president ==
                        null
                  "
                  @click="
                    confirmPurchaseRequestByVicePresident(
                      data.item.purchase_request_id
                    )
                  "
                >
                  <feather-icon icon="CheckIcon" />
                  <span class="align-middle ml-50">Confirm</span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="
                    hasPermission('purchase_request_confirm_president_director') ==
                      true &&
                      data.item.purchase_request_is_center == true &&
                      data.item
                        .purchase_request_is_confirmed_by_laboratory_manager !=
                        null &&
                      data.item.purchase_request_is_confirmed_by_regional_manager !=
                        null &&
                      data.item.purchase_request_is_confirmed_by_division_head !=
                        null &&
                      data.item.purchase_request_is_confirmed_by_fa_coal_manager !=
                        null &&
                      data.item
                        .purchase_request_is_confirmed_by_operation_director !=
                        null &&
                      data.item.purchase_request_is_confirmed_by_vice_president !=
                        null &&
                      data.item
                        .purchase_request_is_confirmed_by_president_director == null
                  "
                  @click="
                    confirmPurchaseRequestByPresidentDirector(
                      data.item.purchase_request_id
                    )
                  "
                >
                  <feather-icon icon="CheckIcon" />
                  <span class="align-middle ml-50">Confirm</span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="
                    hasPermission('purchase_request_confirm_procurement_pusat') ==
                      true &&
                      data.item.purchase_request_is_center == true &&
                      data.item
                        .purchase_request_is_confirmed_by_laboratory_manager !=
                        null &&
                      data.item.purchase_request_is_confirmed_by_regional_manager !=
                        null &&
                      data.item.purchase_request_is_confirmed_by_division_head !=
                        null &&
                      data.item.purchase_request_is_confirmed_by_fa_coal_manager !=
                        null &&
                      data.item
                        .purchase_request_is_confirmed_by_operation_director !=
                        null &&
                      data.item.purchase_request_is_confirmed_by_vice_president !=
                        null &&
                      data.item
                        .purchase_request_is_confirmed_by_president_director !=
                        null &&
                      data.item
                        .purchase_request_is_confirmed_by_procurement_pusat == null
                  "
                  @click="
                    confirmPurchaseRequestByProcurementPusat(
                      data.item.purchase_request_id
                    )
                  "
                >
                  <feather-icon icon="CheckIcon" />
                  <span class="align-middle ml-50">Confirm</span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="userRole=='administrator'"
                  @click="deletePurchaseRequest(data.item.purchase_request_id)"
                >
                  <feather-icon icon="TrashIcon" />
                  <span class="align-middle ml-50">Delete</span>
                </b-dropdown-item>
              </b-dropdown>
            </template>
          </b-table>
          <div class="mx-2 mb-2">
            <b-row>
              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-start"
              >
                <span class="text-muted"
                  >Showing {{ dataMeta.fromSuppliesNA }} to {{ dataMeta.toSuppliesNA }} of
                  {{ dataMeta.ofSuppliesNA }} entries</span
                ><span style="padding-left: 10px;">
                <feather-icon
                  icon="CircleIcon"
                  class="default"
                /> = Just Created,
                <feather-icon
                  icon="CircleIcon"
                  class="danger"
                /> =  Accepted By Lab Manager,
                <feather-icon
                  icon="CircleIcon"
                  class="warning"
                /> =  Accepted By FA Coal Manager,
                <feather-icon
                  icon="CircleIcon"
                  class="primary"
                /> =  Accepted By President Director
                <feather-icon
                  icon="CircleIcon"
                  class="success"
                />
                 = Finished
                <feather-icon
                  style="color:#036bfc"
                  icon="MessageCircleIcon"
                /> = Message,
                <feather-icon
                icon="FileIcon" /> = Attachment
              </span>
              </b-col>
              <!-- Pagination -->
              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-end"
              >
                <b-pagination
                  v-model="currentPageSuppliesNA"
                  :total-rows="totalPurchaseRequestsSuppliesNA"
                  :per-page="perPageSuppliesNA"
                  first-number
                  last-number
                  class="mb-0 mt-1 mt-sm-0"
                  prev-class="prev-item"
                  next-class="next-item"
                >
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </b-col>
            </b-row>
          </div>
        </b-tab>
        <b-tab title="On Going">
          <div class="m-2">
            <!-- Table Top -->
            <b-row>
              <b-col cols="5">
                <label>Tanggal Awal</label>
                <b-form-datepicker id="start-datepicker" v-model="startDateFilterSupplies" class="mb-2"></b-form-datepicker>
              </b-col>
              <b-col cols="5">
                <label>Tanggal Akhir</label>
                <b-form-datepicker id="end-datepicker" v-model="endDateFilterSupplies" class="mb-2"></b-form-datepicker>
              </b-col>
              <b-col cols="2" class="d-flex align-items-center justify-content-center">
                <b-button variant="primary" @click="exportPRSupplies()" :disabled="isLoadingExportSupplies">
                  <feather-icon icon="DownloadIcon" /> Export
                  <b-spinner small type="grow" v-if="isLoadingExportSupplies"></b-spinner>
                </b-button>
              </b-col>
            </b-row>
            <b-row>
              <!-- Per Page -->
              <b-col
                cols="12"
                md="6"
                class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
              >
                <label>Show</label>
                <v-select
                  v-model="perPageSupplies"
                  :options="perPageOptions"
                  :clearable="false"
                  class="per-page-selector d-inline-block mx-50"
                />
                <label>entries</label>
              </b-col>

              <!-- Search -->
              <b-col cols="12" md="6">
                <div class="d-flex align-items-center justify-content-end">
                  <b-form-input
                    v-model="searchQuerySupplies"
                    class="d-inline-block mr-1"
                    placeholder="Search..."
                  />
                </div>
              </b-col>
            </b-row>
          </div>
          <b-table
            ref="refPurchaseRequestListTableSupplies"
            class="position-relative"
            :items="fetchPurchaseRequestsSupplies"
            responsive
            :fields="tableColumnsSupplies"
            primary-key="id"
            :sort-by.sync="sortBySupplies"
            show-empty
            empty-text="No matching records found"
            :sort-desc.sync="isSortDirDescSupplies"
          >
            <!-- Column: Number -->
            <template #cell(Number)="data">
              <div class="text-nowrap">
                <feather-icon
                  v-if="data.item.chats !=null"
                  style="color:#036bfc"
                  icon="MessageCircleIcon"
                /><feather-icon icon="RefreshCwIcon" v-if="data.item.purchase_request_is_revised==true" class="danger"/>
                <feather-icon
                  v-if="data.item.purchase_request_is_confirmed_by_laboratory_manager == null"
                  icon="CircleIcon"
                  class="default"
                />
                <feather-icon
                  v-if="
                    data.item.purchase_request_is_confirmed_by_laboratory_manager != null &&
                      data.item.purchase_request_is_finished == null
                  "
                  icon="CircleIcon"
                  class="danger"
                />
                <feather-icon
                  v-if="
                    data.item.purchase_request_is_confirmed_by_fa_coal_manager != null &&
                      data.item.purchase_request_is_finished == null
                  "
                  icon="CircleIcon"
                  class="warning"
                />
                <feather-icon
                  v-if="
                    data.item.purchase_request_is_confirmed_by_president_director != null &&
                      data.item.purchase_request_is_finished == null
                  "
                  icon="CircleIcon"
                  class="primary"
                />
                <feather-icon
                  v-if="
                    data.item.purchase_request_is_confirmed_by_laboratory_manager != null &&
                      data.item.purchase_request_is_finished == true
                  "
                  icon="CircleIcon"
                  class="success"
                />
                <feather-icon
                v-if="data.item.purchase_request_has_files == true"
                icon="FileIcon" />&nbsp;
                <span class="align-text-top text-capitalize">{{
                  data.item.purchase_request_number
                }}</span>
              </div>
            </template>
            <!-- Column: Class -->
            <template #cell(Class)="data">
              <div class="text-nowrap">
                <span class="align-text-top text-capitalize">{{
                  data.item.purchase_request_class
                }}</span>
              </div>
            </template>
            <!-- Column: Category -->
            <template #cell(Category)="data">
              <div class="text-nowrap">
                <feather-icon size="18" class="mr-50" />
                <span class="align-text-top text-capitalize">{{
                  data.item.purchase_request_category
                }}</span>
              </div>
            </template>
            <!-- Column: Type -->
            <template #cell(Type)="data">
              {{
                data.item.purchase_request_is_center == true
                  ? "Head Office"
                  : "Cabang"
              }}
            </template>
            <!-- Column: Cost Center -->
            <template #cell(CostCenter)="data">
              ({{ data.item.cost_center ? data.item.cost_center.branch_code : '-' }})
              {{ data.item.cost_center ? data.item.cost_center.branch_city : '-' }}
            </template>
            <!-- Column: Notes -->
            <template #cell(Notes)="data">
              {{ truncateString(data.item.purchase_request_notes, 30) }}
            </template>
            <!-- Column: Status -->
            <template #cell(Status)="data">
              <b-badge pill class="text-capitalize">
                {{
                  data.item.purchase_request_is_confirmed
                    ? "CONFIRMED"
                    : "UNCONFIRMED"
                }}
              </b-badge>
            </template>
            <!-- Column: DateCreated -->
            <template #cell(DateCreated)="data">
              {{
                dateSimple(
                  data.item.purchase_request_created_time,
                  "YYYY-MM-DD HH:mm:ss"
                )
              }}
            </template>
            <!-- Column: Actions -->
            <template #cell(actions)="data">
              <b-dropdown
                variant="link"
                no-caret
                :right="$store.state.appConfig.isRTL"
              >
                <template #button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="align-middle text-body"
                  />
                </template>

                <b-dropdown-item @click="showDetail(data.item.purchase_request_id)">
                  <feather-icon icon="ZoomInIcon" />
                  <span class="align-middle ml-50">Detail</span>
                </b-dropdown-item>
                <b-dropdown-item @click="showReviseLog(data.item)">
                  <feather-icon icon="RefreshCwIcon" />
                  <span class="align-middle ml-50">Show Revise Log</span>
                </b-dropdown-item>
                <b-dropdown-item
  @click="setRevise(data.item.purchase_request_id)"
  v-if="checkRevise(data.item) && ['president-director', 'vice-president', 'fa-coal-manager', 'operation-director', 'division-head', 'regional-manager', 'laboratory-manager'].includes(userRole)">
  <feather-icon icon="RefreshCwIcon" />
  <span class="align-middle ml-50">Revise</span>
</b-dropdown-item>
          <b-dropdown-item
                  v-if="
                    hasPermission('purchase_request_update') == true &&
                      data.item.purchase_request_is_confirmed_by_purchasing_cabang == null && data.item.purchase_request_is_confirmed_by_laboratory_manager == null
                  "
                  @click="editPurchaseRequest(data.item)"
                >
                  <feather-icon icon="EditIcon" />
                  <span class="align-middle ml-50">Edit</span>
                </b-dropdown-item>
                <b-dropdown-item
                  @click="showMessages(data.item)"
                >
                  <feather-icon icon="MessageCircleIcon" />
                  <span class="align-middle ml-50">Chat</span>
                </b-dropdown-item>
                <b-dropdown-item
                  @click="showDocuments(data.item.purchase_request_id)"
                >
                  <feather-icon icon="FileIcon" />
                  <span class="align-middle ml-50">Attachments</span>
                </b-dropdown-item>
                <!-- Confirm Assets/FGS/Supplies By Purchasing -->
                <b-dropdown-item
                  v-if="
                    hasPermission('purchase_request_confirm_purchasing') == true &&
                    data.item.purchase_request_is_center == false &&
                    ( data.item.purchase_request_category == 'FGS' || data.item.purchase_request_category == 'Assets' || data.item.purchase_request_category == 'Supplies')  &&
                    data.item.purchase_request_is_confirmed_by_purchasing_cabang == null
                  "
                  @click="
                    confirmPurchaseRequestByPurchasing(
                      data.item.purchase_request_id
                    )
                  "
                >
                  <feather-icon icon="CheckIcon" />
                  <span class="align-middle ml-50">Confirm</span>
                </b-dropdown-item>
                <!-- Confirm Supplies By Lab Manager-->
                <b-dropdown-item
                  v-if="
                    hasPermission('purchase_request_confirm_lab_manager') == true &&
                    data.item.purchase_request_is_center == false &&
                    data.item.purchase_request_category == 'Supplies' &&
                    data.item.purchase_request_is_confirmed_by_purchasing_cabang != null &&
                      data.item
                        .purchase_request_is_confirmed_by_laboratory_manager == null
                  "
                  @click="
                    confirmPurchaseRequestByLabManager(
                      data.item.purchase_request_id
                    )
                  "
                >
                  <feather-icon icon="CheckIcon" />
                  <span class="align-middle ml-50">Confirm</span>
                </b-dropdown-item>
                <!-- Confirm Assets/FGS By Lab Manager -->
                <b-dropdown-item
                  v-if="
                    hasPermission('purchase_request_confirm_lab_manager') == true &&
                    data.item.purchase_request_is_center == false &&
                    ( data.item.purchase_request_category == 'FGS' || data.item.purchase_request_category == 'Assets')  &&
                    data.item.purchase_request_is_confirmed_by_purchasing_cabang != null &&
                      data.item
                        .purchase_request_is_confirmed_by_laboratory_manager == null
                  "
                  @click="
                    confirmPurchaseRequestByLabManager(
                      data.item.purchase_request_id
                    )
                  "
                >
                  <feather-icon icon="CheckIcon" />
                  <span class="align-middle ml-50">Confirm</span>
                </b-dropdown-item>
                <!-- Confirm HO By Lab Manager -->
                <b-dropdown-item
                  v-if="
                    hasPermission('purchase_request_confirm_lab_manager') == true &&
                    data.item.purchase_request_is_center == true &&
                      data.item
                        .purchase_request_is_confirmed_by_laboratory_manager == null
                  "
                  @click="
                    confirmPurchaseRequestByLabManager(
                      data.item.purchase_request_id
                    )
                  "
                >
                  <feather-icon icon="CheckIcon" />
                  <span class="align-middle ml-50">Confirm</span>
                </b-dropdown-item>
                <!-- Confirm Assets/FGS By Regional Manager -->
                <b-dropdown-item
                  v-if="
                    hasPermission('purchase_request_confirm_regional_manager') == true &&
                    data.item.purchase_request_is_center == false &&
                    ( data.item.purchase_request_category == 'FGS' || data.item.purchase_request_category == 'Assets')  &&
                    data.item.purchase_request_is_confirmed_by_purchasing_cabang != null &&
                      data.item.purchase_request_is_confirmed_by_laboratory_manager != null &&
                      data.item.purchase_request_is_confirmed_by_regional_manager == null
                  "
                  @click="
                    confirmPurchaseRequestByRegionalManager(
                      data.item.purchase_request_id
                    )
                  "
                >
                  <feather-icon icon="CheckIcon" />
                  <span class="align-middle ml-50">Confirm</span>
                </b-dropdown-item>
                <!-- Confirm Assets/FGS By Division Head -->
                <b-dropdown-item
                  v-if="
                    hasPermission('purchase_request_confirm_division_head') == true &&
                    data.item.purchase_request_is_center == false &&
                    ( data.item.purchase_request_category == 'FGS' || data.item.purchase_request_category == 'Assets')  &&
                    data.item.purchase_request_is_confirmed_by_purchasing_cabang != null &&
                      data.item.purchase_request_is_confirmed_by_laboratory_manager != null &&
                      data.item.purchase_request_is_confirmed_by_regional_manager != null &&
                      data.item.purchase_request_is_confirmed_by_division_head == null
                  "
                  @click="
                    confirmPurchaseRequestByDivisionHead(
                      data.item.purchase_request_id
                    )
                  "
                >
                  <feather-icon icon="CheckIcon" />
                  <span class="align-middle ml-50">Confirm</span>
                </b-dropdown-item>
                <!-- Confirm Assets/FGS By FA Coal Manager -->
                <b-dropdown-item
                  v-if="
                    hasPermission('purchase_request_confirm_fa_coal_manager') ==
                      true &&
                      data.item.purchase_request_is_center == false &&
                    ( data.item.purchase_request_category == 'FGS' || data.item.purchase_request_category == 'Assets')  &&
                    data.item.purchase_request_is_confirmed_by_purchasing_cabang != null &&
                      data.item.purchase_request_is_confirmed_by_laboratory_manager != null &&
                      data.item.purchase_request_is_confirmed_by_regional_manager != null &&
                      data.item.purchase_request_is_confirmed_by_division_head != null&&
                      data.item.purchase_request_is_confirmed_by_fa_coal_manager == null
                  "
                  @click="
                    confirmPurchaseRequestByFACoalManager(
                      data.item.purchase_request_id
                    )
                  "
                >
                  <feather-icon icon="CheckIcon" />
                  <span class="align-middle ml-50">Confirm</span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="
                    hasPermission('purchase_request_confirm_regional_manager') ==
                      true &&
                      data.item.purchase_request_is_center == true &&
                      data.item
                        .purchase_request_is_confirmed_by_laboratory_manager !=
                        null &&
                      data.item.purchase_request_is_confirmed_by_regional_manager ==
                        null
                  "
                  @click="
                    confirmPurchaseRequestByRegionalManager(
                      data.item.purchase_request_id
                    )
                  "
                >
                  <feather-icon icon="CheckIcon" />
                  <span class="align-middle ml-50">Confirm</span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="
                    hasPermission('purchase_request_confirm_division_head') ==
                      true &&
                      data.item.purchase_request_is_center == true &&
                      data.item
                        .purchase_request_is_confirmed_by_laboratory_manager !=
                        null &&
                      data.item.purchase_request_is_confirmed_by_regional_manager !=
                        null &&
                      data.item.purchase_request_is_confirmed_by_division_head ==
                        null
                  "
                  @click="
                    confirmPurchaseRequestByDivisionHead(
                      data.item.purchase_request_id
                    )
                  "
                >
                  <feather-icon icon="CheckIcon" />
                  <span class="align-middle ml-50">Confirm</span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="
                    hasPermission('purchase_request_confirm_fa_coal_manager') ==
                      true &&
                      data.item.purchase_request_is_center == true &&
                      data.item
                        .purchase_request_is_confirmed_by_laboratory_manager !=
                        null &&
                      data.item.purchase_request_is_confirmed_by_regional_manager !=
                        null &&
                      data.item.purchase_request_is_confirmed_by_division_head !=
                        null &&
                      data.item.purchase_request_is_confirmed_by_fa_coal_manager ==
                        null
                  "
                  @click="
                    confirmPurchaseRequestByFACoalManager(
                      data.item.purchase_request_id
                    )
                  "
                >
                  <feather-icon icon="CheckIcon" />
                  <span class="align-middle ml-50">Confirm</span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="
                    hasPermission('purchase_request_confirm_operation_director') ==
                      true &&
                      data.item.purchase_request_is_center == true &&
                      data.item
                        .purchase_request_is_confirmed_by_laboratory_manager !=
                        null &&
                      data.item.purchase_request_is_confirmed_by_regional_manager !=
                        null &&
                      data.item.purchase_request_is_confirmed_by_division_head !=
                        null &&
                      data.item.purchase_request_is_confirmed_by_fa_coal_manager !=
                        null &&
                      data.item
                        .purchase_request_is_confirmed_by_operation_director == null
                  "
                  @click="
                    confirmPurchaseRequestByOperationDirector(
                      data.item.purchase_request_id
                    )
                  "
                >
                  <feather-icon icon="CheckIcon" />
                  <span class="align-middle ml-50">Confirm</span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="
                    hasPermission('purchase_request_confirm_vice_president') ==
                      true &&
                      data.item.purchase_request_is_center == true &&
                      data.item
                        .purchase_request_is_confirmed_by_laboratory_manager !=
                        null &&
                      data.item.purchase_request_is_confirmed_by_regional_manager !=
                        null &&
                      data.item.purchase_request_is_confirmed_by_division_head !=
                        null &&
                      data.item.purchase_request_is_confirmed_by_fa_coal_manager !=
                        null &&
                      data.item
                        .purchase_request_is_confirmed_by_operation_director !=
                        null &&
                      data.item.purchase_request_is_confirmed_by_vice_president ==
                        null
                  "
                  @click="
                    confirmPurchaseRequestByVicePresident(
                      data.item.purchase_request_id
                    )
                  "
                >
                  <feather-icon icon="CheckIcon" />
                  <span class="align-middle ml-50">Confirm</span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="
                    hasPermission('purchase_request_confirm_president_director') ==
                      true &&
                      data.item.purchase_request_is_center == true &&
                      data.item
                        .purchase_request_is_confirmed_by_laboratory_manager !=
                        null &&
                      data.item.purchase_request_is_confirmed_by_regional_manager !=
                        null &&
                      data.item.purchase_request_is_confirmed_by_division_head !=
                        null &&
                      data.item.purchase_request_is_confirmed_by_fa_coal_manager !=
                        null &&
                      data.item
                        .purchase_request_is_confirmed_by_operation_director !=
                        null &&
                      data.item.purchase_request_is_confirmed_by_vice_president !=
                        null &&
                      data.item
                        .purchase_request_is_confirmed_by_president_director == null
                  "
                  @click="
                    confirmPurchaseRequestByPresidentDirector(
                      data.item.purchase_request_id
                    )
                  "
                >
                  <feather-icon icon="CheckIcon" />
                  <span class="align-middle ml-50">Confirm</span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="
                    hasPermission('purchase_request_confirm_procurement_pusat') ==
                      true &&
                      data.item.purchase_request_is_center == true &&
                      data.item
                        .purchase_request_is_confirmed_by_laboratory_manager !=
                        null &&
                      data.item.purchase_request_is_confirmed_by_regional_manager !=
                        null &&
                      data.item.purchase_request_is_confirmed_by_division_head !=
                        null &&
                      data.item.purchase_request_is_confirmed_by_fa_coal_manager !=
                        null &&
                      data.item
                        .purchase_request_is_confirmed_by_operation_director !=
                        null &&
                      data.item.purchase_request_is_confirmed_by_vice_president !=
                        null &&
                      data.item
                        .purchase_request_is_confirmed_by_president_director !=
                        null &&
                      data.item
                        .purchase_request_is_confirmed_by_procurement_pusat == null
                  "
                  @click="
                    confirmPurchaseRequestByProcurementPusat(
                      data.item.purchase_request_id
                    )
                  "
                >
                  <feather-icon icon="CheckIcon" />
                  <span class="align-middle ml-50">Confirm</span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="userRole=='administrator'"
                  @click="deletePurchaseRequest(data.item.purchase_request_id)"
                >
                  <feather-icon icon="TrashIcon" />
                  <span class="align-middle ml-50">Delete</span>
                </b-dropdown-item>
              </b-dropdown>
            </template>
          </b-table>
          <div class="mx-2 mb-2">
            <b-row>
              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-start"
              >
                <span class="text-muted"
                  >Showing {{ dataMeta.fromSupplies }} to {{ dataMeta.toSupplies }} of
                  {{ dataMeta.ofSupplies }} entries</span
                ><span style="padding-left: 10px;">
                <feather-icon
                  icon="CircleIcon"
                  class="default"
                /> = Just Created,
                <feather-icon
                  icon="CircleIcon"
                  class="danger"
                /> =  Accepted By Lab Manager,
                <feather-icon
                  icon="CircleIcon"
                  class="warning"
                /> =  Accepted By FA Coal Manager,
                <feather-icon
                  icon="CircleIcon"
                  class="primary"
                /> =  Accepted By President Director
                <feather-icon
                  icon="CircleIcon"
                  class="success"
                />
                 = Finished
                <feather-icon
                  style="color:#036bfc"
                  icon="MessageCircleIcon"
                /> = Message,
                <feather-icon
                icon="FileIcon" /> = Attachment
              </span>
              </b-col>
              <!-- Pagination -->
              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-end"
              >
                <b-pagination
                  v-model="currentPageSupplies"
                  :total-rows="totalPurchaseRequestsSupplies"
                  :per-page="perPageSupplies"
                  first-number
                  last-number
                  class="mb-0 mt-1 mt-sm-0"
                  prev-class="prev-item"
                  next-class="next-item"
                >
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </b-col>
            </b-row>
          </div>
        </b-tab>
        <b-tab title="Finished">
          <div class="m-2">
            <!-- Table Top -->
            <b-row>
              <b-col cols="5">
                <label>Tanggal Awal</label>
                <b-form-datepicker id="start-datepicker-finished" v-model="startDateFilterFinishedSupplies" class="mb-2"></b-form-datepicker>
              </b-col>
              <b-col cols="5">
                <label>Tanggal Akhir</label>
                <b-form-datepicker id="end-datepicker-finished" v-model="endDateFilterFinishedSupplies" class="mb-2"></b-form-datepicker>
              </b-col>
              <b-col cols="2" class="d-flex align-items-center justify-content-center">
                <b-button variant="primary" @click="exportPRFinished()" :disabled="isLoadingExportFinishedSupplies">
                  <feather-icon icon="DownloadIcon" /> Export
                  <b-spinner small type="grow" v-if="isLoadingExportFinishedSupplies"></b-spinner>
                </b-button>
              </b-col>
            </b-row>
            <b-row>
              <!-- Per Page -->
              <b-col
                cols="12"
                md="6"
                class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
              >
                <label>Show</label>
                <v-select
                  v-model="perPageFinishedSupplies"
                  :options="perPageOptions"
                  :clearable="false"
                  class="per-page-selector d-inline-block mx-50"
                />
                <label>entries</label>
              </b-col>

              <!-- Search -->
              <b-col cols="12" md="6">
                <div class="d-flex align-items-center justify-content-end">
                  <b-form-input
                    v-model="searchQueryFinishedSupplies"
                    class="d-inline-block mr-1"
                    placeholder="Search..."
                  />
                </div>
              </b-col>
            </b-row>
          </div>
          <b-table
            ref="refPurchaseRequestListTableFinishedSupplies"
            class="position-relative"
            :items="fetchPurchaseRequestsFinishedSupplies"
            responsive
            :fields="tableColumnsFinishedSupplies"
            primary-key="id"
            :sort-by.sync="sortBySupplies"
            show-empty
            empty-text="No matching records found"
            :sort-desc.sync="isSortDirDescSupplies"
          >
            <!-- Column: Number -->
            <template #cell(Number)="data">
              <div class="text-nowrap">
                <feather-icon
                  v-if="data.item.purchase_request_is_confirmed == null"
                  icon="CircleIcon"
                  class="default"
                />
                <feather-icon
                  v-if="
                    data.item.purchase_request_is_confirmed == true &&
                      data.item.purchase_request_is_finished == null
                  "
                  icon="CircleIcon"
                  class="danger"
                />
                <feather-icon
                  v-if="
                    data.item.purchase_request_is_confirmed == true &&
                      data.item.purchase_request_is_finished == true
                  "
                  icon="CircleIcon"
                  class="success"
                />
                <feather-icon
                v-if="data.item.purchase_request_has_files == true"
                icon="FileIcon" />&nbsp;
                <span class="align-text-top text-capitalize">{{
                  data.item.purchase_request_number
                }}</span>
              </div>
            </template>
            <!-- Column: Class -->
            <template #cell(Class)="data">
              <div class="text-nowrap">
                <span class="align-text-top text-capitalize">{{
                  data.item.purchase_request_class
                }}</span>
              </div>
            </template>
            <!-- Column: Category -->
            <template #cell(Category)="data">
              <div class="text-nowrap">
                <feather-icon size="18" class="mr-50" />
                <span class="align-text-top text-capitalize">{{
                  data.item.purchase_request_category
                }}</span>
              </div>
            </template>
            <!-- Column: Type -->
            <template #cell(Type)="data">
              {{
                data.item.purchase_request_is_center == true
                  ? "Head Office"
                  : "Cabang"
              }}
            </template>
            <!-- Column: Cost Center -->
            <template #cell(CostCenter)="data">
              ({{ data.item.cost_center ? data.item.cost_center.branch_code : '-' }})
              {{ data.item.cost_center ? data.item.cost_center.branch_city : '-' }}
            </template>
            <!-- Column: Notes -->
            <template #cell(Notes)="data">
              {{ truncateString(data.item.purchase_request_notes, 30) }}
            </template>
            <!-- Column: Status -->
            <template #cell(Status)="data">
              <b-badge pill class="text-capitalize">
                {{
                  data.item.purchase_request_is_confirmed
                    ? "CONFIRMED"
                    : "UNCONFIRMED"
                }}
              </b-badge>
            </template>
            <!-- Column: DateCreated -->
            <template #cell(DateCreated)="data">
              {{
                dateSimple(
                  data.item.purchase_request_created_time,
                  "YYYY-MM-DD HH:mm:ss"
                )
              }}
            </template>
            <!-- Column: Actions -->
            <template #cell(actions)="data">
              <b-dropdown
                variant="link"
                no-caret
                :right="$store.state.appConfig.isRTL"
              >
                <template #button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="align-middle text-body"
                  />
                </template>

                <b-dropdown-item @click="showDetail(data.item.purchase_request_id)">
                  <feather-icon icon="ZoomInIcon" />
                  <span class="align-middle ml-50">Detail</span>
                </b-dropdown-item>
                <b-dropdown-item @click="showReviseLog(data.item)">
                  <feather-icon icon="RefreshCwIcon" />
                  <span class="align-middle ml-50">Show Revise Log</span>
                </b-dropdown-item>
                <b-dropdown-item
                  @click="showMessages(data.item)"
                >
                  <feather-icon icon="MessageCircleIcon" />
                  <span class="align-middle ml-50">Chat</span>
                </b-dropdown-item>

                <b-dropdown-item
                  @click="showDocuments(data.item.purchase_request_id)"
                >
                  <feather-icon icon="FileIcon" />
                  <span class="align-middle ml-50">Attachments</span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="
                    hasPermission('purchase_request_confirm_lab_manager') == true &&
                      data.item
                        .purchase_request_is_confirmed_by_laboratory_manager == null
                  "
                  @click="
                    confirmPurchaseRequestByLabManager(
                      data.item.purchase_request_id
                    )
                  "
                >
                  <feather-icon icon="CheckIcon" />
                  <span class="align-middle ml-50">Confirm</span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="
                    hasPermission('purchase_request_confirm_regional_manager') ==
                      true &&
                      data.item.purchase_request_is_center == true &&
                      data.item
                        .purchase_request_is_confirmed_by_laboratory_manager !=
                        null &&
                      data.item.purchase_request_is_confirmed_by_regional_manager ==
                        null
                  "
                  @click="
                    confirmPurchaseRequestByRegionalManager(
                      data.item.purchase_request_id
                    )
                  "
                >
                  <feather-icon icon="CheckIcon" />
                  <span class="align-middle ml-50">Confirm</span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="
                    hasPermission('purchase_request_confirm_division_head') ==
                      true &&
                      data.item.purchase_request_is_center == true &&
                      data.item
                        .purchase_request_is_confirmed_by_laboratory_manager !=
                        null &&
                      data.item.purchase_request_is_confirmed_by_regional_manager !=
                        null &&
                      data.item.purchase_request_is_confirmed_by_division_head ==
                        null
                  "
                  @click="
                    confirmPurchaseRequestByDivisionHead(
                      data.item.purchase_request_id
                    )
                  "
                >
                  <feather-icon icon="CheckIcon" />
                  <span class="align-middle ml-50">Confirm</span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="
                    hasPermission('purchase_request_confirm_fa_coal_manager') ==
                      true &&
                      data.item.purchase_request_is_center == true &&
                      data.item
                        .purchase_request_is_confirmed_by_laboratory_manager !=
                        null &&
                      data.item.purchase_request_is_confirmed_by_regional_manager !=
                        null &&
                      data.item.purchase_request_is_confirmed_by_division_head !=
                        null &&
                      data.item.purchase_request_is_confirmed_by_fa_coal_manager ==
                        null
                  "
                  @click="
                    confirmPurchaseRequestByFACoalManager(
                      data.item.purchase_request_id
                    )
                  "
                >
                  <feather-icon icon="CheckIcon" />
                  <span class="align-middle ml-50">Confirm</span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="
                    hasPermission('purchase_request_confirm_operation_director') ==
                      true &&
                      data.item.purchase_request_is_center == true &&
                      data.item
                        .purchase_request_is_confirmed_by_laboratory_manager !=
                        null &&
                      data.item.purchase_request_is_confirmed_by_regional_manager !=
                        null &&
                      data.item.purchase_request_is_confirmed_by_division_head !=
                        null &&
                      data.item.purchase_request_is_confirmed_by_fa_coal_manager !=
                        null &&
                      data.item
                        .purchase_request_is_confirmed_by_operation_director == null
                  "
                  @click="
                    confirmPurchaseRequestByOperationDirector(
                      data.item.purchase_request_id
                    )
                  "
                >
                  <feather-icon icon="CheckIcon" />
                  <span class="align-middle ml-50">Confirm</span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="
                    hasPermission('purchase_request_confirm_vice_president') ==
                      true &&
                      data.item.purchase_request_is_center == true &&
                      data.item
                        .purchase_request_is_confirmed_by_laboratory_manager !=
                        null &&
                      data.item.purchase_request_is_confirmed_by_regional_manager !=
                        null &&
                      data.item.purchase_request_is_confirmed_by_division_head !=
                        null &&
                      data.item.purchase_request_is_confirmed_by_fa_coal_manager !=
                        null &&
                      data.item
                        .purchase_request_is_confirmed_by_operation_director !=
                        null &&
                      data.item.purchase_request_is_confirmed_by_vice_president ==
                        null
                  "
                  @click="
                    confirmPurchaseRequestByVicePresident(
                      data.item.purchase_request_id
                    )
                  "
                >
                  <feather-icon icon="CheckIcon" />
                  <span class="align-middle ml-50">Confirm</span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="
                    hasPermission('purchase_request_confirm_president_director') ==
                      true &&
                      data.item.purchase_request_is_center == true &&
                      data.item
                        .purchase_request_is_confirmed_by_laboratory_manager !=
                        null &&
                      data.item.purchase_request_is_confirmed_by_regional_manager !=
                        null &&
                      data.item.purchase_request_is_confirmed_by_division_head !=
                        null &&
                      data.item.purchase_request_is_confirmed_by_fa_coal_manager !=
                        null &&
                      data.item
                        .purchase_request_is_confirmed_by_operation_director !=
                        null &&
                      data.item.purchase_request_is_confirmed_by_vice_president !=
                        null &&
                      data.item
                        .purchase_request_is_confirmed_by_president_director == null
                  "
                  @click="
                    confirmPurchaseRequestByPresidentDirector(
                      data.item.purchase_request_id
                    )
                  "
                >
                  <feather-icon icon="CheckIcon" />
                  <span class="align-middle ml-50">Confirm</span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="
                    hasPermission('purchase_request_confirm_procurement_pusat') ==
                      true &&
                      data.item.purchase_request_is_center == true &&
                      data.item
                        .purchase_request_is_confirmed_by_laboratory_manager !=
                        null &&
                      data.item.purchase_request_is_confirmed_by_regional_manager !=
                        null &&
                      data.item.purchase_request_is_confirmed_by_division_head !=
                        null &&
                      data.item.purchase_request_is_confirmed_by_fa_coal_manager !=
                        null &&
                      data.item
                        .purchase_request_is_confirmed_by_operation_director !=
                        null &&
                      data.item.purchase_request_is_confirmed_by_vice_president !=
                        null &&
                      data.item
                        .purchase_request_is_confirmed_by_president_director !=
                        null &&
                      data.item
                        .purchase_request_is_confirmed_by_procurement_pusat == null
                  "
                  @click="
                    confirmPurchaseRequestByProcurementPusat(
                      data.item.purchase_request_id
                    )
                  "
                >
                  <feather-icon icon="CheckIcon" />
                  <span class="align-middle ml-50">Confirm</span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="userRole=='administrator'"
                  @click="deletePurchaseRequest(data.item.purchase_request_id)"
                >
                  <feather-icon icon="TrashIcon" />
                  <span class="align-middle ml-50">Delete</span>
                </b-dropdown-item>
              </b-dropdown>
            </template>
          </b-table>
          <div class="mx-2 mb-2">
            <b-row>
              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-start"
              >
                <span class="text-muted"
                  >Showing {{ dataMeta.fromSupplies }} to {{ dataMeta.toSupplies }} of
                  {{ dataMeta.ofSupplies }} entries</span
                ><span style="padding-left: 10px;">
                <feather-icon
                  icon="CircleIcon"
                  class="default"
                /> = Just Created,
                <feather-icon
                  icon="CircleIcon"
                  class="danger"
                /> =  Accepted By Lab Manager,
                <feather-icon
                  icon="CircleIcon"
                  class="warning"
                /> =  Accepted By FA Coal Manager,
                <feather-icon
                  icon="CircleIcon"
                  class="primary"
                /> =  Accepted By President Director
                <feather-icon
                  icon="CircleIcon"
                  class="success"
                />
                 = Finished
                <feather-icon
                  style="color:#036bfc"
                  icon="MessageCircleIcon"
                /> = Message,
                <feather-icon
                icon="FileIcon" /> = Attachment
              </span>
              </b-col>
              <!-- Pagination -->
              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-end"
              >
                <b-pagination
                  v-model="currentPageFinishedSupplies"
                  :total-rows="totalPurchaseRequestsFinishedSupplies"
                  :per-page="perPageFinishedSupplies"
                  first-number
                  last-number
                  class="mb-0 mt-1 mt-sm-0"
                  prev-class="prev-item"
                  next-class="next-item"
                >
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </b-col>
            </b-row>
          </div>
        </b-tab>
        </b-tabs>
        </b-tab>
      </b-tabs>
      </b-tab>
      <b-tab title="HeadOffice">
      <b-tabs>
        <b-tab title="Needs Approval">
          <div class="m-2">
            <!-- Table Top -->
            <b-row>
              <b-col cols="5">
                <label>Tanggal Awal</label>
                <b-form-datepicker id="start-datepicker" v-model="startDateFilterHONA" class="mb-2"></b-form-datepicker>
              </b-col>
              <b-col cols="5">
                <label>Tanggal Akhir</label>
                <b-form-datepicker id="end-datepicker" v-model="endDateFilterHONA" class="mb-2"></b-form-datepicker>
              </b-col>
              <b-col cols="2" class="d-flex align-items-center justify-content-center">
                <b-button variant="primary" @click="exportPR('HO')" :disabled="isLoadingExportHONA">
                  <feather-icon icon="DownloadIcon" /> Export
                  <b-spinner small type="grow" v-if="isLoadingExportHONA"></b-spinner>
                </b-button>
              </b-col>
            </b-row>
            <b-row>
              <!-- Per Page -->
              <b-col
                cols="12"
                md="6"
                class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
              >
                <label>Show</label>
                <v-select
                  v-model="perPageHONA"
                  :options="perPageOptions"
                  :clearable="false"
                  class="per-page-selector d-inline-block mx-50"
                />
                <label>entries</label>
              </b-col>

              <!-- Search -->
              <b-col cols="12" md="6">
                <div class="d-flex align-items-center justify-content-end">
                  <b-form-input
                    v-model="searchQueryHONA"
                    class="d-inline-block mr-1"
                    placeholder="Search..."
                  />
                </div>
              </b-col>
            </b-row>
          </div>
          <b-table
            ref="refPurchaseRequestListTableHONA"
            class="position-relative"
            :items="fetchPurchaseRequestsHONA"
            responsive
            :fields="tableColumnsHONA"
            primary-key="id"
            :sort-by.sync="sortByHONA"
            show-empty
            empty-text="No matching records found"
            :sort-desc.sync="isSortDirDescHONA"
          >
            <!-- Column: Number -->
            <template #cell(Number)="data">
              <div class="text-nowrap">
                <feather-icon
                  v-if="data.item.chats !=null"
                  style="color:#036bfc"
                  icon="MessageCircleIcon"
                /><feather-icon icon="RefreshCwIcon" v-if="data.item.purchase_request_is_revised==true" class="danger"/>
                <feather-icon
                  v-if="data.item.purchase_request_is_confirmed_by_laboratory_manager == null"
                  icon="CircleIcon"
                  class="default"
                />
                <feather-icon
                  v-if="
                    data.item.purchase_request_is_confirmed_by_laboratory_manager != null &&
                      data.item.purchase_request_is_finished == null
                  "
                  icon="CircleIcon"
                  class="danger"
                />
                <feather-icon
                  v-if="
                    data.item.purchase_request_is_confirmed_by_fa_coal_manager != null &&
                      data.item.purchase_request_is_finished == null
                  "
                  icon="CircleIcon"
                  class="warning"
                />
                <feather-icon
                  v-if="
                    data.item.purchase_request_is_confirmed_by_president_director != null &&
                      data.item.purchase_request_is_finished == null
                  "
                  icon="CircleIcon"
                  class="primary"
                />
                <feather-icon
                  v-if="
                    data.item.purchase_request_is_confirmed_by_laboratory_manager != null &&
                      data.item.purchase_request_is_finished == true
                  "
                  icon="CircleIcon"
                  class="success"
                />
                <feather-icon
                v-if="data.item.purchase_request_has_files == true"
                icon="FileIcon" />&nbsp;
                <span class="align-text-top text-capitalize">{{
                  data.item.purchase_request_number
                }}</span>
              </div>
            </template>
            <!-- Column: Class -->
            <template #cell(Class)="data">
              <div class="text-nowrap">
                <span class="align-text-top text-capitalize">{{
                  data.item.purchase_request_class
                }}</span>
              </div>
            </template>
            <!-- Column: Category -->
            <template #cell(Category)="data">
              <div class="text-nowrap">
                <feather-icon size="18" class="mr-50" />
                <span class="align-text-top text-capitalize">{{
                  data.item.purchase_request_category
                }}</span>
              </div>
            </template>
            <!-- Column: Type -->
            <template #cell(Type)="data">
              {{
                data.item.purchase_request_is_center == true
                  ? "Head Office"
                  : "Cabang"
              }}
            </template>
            <!-- Column: Cost Center -->
            <template #cell(CostCenter)="data">
              ({{ data.item.cost_center ? data.item.cost_center.branch_code : '-' }})
              {{ data.item.cost_center ? data.item.cost_center.branch_city : '-' }}
            </template>
            <!-- Column: Notes -->
            <template #cell(Notes)="data">
              {{ truncateString(data.item.purchase_request_notes, 30) }}
            </template>
            <!-- Column: Status -->
            <template #cell(Status)="data">
              <b-badge pill class="text-capitalize">
                {{
                  data.item.purchase_request_is_confirmed
                    ? "CONFIRMED"
                    : "UNCONFIRMED"
                }}
              </b-badge>
            </template>
            <!-- Column: DateCreated -->
            <template #cell(DateCreated)="data">
              {{
                dateSimple(
                  data.item.purchase_request_created_time,
                  "YYYY-MM-DD HH:mm:ss"
                )
              }}
            </template>
            <!-- Column: Actions -->
            <template #cell(actions)="data">
              <b-dropdown
                variant="link"
                no-caret
                :right="$store.state.appConfig.isRTL"
              >
                <template #button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="align-middle text-body"
                  />
                </template>

                <b-dropdown-item @click="showDetail(data.item.purchase_request_id)">
                  <feather-icon icon="ZoomInIcon" />
                  <span class="align-middle ml-50">Detail</span>
                </b-dropdown-item>
                <b-dropdown-item @click="showReviseLog(data.item)">
                  <feather-icon icon="RefreshCwIcon" />
                  <span class="align-middle ml-50">Show Revise Log</span>
                </b-dropdown-item>
                <b-dropdown-item
  @click="setRevise(data.item.purchase_request_id)"
  v-if="checkRevise(data.item) && ['president-director', 'vice-president', 'fa-coal-manager', 'operation-director', 'division-head', 'regional-manager', 'laboratory-manager'].includes(userRole)">
  <feather-icon icon="RefreshCwIcon" />
  <span class="align-middle ml-50">Revise</span>
</b-dropdown-item>
          <b-dropdown-item
                  v-if="
                    hasPermission('purchase_request_update') == true &&
                      data.item.purchase_request_is_confirmed_by_purchasing_cabang == null && data.item.purchase_request_is_confirmed_by_laboratory_manager == null
                  "
                  @click="editPurchaseRequest(data.item)"
                >
                  <feather-icon icon="EditIcon" />
                  <span class="align-middle ml-50">Edit</span>
                </b-dropdown-item>
                <b-dropdown-item
                  @click="showMessages(data.item)"
                >
                  <feather-icon icon="MessageCircleIcon" />
                  <span class="align-middle ml-50">Chat</span>
                </b-dropdown-item>
                <b-dropdown-item
                  @click="showDocuments(data.item.purchase_request_id)"
                >
                  <feather-icon icon="FileIcon" />
                  <span class="align-middle ml-50">Attachments</span>
                </b-dropdown-item>
                <!-- Confirm Assets/FGS/Supplies By Purchasing -->
                <b-dropdown-item
                  v-if="
                    hasPermission('purchase_request_confirm_purchasing') == true &&
                    data.item.purchase_request_is_center == false &&
                    ( data.item.purchase_request_category == 'FGS' || data.item.purchase_request_category == 'Assets' || data.item.purchase_request_category == 'Supplies')  &&
                    data.item.purchase_request_is_confirmed_by_purchasing_cabang == null
                  "
                  @click="
                    confirmPurchaseRequestByPurchasing(
                      data.item.purchase_request_id
                    )
                  "
                >
                  <feather-icon icon="CheckIcon" />
                  <span class="align-middle ml-50">Confirm</span>
                </b-dropdown-item>
                <!-- Confirm Supplies By Lab Manager-->
                <b-dropdown-item
                  v-if="
                    hasPermission('purchase_request_confirm_lab_manager') == true &&
                    data.item.purchase_request_is_center == false &&
                    data.item.purchase_request_category == 'Supplies' &&
                    data.item.purchase_request_is_confirmed_by_purchasing_cabang != null &&
                      data.item
                        .purchase_request_is_confirmed_by_laboratory_manager == null
                  "
                  @click="
                    confirmPurchaseRequestByLabManager(
                      data.item.purchase_request_id
                    )
                  "
                >
                  <feather-icon icon="CheckIcon" />
                  <span class="align-middle ml-50">Confirm</span>
                </b-dropdown-item>
                <!-- Confirm Assets/FGS By Lab Manager -->
                <b-dropdown-item
                  v-if="
                    hasPermission('purchase_request_confirm_lab_manager') == true &&
                    data.item.purchase_request_is_center == false &&
                    ( data.item.purchase_request_category == 'FGS' || data.item.purchase_request_category == 'Assets')  &&
                    data.item.purchase_request_is_confirmed_by_purchasing_cabang != null &&
                      data.item
                        .purchase_request_is_confirmed_by_laboratory_manager == null
                  "
                  @click="
                    confirmPurchaseRequestByLabManager(
                      data.item.purchase_request_id
                    )
                  "
                >
                  <feather-icon icon="CheckIcon" />
                  <span class="align-middle ml-50">Confirm</span>
                </b-dropdown-item>
                <!-- Confirm HO By Lab Manager -->
                <b-dropdown-item
                  v-if="
                    hasPermission('purchase_request_confirm_lab_manager') == true &&
                    data.item.purchase_request_is_center == true &&
                      data.item
                        .purchase_request_is_confirmed_by_laboratory_manager == null
                  "
                  @click="
                    confirmPurchaseRequestByLabManager(
                      data.item.purchase_request_id
                    )
                  "
                >
                  <feather-icon icon="CheckIcon" />
                  <span class="align-middle ml-50">Confirm</span>
                </b-dropdown-item>
                <!-- Confirm Assets/FGS By Regional Manager -->
                <b-dropdown-item
                  v-if="
                    hasPermission('purchase_request_confirm_regional_manager') == true &&
                    data.item.purchase_request_is_center == false &&
                    ( data.item.purchase_request_category == 'FGS' || data.item.purchase_request_category == 'Assets')  &&
                    data.item.purchase_request_is_confirmed_by_purchasing_cabang != null &&
                      data.item.purchase_request_is_confirmed_by_laboratory_manager != null &&
                      data.item.purchase_request_is_confirmed_by_regional_manager == null
                  "
                  @click="
                    confirmPurchaseRequestByRegionalManager(
                      data.item.purchase_request_id
                    )
                  "
                >
                  <feather-icon icon="CheckIcon" />
                  <span class="align-middle ml-50">Confirm</span>
                </b-dropdown-item>
                <!-- Confirm Assets/FGS By Division Head -->
                <b-dropdown-item
                  v-if="
                    hasPermission('purchase_request_confirm_division_head') == true &&
                    data.item.purchase_request_is_center == false &&
                    ( data.item.purchase_request_category == 'FGS' || data.item.purchase_request_category == 'Assets')  &&
                    data.item.purchase_request_is_confirmed_by_purchasing_cabang != null &&
                      data.item.purchase_request_is_confirmed_by_laboratory_manager != null &&
                      data.item.purchase_request_is_confirmed_by_regional_manager != null &&
                      data.item.purchase_request_is_confirmed_by_division_head == null
                  "
                  @click="
                    confirmPurchaseRequestByDivisionHead(
                      data.item.purchase_request_id
                    )
                  "
                >
                  <feather-icon icon="CheckIcon" />
                  <span class="align-middle ml-50">Confirm</span>
                </b-dropdown-item>
                <!-- Confirm Assets/FGS By FA Coal Manager -->
                <b-dropdown-item
                  v-if="
                    hasPermission('purchase_request_confirm_fa_coal_manager') ==
                      true &&
                      data.item.purchase_request_is_center == false &&
                    ( data.item.purchase_request_category == 'FGS' || data.item.purchase_request_category == 'Assets')  &&
                    data.item.purchase_request_is_confirmed_by_purchasing_cabang != null &&
                      data.item.purchase_request_is_confirmed_by_laboratory_manager != null &&
                      data.item.purchase_request_is_confirmed_by_regional_manager != null &&
                      data.item.purchase_request_is_confirmed_by_division_head != null&&
                      data.item.purchase_request_is_confirmed_by_fa_coal_manager == null
                  "
                  @click="
                    confirmPurchaseRequestByFACoalManager(
                      data.item.purchase_request_id
                    )
                  "
                >
                  <feather-icon icon="CheckIcon" />
                  <span class="align-middle ml-50">Confirm</span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="
                    hasPermission('purchase_request_confirm_regional_manager') ==
                      true &&
                      data.item.purchase_request_is_center == true &&
                      data.item
                        .purchase_request_is_confirmed_by_laboratory_manager !=
                        null &&
                      data.item.purchase_request_is_confirmed_by_regional_manager ==
                        null
                  "
                  @click="
                    confirmPurchaseRequestByRegionalManager(
                      data.item.purchase_request_id
                    )
                  "
                >
                  <feather-icon icon="CheckIcon" />
                  <span class="align-middle ml-50">Confirm</span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="
                    hasPermission('purchase_request_confirm_division_head') ==
                      true &&
                      data.item.purchase_request_is_center == true &&
                      data.item
                        .purchase_request_is_confirmed_by_laboratory_manager !=
                        null &&
                      data.item.purchase_request_is_confirmed_by_regional_manager !=
                        null &&
                      data.item.purchase_request_is_confirmed_by_division_head ==
                        null
                  "
                  @click="
                    confirmPurchaseRequestByDivisionHead(
                      data.item.purchase_request_id
                    )
                  "
                >
                  <feather-icon icon="CheckIcon" />
                  <span class="align-middle ml-50">Confirm</span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="
                    hasPermission('purchase_request_confirm_fa_coal_manager') ==
                      true &&
                      data.item.purchase_request_is_center == true &&
                      data.item
                        .purchase_request_is_confirmed_by_laboratory_manager !=
                        null &&
                      data.item.purchase_request_is_confirmed_by_regional_manager !=
                        null &&
                      data.item.purchase_request_is_confirmed_by_division_head !=
                        null &&
                      data.item.purchase_request_is_confirmed_by_fa_coal_manager ==
                        null
                  "
                  @click="
                    confirmPurchaseRequestByFACoalManager(
                      data.item.purchase_request_id
                    )
                  "
                >
                  <feather-icon icon="CheckIcon" />
                  <span class="align-middle ml-50">Confirm</span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="
                    hasPermission('purchase_request_confirm_operation_director') ==
                      true &&
                      data.item.purchase_request_is_center == true &&
                      data.item
                        .purchase_request_is_confirmed_by_laboratory_manager !=
                        null &&
                      data.item.purchase_request_is_confirmed_by_regional_manager !=
                        null &&
                      data.item.purchase_request_is_confirmed_by_division_head !=
                        null &&
                      data.item.purchase_request_is_confirmed_by_fa_coal_manager !=
                        null &&
                      data.item
                        .purchase_request_is_confirmed_by_operation_director == null
                  "
                  @click="
                    confirmPurchaseRequestByOperationDirector(
                      data.item.purchase_request_id
                    )
                  "
                >
                  <feather-icon icon="CheckIcon" />
                  <span class="align-middle ml-50">Confirm</span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="
                    hasPermission('purchase_request_confirm_vice_president') ==
                      true &&
                      data.item.purchase_request_is_center == true &&
                      data.item
                        .purchase_request_is_confirmed_by_laboratory_manager !=
                        null &&
                      data.item.purchase_request_is_confirmed_by_regional_manager !=
                        null &&
                      data.item.purchase_request_is_confirmed_by_division_head !=
                        null &&
                      data.item.purchase_request_is_confirmed_by_fa_coal_manager !=
                        null &&
                      data.item
                        .purchase_request_is_confirmed_by_operation_director !=
                        null &&
                      data.item.purchase_request_is_confirmed_by_vice_president ==
                        null
                  "
                  @click="
                    confirmPurchaseRequestByVicePresident(
                      data.item.purchase_request_id
                    )
                  "
                >
                  <feather-icon icon="CheckIcon" />
                  <span class="align-middle ml-50">Confirm</span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="
                    hasPermission('purchase_request_confirm_president_director') ==
                      true &&
                      data.item.purchase_request_is_center == true &&
                      data.item
                        .purchase_request_is_confirmed_by_laboratory_manager !=
                        null &&
                      data.item.purchase_request_is_confirmed_by_regional_manager !=
                        null &&
                      data.item.purchase_request_is_confirmed_by_division_head !=
                        null &&
                      data.item.purchase_request_is_confirmed_by_fa_coal_manager !=
                        null &&
                      data.item
                        .purchase_request_is_confirmed_by_operation_director !=
                        null &&
                      data.item.purchase_request_is_confirmed_by_vice_president !=
                        null &&
                      data.item
                        .purchase_request_is_confirmed_by_president_director == null
                  "
                  @click="
                    confirmPurchaseRequestByPresidentDirector(
                      data.item.purchase_request_id
                    )
                  "
                >
                  <feather-icon icon="CheckIcon" />
                  <span class="align-middle ml-50">Confirm</span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="
                    hasPermission('purchase_request_confirm_procurement_pusat') ==
                      true &&
                      data.item.purchase_request_is_center == true &&
                      data.item
                        .purchase_request_is_confirmed_by_laboratory_manager !=
                        null &&
                      data.item.purchase_request_is_confirmed_by_regional_manager !=
                        null &&
                      data.item.purchase_request_is_confirmed_by_division_head !=
                        null &&
                      data.item.purchase_request_is_confirmed_by_fa_coal_manager !=
                        null &&
                      data.item
                        .purchase_request_is_confirmed_by_operation_director !=
                        null &&
                      data.item.purchase_request_is_confirmed_by_vice_president !=
                        null &&
                      data.item
                        .purchase_request_is_confirmed_by_president_director !=
                        null &&
                      data.item
                        .purchase_request_is_confirmed_by_procurement_pusat == null
                  "
                  @click="
                    confirmPurchaseRequestByProcurementPusat(
                      data.item.purchase_request_id
                    )
                  "
                >
                  <feather-icon icon="CheckIcon" />
                  <span class="align-middle ml-50">Confirm</span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="userRole=='administrator'"
                  @click="deletePurchaseRequest(data.item.purchase_request_id)"
                >
                  <feather-icon icon="TrashIcon" />
                  <span class="align-middle ml-50">Delete</span>
                </b-dropdown-item>
              </b-dropdown>
            </template>
          </b-table>
          <div class="mx-2 mb-2">
            <b-row>
              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-start"
              >
                <span class="text-muted"
                  >Showing {{ dataMeta.fromHONA }} to {{ dataMeta.toHONA }} of
                  {{ dataMeta.ofHONA }} entries</span
                ><span style="padding-left: 10px;">
                <feather-icon
                  icon="CircleIcon"
                  class="default"
                /> = Just Created,
                <feather-icon
                  icon="CircleIcon"
                  class="danger"
                /> =  Accepted By Lab Manager,
                <feather-icon
                  icon="CircleIcon"
                  class="warning"
                /> =  Accepted By FA Coal Manager,
                <feather-icon
                  icon="CircleIcon"
                  class="primary"
                /> =  Accepted By President Director
                <feather-icon
                  icon="CircleIcon"
                  class="success"
                />
                 = Finished
                <feather-icon
                  style="color:#036bfc"
                  icon="MessageCircleIcon"
                /> = Message,
                <feather-icon
                icon="FileIcon" /> = Attachment
              </span>
              </b-col>
              <!-- Pagination -->
              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-end"
              >
                <b-pagination
                  v-model="currentPageHONA"
                  :total-rows="totalPurchaseRequestsHONA"
                  :per-page="perPageHONA"
                  first-number
                  last-number
                  class="mb-0 mt-1 mt-sm-0"
                  prev-class="prev-item"
                  next-class="next-item"
                >
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </b-col>
            </b-row>
          </div>
        </b-tab>
        <b-tab title="On Going">
          <div class="m-2">
            <!-- Table Top -->
            <b-row>
              <b-col cols="5">
                <label>Tanggal Awal</label>
                <b-form-datepicker id="start-datepicker" v-model="startDateFilterHO" class="mb-2"></b-form-datepicker>
              </b-col>
              <b-col cols="5">
                <label>Tanggal Akhir</label>
                <b-form-datepicker id="end-datepicker" v-model="endDateFilterHO" class="mb-2"></b-form-datepicker>
              </b-col>
              <b-col cols="2" class="d-flex align-items-center justify-content-center">
                <b-button variant="primary" @click="exportPR('HO')" :disabled="isLoadingExportHO">
                  <feather-icon icon="DownloadIcon" /> Export
                  <b-spinner small type="grow" v-if="isLoadingExportHO"></b-spinner>
                </b-button>
              </b-col>
            </b-row>
            <b-row>
              <!-- Per Page -->
              <b-col
                cols="12"
                md="6"
                class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
              >
                <label>Show</label>
                <v-select
                  v-model="perPageHO"
                  :options="perPageOptions"
                  :clearable="false"
                  class="per-page-selector d-inline-block mx-50"
                />
                <label>entries</label>
              </b-col>

              <!-- Search -->
              <b-col cols="12" md="6">
                <div class="d-flex align-items-center justify-content-end">
                  <b-form-input
                    v-model="searchQueryHO"
                    class="d-inline-block mr-1"
                    placeholder="Search..."
                  />
                </div>
              </b-col>
            </b-row>
          </div>
          <b-table
            ref="refPurchaseRequestListTableHO"
            class="position-relative"
            :items="fetchPurchaseRequestsHO"
            responsive
            :fields="tableColumnsHO"
            primary-key="id"
            :sort-by.sync="sortByHO"
            show-empty
            empty-text="No matching records found"
            :sort-desc.sync="isSortDirDescHO"
          >
            <!-- Column: Number -->
            <template #cell(Number)="data">
              <div class="text-nowrap">
                <feather-icon
                  v-if="data.item.chats !=null"
                  style="color:#036bfc"
                  icon="MessageCircleIcon"
                /><feather-icon icon="RefreshCwIcon" v-if="data.item.purchase_request_is_revised==true" class="danger"/>
                <feather-icon
                  v-if="data.item.purchase_request_is_confirmed_by_laboratory_manager == null"
                  icon="CircleIcon"
                  class="default"
                />
                <feather-icon
                  v-if="
                    data.item.purchase_request_is_confirmed_by_laboratory_manager != null &&
                      data.item.purchase_request_is_finished == null
                  "
                  icon="CircleIcon"
                  class="danger"
                />
                <feather-icon
                  v-if="
                    data.item.purchase_request_is_confirmed_by_fa_coal_manager != null &&
                      data.item.purchase_request_is_finished == null
                  "
                  icon="CircleIcon"
                  class="warning"
                />
                <feather-icon
                  v-if="
                    data.item.purchase_request_is_confirmed_by_president_director != null &&
                      data.item.purchase_request_is_finished == null
                  "
                  icon="CircleIcon"
                  class="primary"
                />
                <feather-icon
                  v-if="
                    data.item.purchase_request_is_confirmed_by_laboratory_manager != null &&
                      data.item.purchase_request_is_finished == true
                  "
                  icon="CircleIcon"
                  class="success"
                />
                <feather-icon
                v-if="data.item.purchase_request_has_files == true"
                icon="FileIcon" />&nbsp;
                <span class="align-text-top text-capitalize">{{
                  data.item.purchase_request_number
                }}</span>
              </div>
            </template>
            <!-- Column: Class -->
            <template #cell(Class)="data">
              <div class="text-nowrap">
                <span class="align-text-top text-capitalize">{{
                  data.item.purchase_request_class
                }}</span>
              </div>
            </template>
            <!-- Column: Category -->
            <template #cell(Category)="data">
              <div class="text-nowrap">
                <feather-icon size="18" class="mr-50" />
                <span class="align-text-top text-capitalize">{{
                  data.item.purchase_request_category
                }}</span>
              </div>
            </template>
            <!-- Column: Type -->
            <template #cell(Type)="data">
              {{
                data.item.purchase_request_is_center == true
                  ? "Head Office"
                  : "Cabang"
              }}
            </template>
            <!-- Column: Cost Center -->
            <template #cell(CostCenter)="data">
              ({{ data.item.cost_center ? data.item.cost_center.branch_code : '-' }})
              {{ data.item.cost_center ? data.item.cost_center.branch_city : '-' }}
            </template>
            <!-- Column: Notes -->
            <template #cell(Notes)="data">
              {{ truncateString(data.item.purchase_request_notes, 30) }}
            </template>
            <!-- Column: Status -->
            <template #cell(Status)="data">
              <b-badge pill class="text-capitalize">
                {{
                  data.item.purchase_request_is_confirmed
                    ? "CONFIRMED"
                    : "UNCONFIRMED"
                }}
              </b-badge>
            </template>
            <!-- Column: DateCreated -->
            <template #cell(DateCreated)="data">
              {{
                dateSimple(
                  data.item.purchase_request_created_time,
                  "YYYY-MM-DD HH:mm:ss"
                )
              }}
            </template>
            <!-- Column: Actions -->
            <template #cell(actions)="data">
              <b-dropdown
                variant="link"
                no-caret
                :right="$store.state.appConfig.isRTL"
              >
                <template #button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="align-middle text-body"
                  />
                </template>

                <b-dropdown-item @click="showDetail(data.item.purchase_request_id)">
                  <feather-icon icon="ZoomInIcon" />
                  <span class="align-middle ml-50">Detail</span>
                </b-dropdown-item>
                <b-dropdown-item @click="showReviseLog(data.item)">
                  <feather-icon icon="RefreshCwIcon" />
                  <span class="align-middle ml-50">Show Revise Log</span>
                </b-dropdown-item>
                <b-dropdown-item
  @click="setRevise(data.item.purchase_request_id)"
  v-if="checkRevise(data.item) && ['president-director', 'vice-president', 'fa-coal-manager', 'operation-director', 'division-head', 'regional-manager', 'laboratory-manager'].includes(userRole)">
  <feather-icon icon="RefreshCwIcon" />
  <span class="align-middle ml-50">Revise</span>
</b-dropdown-item>
          <b-dropdown-item
                  v-if="
                    hasPermission('purchase_request_update') == true &&
                      data.item.purchase_request_is_confirmed_by_purchasing_cabang == null && data.item.purchase_request_is_confirmed_by_laboratory_manager == null
                  "
                  @click="editPurchaseRequest(data.item)"
                >
                  <feather-icon icon="EditIcon" />
                  <span class="align-middle ml-50">Edit</span>
                </b-dropdown-item>
                <b-dropdown-item
                  @click="showMessages(data.item)"
                >
                  <feather-icon icon="MessageCircleIcon" />
                  <span class="align-middle ml-50">Chat</span>
                </b-dropdown-item>
                <b-dropdown-item
                  @click="showDocuments(data.item.purchase_request_id)"
                >
                  <feather-icon icon="FileIcon" />
                  <span class="align-middle ml-50">Attachments</span>
                </b-dropdown-item>
                <!-- Confirm Assets/FGS/Supplies By Purchasing -->
                <b-dropdown-item
                  v-if="
                    hasPermission('purchase_request_confirm_purchasing') == true &&
                    data.item.purchase_request_is_center == false &&
                    ( data.item.purchase_request_category == 'FGS' || data.item.purchase_request_category == 'Assets' || data.item.purchase_request_category == 'Supplies')  &&
                    data.item.purchase_request_is_confirmed_by_purchasing_cabang == null
                  "
                  @click="
                    confirmPurchaseRequestByPurchasing(
                      data.item.purchase_request_id
                    )
                  "
                >
                  <feather-icon icon="CheckIcon" />
                  <span class="align-middle ml-50">Confirm</span>
                </b-dropdown-item>
                <!-- Confirm Supplies By Lab Manager-->
                <b-dropdown-item
                  v-if="
                    hasPermission('purchase_request_confirm_lab_manager') == true &&
                    data.item.purchase_request_is_center == false &&
                    data.item.purchase_request_category == 'Supplies' &&
                    data.item.purchase_request_is_confirmed_by_purchasing_cabang != null &&
                      data.item
                        .purchase_request_is_confirmed_by_laboratory_manager == null
                  "
                  @click="
                    confirmPurchaseRequestByLabManager(
                      data.item.purchase_request_id
                    )
                  "
                >
                  <feather-icon icon="CheckIcon" />
                  <span class="align-middle ml-50">Confirm</span>
                </b-dropdown-item>
                <!-- Confirm Assets/FGS By Lab Manager -->
                <b-dropdown-item
                  v-if="
                    hasPermission('purchase_request_confirm_lab_manager') == true &&
                    data.item.purchase_request_is_center == false &&
                    ( data.item.purchase_request_category == 'FGS' || data.item.purchase_request_category == 'Assets')  &&
                    data.item.purchase_request_is_confirmed_by_purchasing_cabang != null &&
                      data.item
                        .purchase_request_is_confirmed_by_laboratory_manager == null
                  "
                  @click="
                    confirmPurchaseRequestByLabManager(
                      data.item.purchase_request_id
                    )
                  "
                >
                  <feather-icon icon="CheckIcon" />
                  <span class="align-middle ml-50">Confirm</span>
                </b-dropdown-item>
                <!-- Confirm HO By Lab Manager -->
                <b-dropdown-item
                  v-if="
                    hasPermission('purchase_request_confirm_lab_manager') == true &&
                    data.item.purchase_request_is_center == true &&
                      data.item
                        .purchase_request_is_confirmed_by_laboratory_manager == null
                  "
                  @click="
                    confirmPurchaseRequestByLabManager(
                      data.item.purchase_request_id
                    )
                  "
                >
                  <feather-icon icon="CheckIcon" />
                  <span class="align-middle ml-50">Confirm</span>
                </b-dropdown-item>
                <!-- Confirm Assets/FGS By Regional Manager -->
                <b-dropdown-item
                  v-if="
                    hasPermission('purchase_request_confirm_regional_manager') == true &&
                    data.item.purchase_request_is_center == false &&
                    ( data.item.purchase_request_category == 'FGS' || data.item.purchase_request_category == 'Assets')  &&
                    data.item.purchase_request_is_confirmed_by_purchasing_cabang != null &&
                      data.item.purchase_request_is_confirmed_by_laboratory_manager != null &&
                      data.item.purchase_request_is_confirmed_by_regional_manager == null
                  "
                  @click="
                    confirmPurchaseRequestByRegionalManager(
                      data.item.purchase_request_id
                    )
                  "
                >
                  <feather-icon icon="CheckIcon" />
                  <span class="align-middle ml-50">Confirm</span>
                </b-dropdown-item>
                <!-- Confirm Assets/FGS By Division Head -->
                <b-dropdown-item
                  v-if="
                    hasPermission('purchase_request_confirm_division_head') == true &&
                    data.item.purchase_request_is_center == false &&
                    ( data.item.purchase_request_category == 'FGS' || data.item.purchase_request_category == 'Assets')  &&
                    data.item.purchase_request_is_confirmed_by_purchasing_cabang != null &&
                      data.item.purchase_request_is_confirmed_by_laboratory_manager != null &&
                      data.item.purchase_request_is_confirmed_by_regional_manager != null &&
                      data.item.purchase_request_is_confirmed_by_division_head == null
                  "
                  @click="
                    confirmPurchaseRequestByDivisionHead(
                      data.item.purchase_request_id
                    )
                  "
                >
                  <feather-icon icon="CheckIcon" />
                  <span class="align-middle ml-50">Confirm</span>
                </b-dropdown-item>
                <!-- Confirm Assets/FGS By FA Coal Manager -->
                <b-dropdown-item
                  v-if="
                    hasPermission('purchase_request_confirm_fa_coal_manager') ==
                      true &&
                      data.item.purchase_request_is_center == false &&
                    ( data.item.purchase_request_category == 'FGS' || data.item.purchase_request_category == 'Assets')  &&
                    data.item.purchase_request_is_confirmed_by_purchasing_cabang != null &&
                      data.item.purchase_request_is_confirmed_by_laboratory_manager != null &&
                      data.item.purchase_request_is_confirmed_by_regional_manager != null &&
                      data.item.purchase_request_is_confirmed_by_division_head != null&&
                      data.item.purchase_request_is_confirmed_by_fa_coal_manager == null
                  "
                  @click="
                    confirmPurchaseRequestByFACoalManager(
                      data.item.purchase_request_id
                    )
                  "
                >
                  <feather-icon icon="CheckIcon" />
                  <span class="align-middle ml-50">Confirm</span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="
                    hasPermission('purchase_request_confirm_regional_manager') ==
                      true &&
                      data.item.purchase_request_is_center == true &&
                      data.item
                        .purchase_request_is_confirmed_by_laboratory_manager !=
                        null &&
                      data.item.purchase_request_is_confirmed_by_regional_manager ==
                        null
                  "
                  @click="
                    confirmPurchaseRequestByRegionalManager(
                      data.item.purchase_request_id
                    )
                  "
                >
                  <feather-icon icon="CheckIcon" />
                  <span class="align-middle ml-50">Confirm</span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="
                    hasPermission('purchase_request_confirm_division_head') ==
                      true &&
                      data.item.purchase_request_is_center == true &&
                      data.item
                        .purchase_request_is_confirmed_by_laboratory_manager !=
                        null &&
                      data.item.purchase_request_is_confirmed_by_regional_manager !=
                        null &&
                      data.item.purchase_request_is_confirmed_by_division_head ==
                        null
                  "
                  @click="
                    confirmPurchaseRequestByDivisionHead(
                      data.item.purchase_request_id
                    )
                  "
                >
                  <feather-icon icon="CheckIcon" />
                  <span class="align-middle ml-50">Confirm</span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="
                    hasPermission('purchase_request_confirm_fa_coal_manager') ==
                      true &&
                      data.item.purchase_request_is_center == true &&
                      data.item
                        .purchase_request_is_confirmed_by_laboratory_manager !=
                        null &&
                      data.item.purchase_request_is_confirmed_by_regional_manager !=
                        null &&
                      data.item.purchase_request_is_confirmed_by_division_head !=
                        null &&
                      data.item.purchase_request_is_confirmed_by_fa_coal_manager ==
                        null
                  "
                  @click="
                    confirmPurchaseRequestByFACoalManager(
                      data.item.purchase_request_id
                    )
                  "
                >
                  <feather-icon icon="CheckIcon" />
                  <span class="align-middle ml-50">Confirm</span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="
                    hasPermission('purchase_request_confirm_operation_director') ==
                      true &&
                      data.item.purchase_request_is_center == true &&
                      data.item
                        .purchase_request_is_confirmed_by_laboratory_manager !=
                        null &&
                      data.item.purchase_request_is_confirmed_by_regional_manager !=
                        null &&
                      data.item.purchase_request_is_confirmed_by_division_head !=
                        null &&
                      data.item.purchase_request_is_confirmed_by_fa_coal_manager !=
                        null &&
                      data.item
                        .purchase_request_is_confirmed_by_operation_director == null
                  "
                  @click="
                    confirmPurchaseRequestByOperationDirector(
                      data.item.purchase_request_id
                    )
                  "
                >
                  <feather-icon icon="CheckIcon" />
                  <span class="align-middle ml-50">Confirm</span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="
                    hasPermission('purchase_request_confirm_vice_president') ==
                      true &&
                      data.item.purchase_request_is_center == true &&
                      data.item
                        .purchase_request_is_confirmed_by_laboratory_manager !=
                        null &&
                      data.item.purchase_request_is_confirmed_by_regional_manager !=
                        null &&
                      data.item.purchase_request_is_confirmed_by_division_head !=
                        null &&
                      data.item.purchase_request_is_confirmed_by_fa_coal_manager !=
                        null &&
                      data.item
                        .purchase_request_is_confirmed_by_operation_director !=
                        null &&
                      data.item.purchase_request_is_confirmed_by_vice_president ==
                        null
                  "
                  @click="
                    confirmPurchaseRequestByVicePresident(
                      data.item.purchase_request_id
                    )
                  "
                >
                  <feather-icon icon="CheckIcon" />
                  <span class="align-middle ml-50">Confirm</span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="
                    hasPermission('purchase_request_confirm_president_director') ==
                      true &&
                      data.item.purchase_request_is_center == true &&
                      data.item
                        .purchase_request_is_confirmed_by_laboratory_manager !=
                        null &&
                      data.item.purchase_request_is_confirmed_by_regional_manager !=
                        null &&
                      data.item.purchase_request_is_confirmed_by_division_head !=
                        null &&
                      data.item.purchase_request_is_confirmed_by_fa_coal_manager !=
                        null &&
                      data.item
                        .purchase_request_is_confirmed_by_operation_director !=
                        null &&
                      data.item.purchase_request_is_confirmed_by_vice_president !=
                        null &&
                      data.item
                        .purchase_request_is_confirmed_by_president_director == null
                  "
                  @click="
                    confirmPurchaseRequestByPresidentDirector(
                      data.item.purchase_request_id
                    )
                  "
                >
                  <feather-icon icon="CheckIcon" />
                  <span class="align-middle ml-50">Confirm</span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="
                    hasPermission('purchase_request_confirm_procurement_pusat') ==
                      true &&
                      data.item.purchase_request_is_center == true &&
                      data.item
                        .purchase_request_is_confirmed_by_laboratory_manager !=
                        null &&
                      data.item.purchase_request_is_confirmed_by_regional_manager !=
                        null &&
                      data.item.purchase_request_is_confirmed_by_division_head !=
                        null &&
                      data.item.purchase_request_is_confirmed_by_fa_coal_manager !=
                        null &&
                      data.item
                        .purchase_request_is_confirmed_by_operation_director !=
                        null &&
                      data.item.purchase_request_is_confirmed_by_vice_president !=
                        null &&
                      data.item
                        .purchase_request_is_confirmed_by_president_director !=
                        null &&
                      data.item
                        .purchase_request_is_confirmed_by_procurement_pusat == null
                  "
                  @click="
                    confirmPurchaseRequestByProcurementPusat(
                      data.item.purchase_request_id
                    )
                  "
                >
                  <feather-icon icon="CheckIcon" />
                  <span class="align-middle ml-50">Confirm</span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="userRole=='administrator'"
                  @click="deletePurchaseRequest(data.item.purchase_request_id)"
                >
                  <feather-icon icon="TrashIcon" />
                  <span class="align-middle ml-50">Delete</span>
                </b-dropdown-item>
              </b-dropdown>
            </template>
          </b-table>
          <div class="mx-2 mb-2">
            <b-row>
              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-start"
              >
                <span class="text-muted"
                  >Showing {{ dataMeta.fromHO }} to {{ dataMeta.toHO }} of
                  {{ dataMeta.ofHO }} entries</span
                ><span style="padding-left: 10px;">
                <feather-icon
                  icon="CircleIcon"
                  class="default"
                /> = Just Created,
                <feather-icon
                  icon="CircleIcon"
                  class="danger"
                /> =  Accepted By Lab Manager,
                <feather-icon
                  icon="CircleIcon"
                  class="warning"
                /> =  Accepted By FA Coal Manager,
                <feather-icon
                  icon="CircleIcon"
                  class="primary"
                /> =  Accepted By President Director
                <feather-icon
                  icon="CircleIcon"
                  class="success"
                />
                 = Finished
                <feather-icon
                  style="color:#036bfc"
                  icon="MessageCircleIcon"
                /> = Message,
                <feather-icon
                icon="FileIcon" /> = Attachment
              </span>
              </b-col>
              <!-- Pagination -->
              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-end"
              >
                <b-pagination
                  v-model="currentPageHO"
                  :total-rows="totalPurchaseRequestsHO"
                  :per-page="perPageHO"
                  first-number
                  last-number
                  class="mb-0 mt-1 mt-sm-0"
                  prev-class="prev-item"
                  next-class="next-item"
                >
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </b-col>
            </b-row>
          </div>
        </b-tab>
        <b-tab title="Finished">
          <div class="m-2">
            <!-- Table Top -->
            <b-row>
              <b-col cols="5">
                <label>Tanggal Awal</label>
                <b-form-datepicker id="start-datepicker-finished" v-model="startDateFilterFinishedHO" class="mb-2"></b-form-datepicker>
              </b-col>
              <b-col cols="5">
                <label>Tanggal Akhir</label>
                <b-form-datepicker id="end-datepicker-finished" v-model="endDateFilterFinishedHO" class="mb-2"></b-form-datepicker>
              </b-col>
              <b-col cols="2" class="d-flex align-items-center justify-content-center">
                <b-button variant="primary" @click="exportPRFinished('HO')" :disabled="isLoadingExportFinishedHO">
                  <feather-icon icon="DownloadIcon" /> Export
                  <b-spinner small type="grow" v-if="isLoadingExportFinished"></b-spinner>
                </b-button>
              </b-col>
            </b-row>
            <b-row>
              <!-- Per Page -->
              <b-col
                cols="12"
                md="6"
                class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
              >
                <label>Show</label>
                <v-select
                  v-model="perPageFinishedHO"
                  :options="perPageOptions"
                  :clearable="false"
                  class="per-page-selector d-inline-block mx-50"
                />
                <label>entries</label>
              </b-col>

              <!-- Search -->
              <b-col cols="12" md="6">
                <div class="d-flex align-items-center justify-content-end">
                  <b-form-input
                    v-model="searchQueryFinishedHO"
                    class="d-inline-block mr-1"
                    placeholder="Search..."
                  />
                </div>
              </b-col>
            </b-row>
          </div>
          <b-table
            ref="refPurchaseRequestListTableFinishedHO"
            class="position-relative"
            :items="fetchPurchaseRequestsFinishedHO"
            responsive
            :fields="tableColumnsFinishedHO"
            primary-key="id"
            :sort-by.sync="sortByHO"
            show-empty
            empty-text="No matching records found"
            :sort-desc.sync="isSortDirDescHO"
          >
            <!-- Column: Number -->
            <template #cell(Number)="data">
              <div class="text-nowrap">
                <feather-icon
                  v-if="data.item.purchase_request_is_confirmed == null"
                  icon="CircleIcon"
                  class="default"
                />
                <feather-icon
                  v-if="
                    data.item.purchase_request_is_confirmed == true &&
                      data.item.purchase_request_is_finished == null
                  "
                  icon="CircleIcon"
                  class="danger"
                />
                <feather-icon
                  v-if="
                    data.item.purchase_request_is_confirmed == true &&
                      data.item.purchase_request_is_finished == true
                  "
                  icon="CircleIcon"
                  class="success"
                />
                <feather-icon
                v-if="data.item.purchase_request_has_files == true"
                icon="FileIcon" />&nbsp;
                <span class="align-text-top text-capitalize">{{
                  data.item.purchase_request_number
                }}</span>
              </div>
            </template>
            <!-- Column: Class -->
            <template #cell(Class)="data">
              <div class="text-nowrap">
                <span class="align-text-top text-capitalize">{{
                  data.item.purchase_request_class
                }}</span>
              </div>
            </template>
            <!-- Column: Category -->
            <template #cell(Category)="data">
              <div class="text-nowrap">
                <feather-icon size="18" class="mr-50" />
                <span class="align-text-top text-capitalize">{{
                  data.item.purchase_request_category
                }}</span>
              </div>
            </template>
            <!-- Column: Type -->
            <template #cell(Type)="data">
              {{
                data.item.purchase_request_is_center == true
                  ? "Head Office"
                  : "Cabang"
              }}
            </template>
            <!-- Column: Cost Center -->
            <template #cell(CostCenter)="data">
              ({{ data.item.cost_center ? data.item.cost_center.branch_code : '-' }})
              {{ data.item.cost_center ? data.item.cost_center.branch_city : '-' }}
            </template>
            <!-- Column: Notes -->
            <template #cell(Notes)="data">
              {{ truncateString(data.item.purchase_request_notes, 30) }}
            </template>
            <!-- Column: Status -->
            <template #cell(Status)="data">
              <b-badge pill class="text-capitalize">
                {{
                  data.item.purchase_request_is_confirmed
                    ? "CONFIRMED"
                    : "UNCONFIRMED"
                }}
              </b-badge>
            </template>
            <!-- Column: DateCreated -->
            <template #cell(DateCreated)="data">
              {{
                dateSimple(
                  data.item.purchase_request_created_time,
                  "YYYY-MM-DD HH:mm:ss"
                )
              }}
            </template>
            <!-- Column: Actions -->
            <template #cell(actions)="data">
              <b-dropdown
                variant="link"
                no-caret
                :right="$store.state.appConfig.isRTL"
              >
                <template #button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="align-middle text-body"
                  />
                </template>

                <b-dropdown-item @click="showDetail(data.item.purchase_request_id)">
                  <feather-icon icon="ZoomInIcon" />
                  <span class="align-middle ml-50">Detail</span>
                </b-dropdown-item>
                <b-dropdown-item @click="showReviseLog(data.item)">
                  <feather-icon icon="RefreshCwIcon" />
                  <span class="align-middle ml-50">Show Revise Log</span>
                </b-dropdown-item>
                <b-dropdown-item
                  @click="showMessages(data.item)"
                >
                  <feather-icon icon="MessageCircleIcon" />
                  <span class="align-middle ml-50">Chat</span>
                </b-dropdown-item>

                <b-dropdown-item
                  @click="showDocuments(data.item.purchase_request_id)"
                >
                  <feather-icon icon="FileIcon" />
                  <span class="align-middle ml-50">Attachments</span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="
                    hasPermission('purchase_request_confirm_lab_manager') == true &&
                      data.item
                        .purchase_request_is_confirmed_by_laboratory_manager == null
                  "
                  @click="
                    confirmPurchaseRequestByLabManager(
                      data.item.purchase_request_id
                    )
                  "
                >
                  <feather-icon icon="CheckIcon" />
                  <span class="align-middle ml-50">Confirm</span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="
                    hasPermission('purchase_request_confirm_regional_manager') ==
                      true &&
                      data.item.purchase_request_is_center == true &&
                      data.item
                        .purchase_request_is_confirmed_by_laboratory_manager !=
                        null &&
                      data.item.purchase_request_is_confirmed_by_regional_manager ==
                        null
                  "
                  @click="
                    confirmPurchaseRequestByRegionalManager(
                      data.item.purchase_request_id
                    )
                  "
                >
                  <feather-icon icon="CheckIcon" />
                  <span class="align-middle ml-50">Confirm</span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="
                    hasPermission('purchase_request_confirm_division_head') ==
                      true &&
                      data.item.purchase_request_is_center == true &&
                      data.item
                        .purchase_request_is_confirmed_by_laboratory_manager !=
                        null &&
                      data.item.purchase_request_is_confirmed_by_regional_manager !=
                        null &&
                      data.item.purchase_request_is_confirmed_by_division_head ==
                        null
                  "
                  @click="
                    confirmPurchaseRequestByDivisionHead(
                      data.item.purchase_request_id
                    )
                  "
                >
                  <feather-icon icon="CheckIcon" />
                  <span class="align-middle ml-50">Confirm</span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="
                    hasPermission('purchase_request_confirm_fa_coal_manager') ==
                      true &&
                      data.item.purchase_request_is_center == true &&
                      data.item
                        .purchase_request_is_confirmed_by_laboratory_manager !=
                        null &&
                      data.item.purchase_request_is_confirmed_by_regional_manager !=
                        null &&
                      data.item.purchase_request_is_confirmed_by_division_head !=
                        null &&
                      data.item.purchase_request_is_confirmed_by_fa_coal_manager ==
                        null
                  "
                  @click="
                    confirmPurchaseRequestByFACoalManager(
                      data.item.purchase_request_id
                    )
                  "
                >
                  <feather-icon icon="CheckIcon" />
                  <span class="align-middle ml-50">Confirm</span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="
                    hasPermission('purchase_request_confirm_operation_director') ==
                      true &&
                      data.item.purchase_request_is_center == true &&
                      data.item
                        .purchase_request_is_confirmed_by_laboratory_manager !=
                        null &&
                      data.item.purchase_request_is_confirmed_by_regional_manager !=
                        null &&
                      data.item.purchase_request_is_confirmed_by_division_head !=
                        null &&
                      data.item.purchase_request_is_confirmed_by_fa_coal_manager !=
                        null &&
                      data.item
                        .purchase_request_is_confirmed_by_operation_director == null
                  "
                  @click="
                    confirmPurchaseRequestByOperationDirector(
                      data.item.purchase_request_id
                    )
                  "
                >
                  <feather-icon icon="CheckIcon" />
                  <span class="align-middle ml-50">Confirm</span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="
                    hasPermission('purchase_request_confirm_vice_president') ==
                      true &&
                      data.item.purchase_request_is_center == true &&
                      data.item
                        .purchase_request_is_confirmed_by_laboratory_manager !=
                        null &&
                      data.item.purchase_request_is_confirmed_by_regional_manager !=
                        null &&
                      data.item.purchase_request_is_confirmed_by_division_head !=
                        null &&
                      data.item.purchase_request_is_confirmed_by_fa_coal_manager !=
                        null &&
                      data.item
                        .purchase_request_is_confirmed_by_operation_director !=
                        null &&
                      data.item.purchase_request_is_confirmed_by_vice_president ==
                        null
                  "
                  @click="
                    confirmPurchaseRequestByVicePresident(
                      data.item.purchase_request_id
                    )
                  "
                >
                  <feather-icon icon="CheckIcon" />
                  <span class="align-middle ml-50">Confirm</span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="
                    hasPermission('purchase_request_confirm_president_director') ==
                      true &&
                      data.item.purchase_request_is_center == true &&
                      data.item
                        .purchase_request_is_confirmed_by_laboratory_manager !=
                        null &&
                      data.item.purchase_request_is_confirmed_by_regional_manager !=
                        null &&
                      data.item.purchase_request_is_confirmed_by_division_head !=
                        null &&
                      data.item.purchase_request_is_confirmed_by_fa_coal_manager !=
                        null &&
                      data.item
                        .purchase_request_is_confirmed_by_operation_director !=
                        null &&
                      data.item.purchase_request_is_confirmed_by_vice_president !=
                        null &&
                      data.item
                        .purchase_request_is_confirmed_by_president_director == null
                  "
                  @click="
                    confirmPurchaseRequestByPresidentDirector(
                      data.item.purchase_request_id
                    )
                  "
                >
                  <feather-icon icon="CheckIcon" />
                  <span class="align-middle ml-50">Confirm</span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="
                    hasPermission('purchase_request_confirm_procurement_pusat') ==
                      true &&
                      data.item.purchase_request_is_center == true &&
                      data.item
                        .purchase_request_is_confirmed_by_laboratory_manager !=
                        null &&
                      data.item.purchase_request_is_confirmed_by_regional_manager !=
                        null &&
                      data.item.purchase_request_is_confirmed_by_division_head !=
                        null &&
                      data.item.purchase_request_is_confirmed_by_fa_coal_manager !=
                        null &&
                      data.item
                        .purchase_request_is_confirmed_by_operation_director !=
                        null &&
                      data.item.purchase_request_is_confirmed_by_vice_president !=
                        null &&
                      data.item
                        .purchase_request_is_confirmed_by_president_director !=
                        null &&
                      data.item
                        .purchase_request_is_confirmed_by_procurement_pusat == null
                  "
                  @click="
                    confirmPurchaseRequestByProcurementPusat(
                      data.item.purchase_request_id
                    )
                  "
                >
                  <feather-icon icon="CheckIcon" />
                  <span class="align-middle ml-50">Confirm</span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="userRole=='administrator'"
                  @click="deletePurchaseRequest(data.item.purchase_request_id)"
                >
                  <feather-icon icon="TrashIcon" />
                  <span class="align-middle ml-50">Delete</span>
                </b-dropdown-item>
              </b-dropdown>
            </template>
          </b-table>
          <div class="mx-2 mb-2">
            <b-row>
              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-start"
              >
              <span class="text-muted"
                  >Showing {{ dataMetaFinished.fromHO }} to {{ dataMetaFinished.toHO }} of
                  {{ dataMetaFinished.ofHO }} entries</span>
                  <span style="padding-left: 10px;">
                <feather-icon
                  icon="CircleIcon"
                  class="default"
                /> = Just Created,
                <feather-icon
                  icon="CircleIcon"
                  class="danger"
                /> =  Accepted By Lab Manager,
                <feather-icon
                  icon="CircleIcon"
                  class="warning"
                /> =  Accepted By FA Coal Manager,
                <feather-icon
                  icon="CircleIcon"
                  class="primary"
                /> =  Accepted By President Director
                <feather-icon
                  icon="CircleIcon"
                  class="success"
                />
                 = Finished
                <feather-icon
                  style="color:#036bfc"
                  icon="MessageCircleIcon"
                /> = Message,
                <feather-icon
                icon="FileIcon" /> = Attachment
              </span>
              </b-col>
              <!-- Pagination -->
              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-end"
              >
                <b-pagination
                  v-model="currentPageFinishedHO"
                  :total-rows="totalPurchaseRequestsFinishedHO"
                  :per-page="perPageFinishedHO"
                  first-number
                  last-number
                  class="mb-0 mt-1 mt-sm-0"
                  prev-class="prev-item"
                  next-class="next-item"
                >
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </b-col>
            </b-row>
          </div>
        </b-tab>
      </b-tabs>
      </b-tab>

      <b-tab title="Void">

      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <b-col cols="5">
            <label>Tanggal Awal</label>
            <b-form-datepicker id="start-datepicker" v-model="startDateFilterVoid" class="mb-2"></b-form-datepicker>
          </b-col>
          <b-col cols="5">
            <label>Tanggal Akhir</label>
            <b-form-datepicker id="end-datepicker" v-model="endDateFilterVoid" class="mb-2"></b-form-datepicker>
          </b-col>
          <b-col cols="2" class="d-flex align-items-center justify-content-center">
            <b-button variant="primary" @click="exportPR('HO')" :disabled="isLoadingExportVoid">
              <feather-icon icon="DownloadIcon" /> Export
              <b-spinner small type="grow" v-if="isLoadingExportVoid"></b-spinner>
            </b-button>
          </b-col>
        </b-row>
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="perPageVoid"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQueryVoid"
                class="d-inline-block mr-1"
                placeholder="Search..."
              />
            </div>
          </b-col>
        </b-row>
      </div>
      <b-table
        ref="refPurchaseRequestListTableVoid"
        class="position-relative"
        :items="fetchPurchaseRequestsVoid"
        responsive
        :fields="tableColumnsVoid"
        primary-key="id"
        :sort-by.sync="sortByVoid"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDescVoid"
      >
        <!-- Column: Number -->
        <template #cell(Number)="data">
          <div class="text-nowrap">
            <feather-icon
              v-if="data.item.chats !=null"
              style="color:#036bfc"
              icon="MessageCircleIcon"
            />
            <feather-icon
              v-if="data.item.purchase_request_is_confirmed_by_laboratory_manager == null"
              icon="CircleIcon"
              class="default"
            />
            <feather-icon
              v-if="
                data.item.purchase_request_is_confirmed_by_laboratory_manager != null &&
                  data.item.purchase_request_is_finished == null
              "
              icon="CircleIcon"
              class="danger"
            />
            <feather-icon
              v-if="
                data.item.purchase_request_is_confirmed_by_laboratory_manager != null &&
                  data.item.purchase_request_is_finished == true
              "
              icon="CircleIcon"
              class="success"
            />
            <feather-icon
            v-if="data.item.purchase_request_has_files == true"
            icon="FileIcon" />&nbsp;
            <span class="align-text-top text-capitalize">{{
              data.item.purchase_request_number
            }}</span>
          </div>
        </template>
        <!-- Column: Class -->
        <template #cell(Class)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize">{{
              data.item.purchase_request_class
            }}</span>
          </div>
        </template>
        <!-- Column: Category -->
        <template #cell(Category)="data">
          <div class="text-nowrap">
            <feather-icon size="18" class="mr-50" />
            <span class="align-text-top text-capitalize">{{
              data.item.purchase_request_category
            }}</span>
          </div>
        </template>
        <!-- Column: Type -->
        <template #cell(Type)="data">
          {{
            data.item.purchase_request_is_center == true
              ? "Head Office"
              : "Cabang"
          }}
        </template>
        <!-- Column: Cost Center -->
        <template #cell(CostCenter)="data">
          ({{ data.item.cost_center ? data.item.cost_center.branch_code : '-' }})
          {{ data.item.cost_center ? data.item.cost_center.branch_city : '-' }}
        </template>
        <!-- Column: Notes -->
        <template #cell(Notes)="data">
          {{ truncateString(data.item.purchase_request_notes, 30) }}
        </template>
        <!-- Column: Status -->
        <template #cell(Status)="data">
          <b-badge pill class="text-capitalize">
            {{
              data.item.purchase_request_is_confirmed
                ? "CONFIRMED"
                : "UNCONFIRMED"
            }}
          </b-badge>
        </template>
        <!-- Column: DateCreated -->
        <template #cell(DateCreated)="data">
          {{
            dateSimple(
              data.item.purchase_request_created_time,
              "YYYY-MM-DD HH:mm:ss"
            )
          }}
        </template>
        <!-- Column: VoidBy -->
        <template #cell(VoidBy)="data">
          <b-badge pill class="text-capitalize">
            {{
              data.item.deleter
                ? data.item.deleter.user_fullname
                : "-"
            }}
          </b-badge>
        </template>
        <!-- Column: VoidTime -->
        <template #cell(VoidTime)="data">
          {{
            data.item.purchase_request_deleted_time?
            dateSimple(
              data.item.purchase_request_deleted_time,
              "YYYY-MM-DD HH:mm:ss"
            ):"-"
          }}
        </template>
        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <b-dropdown-item @click="showDetail(data.item.purchase_request_id)">
              <feather-icon icon="ZoomInIcon" />
              <span class="align-middle ml-50">Detail</span>
            </b-dropdown-item>
                <b-dropdown-item @click="showReviseLog(data.item)">
                  <feather-icon icon="RefreshCwIcon" />
                  <span class="align-middle ml-50">Show Revise Log</span>
                </b-dropdown-item>
            <b-dropdown-item
              @click="showMessages(data.item)"
            >
              <feather-icon icon="MessageCircleIcon" />
              <span class="align-middle ml-50">Chat</span>
            </b-dropdown-item>
            <b-dropdown-item
              @click="showDocuments(data.item.purchase_request_id)"
            >
              <feather-icon icon="FileIcon" />
              <span class="align-middle ml-50">Attachments</span>
            </b-dropdown-item>
            <!-- Confirm Assets/FGS/Supplies By Purchasing -->
            <b-dropdown-item
              v-if="
                hasPermission('purchase_request_confirm_purchasing') == true &&
                data.item.purchase_request_is_center == false &&
                ( data.item.purchase_request_category == 'FGS' || data.item.purchase_request_category == 'Assets' || data.item.purchase_request_category == 'Supplies')  &&
                data.item.purchase_request_is_confirmed_by_purchasing_cabang == null
              "
              @click="
                confirmPurchaseRequestByPurchasing(
                  data.item.purchase_request_id
                )
              "
            >
              <feather-icon icon="CheckIcon" />
              <span class="align-middle ml-50">Confirm</span>
            </b-dropdown-item>
            <!-- Confirm Supplies By Lab Manager-->
            <b-dropdown-item
              v-if="
                hasPermission('purchase_request_confirm_lab_manager') == true &&
                data.item.purchase_request_is_center == false &&
                data.item.purchase_request_category == 'Supplies' &&
                data.item.purchase_request_is_confirmed_by_purchasing_cabang != null &&
                  data.item
                    .purchase_request_is_confirmed_by_laboratory_manager == null
              "
              @click="
                confirmPurchaseRequestByLabManager(
                  data.item.purchase_request_id
                )
              "
            >
              <feather-icon icon="CheckIcon" />
              <span class="align-middle ml-50">Confirm</span>
            </b-dropdown-item>
            <!-- Confirm Assets/FGS By Lab Manager -->
            <b-dropdown-item
              v-if="
                hasPermission('purchase_request_confirm_lab_manager') == true &&
                data.item.purchase_request_is_center == false &&
                ( data.item.purchase_request_category == 'FGS' || data.item.purchase_request_category == 'Assets')  &&
                data.item.purchase_request_is_confirmed_by_purchasing_cabang != null &&
                  data.item
                    .purchase_request_is_confirmed_by_laboratory_manager == null
              "
              @click="
                confirmPurchaseRequestByLabManager(
                  data.item.purchase_request_id
                )
              "
            >
              <feather-icon icon="CheckIcon" />
              <span class="align-middle ml-50">Confirm</span>
            </b-dropdown-item>
            <!-- Confirm HO By Lab Manager -->
            <b-dropdown-item
              v-if="
                hasPermission('purchase_request_confirm_lab_manager') == true &&
                data.item.purchase_request_is_center == true &&
                  data.item
                    .purchase_request_is_confirmed_by_laboratory_manager == null
              "
              @click="
                confirmPurchaseRequestByLabManager(
                  data.item.purchase_request_id
                )
              "
            >
              <feather-icon icon="CheckIcon" />
              <span class="align-middle ml-50">Confirm</span>
            </b-dropdown-item>
            <!-- Confirm Assets/FGS By Regional Manager -->
            <b-dropdown-item
              v-if="
                hasPermission('purchase_request_confirm_regional_manager') == true &&
                data.item.purchase_request_is_center == false &&
                ( data.item.purchase_request_category == 'FGS' || data.item.purchase_request_category == 'Assets')  &&
                data.item.purchase_request_is_confirmed_by_purchasing_cabang != null &&
                  data.item.purchase_request_is_confirmed_by_laboratory_manager != null &&
                  data.item.purchase_request_is_confirmed_by_regional_manager == null
              "
              @click="
                confirmPurchaseRequestByRegionalManager(
                  data.item.purchase_request_id
                )
              "
            >
              <feather-icon icon="CheckIcon" />
              <span class="align-middle ml-50">Confirm</span>
            </b-dropdown-item>
            <!-- Confirm Assets/FGS By Division Head -->
            <b-dropdown-item
              v-if="
                hasPermission('purchase_request_confirm_division_head') == true &&
                data.item.purchase_request_is_center == false &&
                ( data.item.purchase_request_category == 'FGS' || data.item.purchase_request_category == 'Assets')  &&
                data.item.purchase_request_is_confirmed_by_purchasing_cabang != null &&
                  data.item.purchase_request_is_confirmed_by_laboratory_manager != null &&
                  data.item.purchase_request_is_confirmed_by_regional_manager != null &&
                  data.item.purchase_request_is_confirmed_by_division_head == null
              "
              @click="
                confirmPurchaseRequestByDivisionHead(
                  data.item.purchase_request_id
                )
              "
            >
              <feather-icon icon="CheckIcon" />
              <span class="align-middle ml-50">Confirm</span>
            </b-dropdown-item>
            <!-- Confirm Assets/FGS By FA Coal Manager -->
            <b-dropdown-item
              v-if="
                hasPermission('purchase_request_confirm_fa_coal_manager') ==
                  true &&
                  data.item.purchase_request_is_center == false &&
                ( data.item.purchase_request_category == 'FGS' || data.item.purchase_request_category == 'Assets')  &&
                data.item.purchase_request_is_confirmed_by_purchasing_cabang != null &&
                  data.item.purchase_request_is_confirmed_by_laboratory_manager != null &&
                  data.item.purchase_request_is_confirmed_by_regional_manager != null &&
                  data.item.purchase_request_is_confirmed_by_division_head != null&&
                  data.item.purchase_request_is_confirmed_by_fa_coal_manager == null
              "
              @click="
                confirmPurchaseRequestByFACoalManager(
                  data.item.purchase_request_id
                )
              "
            >
              <feather-icon icon="CheckIcon" />
              <span class="align-middle ml-50">Confirm</span>
            </b-dropdown-item>
            <b-dropdown-item
              v-if="
                hasPermission('purchase_request_confirm_regional_manager') ==
                  true &&
                  data.item.purchase_request_is_center == true &&
                  data.item
                    .purchase_request_is_confirmed_by_laboratory_manager !=
                    null &&
                  data.item.purchase_request_is_confirmed_by_regional_manager ==
                    null
              "
              @click="
                confirmPurchaseRequestByRegionalManager(
                  data.item.purchase_request_id
                )
              "
            >
              <feather-icon icon="CheckIcon" />
              <span class="align-middle ml-50">Confirm</span>
            </b-dropdown-item>
            <b-dropdown-item
              v-if="
                hasPermission('purchase_request_confirm_division_head') ==
                  true &&
                  data.item.purchase_request_is_center == true &&
                  data.item
                    .purchase_request_is_confirmed_by_laboratory_manager !=
                    null &&
                  data.item.purchase_request_is_confirmed_by_regional_manager !=
                    null &&
                  data.item.purchase_request_is_confirmed_by_division_head ==
                    null
              "
              @click="
                confirmPurchaseRequestByDivisionHead(
                  data.item.purchase_request_id
                )
              "
            >
              <feather-icon icon="CheckIcon" />
              <span class="align-middle ml-50">Confirm</span>
            </b-dropdown-item>
            <b-dropdown-item
              v-if="
                hasPermission('purchase_request_confirm_fa_coal_manager') ==
                  true &&
                  data.item.purchase_request_is_center == true &&
                  data.item
                    .purchase_request_is_confirmed_by_laboratory_manager !=
                    null &&
                  data.item.purchase_request_is_confirmed_by_regional_manager !=
                    null &&
                  data.item.purchase_request_is_confirmed_by_division_head !=
                    null &&
                  data.item.purchase_request_is_confirmed_by_fa_coal_manager ==
                    null
              "
              @click="
                confirmPurchaseRequestByFACoalManager(
                  data.item.purchase_request_id
                )
              "
            >
              <feather-icon icon="CheckIcon" />
              <span class="align-middle ml-50">Confirm</span>
            </b-dropdown-item>
            <b-dropdown-item
              v-if="
                hasPermission('purchase_request_confirm_operation_director') ==
                  true &&
                  data.item.purchase_request_is_center == true &&
                  data.item
                    .purchase_request_is_confirmed_by_laboratory_manager !=
                    null &&
                  data.item.purchase_request_is_confirmed_by_regional_manager !=
                    null &&
                  data.item.purchase_request_is_confirmed_by_division_head !=
                    null &&
                  data.item.purchase_request_is_confirmed_by_fa_coal_manager !=
                    null &&
                  data.item
                    .purchase_request_is_confirmed_by_operation_director == null
              "
              @click="
                confirmPurchaseRequestByOperationDirector(
                  data.item.purchase_request_id
                )
              "
            >
              <feather-icon icon="CheckIcon" />
              <span class="align-middle ml-50">Confirm</span>
            </b-dropdown-item>
            <b-dropdown-item
              v-if="
                hasPermission('purchase_request_confirm_vice_president') ==
                  true &&
                  data.item.purchase_request_is_center == true &&
                  data.item
                    .purchase_request_is_confirmed_by_laboratory_manager !=
                    null &&
                  data.item.purchase_request_is_confirmed_by_regional_manager !=
                    null &&
                  data.item.purchase_request_is_confirmed_by_division_head !=
                    null &&
                  data.item.purchase_request_is_confirmed_by_fa_coal_manager !=
                    null &&
                  data.item
                    .purchase_request_is_confirmed_by_operation_director !=
                    null &&
                  data.item.purchase_request_is_confirmed_by_vice_president ==
                    null
              "
              @click="
                confirmPurchaseRequestByVicePresident(
                  data.item.purchase_request_id
                )
              "
            >
              <feather-icon icon="CheckIcon" />
              <span class="align-middle ml-50">Confirm</span>
            </b-dropdown-item>
            <b-dropdown-item
              v-if="
                hasPermission('purchase_request_confirm_president_director') ==
                  true &&
                  data.item.purchase_request_is_center == true &&
                  data.item
                    .purchase_request_is_confirmed_by_laboratory_manager !=
                    null &&
                  data.item.purchase_request_is_confirmed_by_regional_manager !=
                    null &&
                  data.item.purchase_request_is_confirmed_by_division_head !=
                    null &&
                  data.item.purchase_request_is_confirmed_by_fa_coal_manager !=
                    null &&
                  data.item
                    .purchase_request_is_confirmed_by_operation_director !=
                    null &&
                  data.item.purchase_request_is_confirmed_by_vice_president !=
                    null &&
                  data.item
                    .purchase_request_is_confirmed_by_president_director == null
              "
              @click="
                confirmPurchaseRequestByPresidentDirector(
                  data.item.purchase_request_id
                )
              "
            >
              <feather-icon icon="CheckIcon" />
              <span class="align-middle ml-50">Confirm</span>
            </b-dropdown-item>
            <b-dropdown-item
              v-if="
                hasPermission('purchase_request_confirm_procurement_pusat') ==
                  true &&
                  data.item.purchase_request_is_center == true &&
                  data.item
                    .purchase_request_is_confirmed_by_laboratory_manager !=
                    null &&
                  data.item.purchase_request_is_confirmed_by_regional_manager !=
                    null &&
                  data.item.purchase_request_is_confirmed_by_division_head !=
                    null &&
                  data.item.purchase_request_is_confirmed_by_fa_coal_manager !=
                    null &&
                  data.item
                    .purchase_request_is_confirmed_by_operation_director !=
                    null &&
                  data.item.purchase_request_is_confirmed_by_vice_president !=
                    null &&
                  data.item
                    .purchase_request_is_confirmed_by_president_director !=
                    null &&
                  data.item
                    .purchase_request_is_confirmed_by_procurement_pusat == null
              "
              @click="
                confirmPurchaseRequestByProcurementPusat(
                  data.item.purchase_request_id
                )
              "
            >
              <feather-icon icon="CheckIcon" />
              <span class="align-middle ml-50">Confirm</span>
            </b-dropdown-item>
            <b-dropdown-item
              v-if="userRole=='administrator'"
              @click="deletePurchaseRequest(data.item.purchase_request_id)"
            >
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">Delete</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted"
              >Showing {{ dataMeta.fromVoid }} to {{ dataMeta.toVoid }} of
              {{ dataMeta.ofVoid }} entries</span
            ><span style="padding-left: 10px;">
            <feather-icon
              icon="CircleIcon"
              class="default"
            /> = Just Created,
            <feather-icon
              icon="CircleIcon"
              class="danger"
            /> =  Accepted By Lab Manager,
                <feather-icon
                  icon="CircleIcon"
                  class="warning"
                /> =  Accepted By FA Coal Manager,
                <feather-icon
                  icon="CircleIcon"
                  class="primary"
                /> =  Accepted By President Director
            <feather-icon
              icon="CircleIcon"
              class="success"
            />
             = Finished
            <feather-icon
              style="color:#036bfc"
              icon="MessageCircleIcon"
            /> = Message,
            <feather-icon
            icon="FileIcon" /> = Attachment
          </span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPageVoid"
              :total-rows="totalPurchaseRequestsVoid"
              :per-page="perPageVoid"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
  </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BModal,
  VBModal,
  BListGroup,
  BListGroupItem,
  BTabs,
  BTab,
  BFormDatepicker,
  BForm,
  BFormFile,
} from "bootstrap-vue"
import axios from "axios"
import vSelect from "vue-select"
import store from "@/store"
import { getUserData } from "@/auth/utils"
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue"
import { ref, onUnmounted } from "@vue/composition-api"
import { avatarText } from "@core/utils/filter"
import moment from "moment"
import { saveAs } from 'file-saver'
import { VueHorizontalTimeline } from "vue-horizontal-timeline"
// import PurchaseRequestsListFilters from './PurchaseRequestsListFilters.vue'
import PurchaseRequestList from "./List"
import PurchaseRequestModule from "../purchaseRequestsModule"

const userRole = ""

const messageBox = ""
const to = ""
const cc = ""
const reason = ""
const purchaseRequestId = ""
const modalRevise = false
const messagesList = []
const statusItems = []
const items = []
const userId = getUserData().user_id
const modalDetailShow = false
const modalMessage = false
const modalUpload = false
const documentsList = []
const prId = ''
export default {
  components: {
    VueHorizontalTimeline,
    BListGroup,
    BListGroupItem,
    // PurchaseRequestsListFilters,
    BModal,
    VBModal,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    BTab,
    BTabs,
    BFormDatepicker,
    BForm,
    BFormFile,
  },
  data() {
    return {
      prId,
      modalRevise,
      modalUpload,
      documentsList,
      items,
      statusItems,
      purchaseRequestId,
      userId,
      messagesList,
      isLoadingExport: false,
      isLoadingExportFinished: false,
      userdata: getUserData(),
      messageListRef: 1,
    }
  },
  mounted() {
    this.userRole = this.$cookies.get("UserRole")
    this.$root.$on("refreshTable", text => {
      console.log(text) // here you need to use the arrow function
      this.$refs.refPurchaseRequestListTableAssetsFGS.refresh()
      this.$refs.refPurchaseRequestListTableSupplies.refresh()
      this.$refs.refPurchaseRequestListTableHO.refresh()
      this.$refs.refPurchaseRequestListTableFinishedAssetsFGS.refresh()
      this.$refs.refPurchaseRequestListTableFinishedSupplies.refresh()
      this.$refs.refPurchaseRequestListTableFinishedHO.refresh()
      this.$refs.refPurchaseRequestListTableVoid.refresh()
    })
  },
  setup() {
    const BRANCH_APP_STORE_MODULE_NAME = "app-purchase-request"
    // Register module
    if (!store.hasModule(BRANCH_APP_STORE_MODULE_NAME)) {
      store.registerModule(BRANCH_APP_STORE_MODULE_NAME, PurchaseRequestModule)
    }
    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(BRANCH_APP_STORE_MODULE_NAME)) {
        store.unregisterModule(BRANCH_APP_STORE_MODULE_NAME)
      }
    })

    const isAddNewPurchaseRequestSidebarActive = ref(false)

    const statusOptions = [
      { label: "Pending", value: "pending" },
      { label: "Active", value: "active" },
      { label: "Inactive", value: "inactive" },
    ]

    const {
    fetchPurchaseRequestsAssetsFGS,
    fetchPurchaseRequestsAssetsFGSNA,
    fetchPurchaseRequestsFinishedAssetsFGS,
    fetchPurchaseRequestsSupplies,
    fetchPurchaseRequestsSuppliesNA,
    fetchPurchaseRequestsFinishedSupplies,
    fetchPurchaseRequestsHO,
    fetchPurchaseRequestsHONA,
    fetchPurchaseRequestsFinishedHO,
    fetchPurchaseRequestsVoid,
    perPageOptions,
    tableColumnsAssetsFGS,
    tableColumnsAssetsFGSNA,
    tableColumnsFinishedAssetsFGS,
    tableColumnsSupplies,
    tableColumnsSuppliesNA,
    tableColumnsFinishedSupplies,
    tableColumnsHO,
    tableColumnsHONA,
    tableColumnsFinishedHO,
    tableColumnsVoid,
    perPageAssetsFGS,
    perPageAssetsFGSNA,
    perPageFinishedAssetsFGS,
    totalPurchaseRequestsAssetsFGS,
    totalPurchaseRequestsAssetsFGSNA,
    totalPurchaseRequestsFinishedAssetsFGS,
    currentPageAssetsFGS,
    currentPageAssetsFGSNA,
    currentPageFinishedAssetsFGS,
    perPageSupplies,
    perPageSuppliesNA,
    perPageFinishedSupplies,
    totalPurchaseRequestsSupplies,
    totalPurchaseRequestsSuppliesNA,
    totalPurchaseRequestsFinishedSupplies,
    currentPageSupplies,
    currentPageSuppliesNA,
    currentPageFinishedSupplies,
    perPageHO,
    perPageHONA,
    perPageFinishedHO,
    perPageVoid,
    totalPurchaseRequestsHO,
    totalPurchaseRequestsHONA,
    totalPurchaseRequestsFinishedHO,
    totalPurchaseRequestsVoid,
    currentPageHO,
    currentPageHONA,
    currentPageFinishedHO,
    currentPageVoid,
    searchQueryAssetsFGS,
    searchQueryAssetsFGSNA,
    searchQueryFinishedAssetsFGS,
    sortByAssetsFGS,
    sortByAssetsFGSNA,
    isSortDirDescAssetsFGS,
    isSortDirDescAssetsFGSNA,
    startDateFilterAssetsFGS,
    startDateFilterAssetsFGSNA,
    endDateFilterAssetsFGS,
    endDateFilterAssetsFGSNA,
    startDateFilterFinishedAssetsFGS,
    endDateFilterFinishedAssetsFGS,
    searchQuerySupplies,
    searchQuerySuppliesNA,
    searchQueryFinishedSupplies,
    sortBySupplies,
    sortBySuppliesNA,
    isSortDirDescSupplies,
    isSortDirDescSuppliesNA,
    startDateFilterSupplies,
    startDateFilterSuppliesNA,
    endDateFilterSupplies,
    endDateFilterSuppliesNA,
    startDateFilterFinishedSupplies,
    endDateFilterFinishedSupplies,
    searchQueryHO,
    searchQueryHONA,
    searchQueryFinishedHO,
    searchQueryVoid,
    sortByHO,
    sortByHONA,
    isSortDirDescHO,
    isSortDirDescHONA,
    startDateFilterHO,
    startDateFilterHONA,
    endDateFilterHO,
    endDateFilterHONA,
    startDateFilterFinishedHO,
    endDateFilterFinishedHO,
    startDateFilterVoid,
    endDateFilterVoid,
    dataMeta,
    dataMetaFinished,
    tableColumnsFinished,
    refPurchaseRequestListTableAssetsFGS,
    refPurchaseRequestListTableAssetsFGSNA,
    refPurchaseRequestListTableSuppliesNA,
    refPurchaseRequestListTableHONA,
    refPurchaseRequestListTableSupplies,
    refPurchaseRequestListTableHO,
    refPurchaseRequestListTableFinishedAssetsFGS,
    refPurchaseRequestListTableFinishedSupplies,
    refPurchaseRequestListTableFinishedHO,
    refPurchaseRequestListTableVoid,
    refetchDataAssetsFGS,
    refetchDataFinishedAssetsFGS,
    refetchDataSupplies,
    refetchDataFinishedSupplies,
    refetchDataHO,
    refetchDataFinishedHO,
    refetchDataVoid,
    resolveUserRoleVariant,
    resolveUserRoleIcon,
    resolveUserStatusVariant,
      // UI
      resolvePurchaseRequestRoleVariant,
      resolvePurchaseRequestRoleIcon,
      resolvePurchaseRequestStatusVariant,
    } = PurchaseRequestList()

    const roleOptions = [
      { label: "Admin", value: "admin" },
      { label: "Author", value: "author" },
      { label: "Editor", value: "editor" },
      { label: "Maintainer", value: "maintainer" },
      { label: "Subscriber", value: "subscriber" },
    ]

    return {
      to,
      cc,
      reason,
      messageBox,
      modalDetailShow,
      modalMessage,
      // Sidebar
      isAddNewPurchaseRequestSidebarActive,
      userRole,
    fetchPurchaseRequestsAssetsFGS,
    fetchPurchaseRequestsAssetsFGSNA,
    fetchPurchaseRequestsFinishedAssetsFGS,
    fetchPurchaseRequestsSupplies,
    fetchPurchaseRequestsSuppliesNA,
    fetchPurchaseRequestsFinishedSupplies,
    fetchPurchaseRequestsHO,
    fetchPurchaseRequestsHONA,
    fetchPurchaseRequestsFinishedHO,
    fetchPurchaseRequestsVoid,
    perPageOptions,
    tableColumnsAssetsFGS,
    tableColumnsFinishedAssetsFGS,
    tableColumnsSupplies,
    tableColumnsFinishedSupplies,
    tableColumnsHO,
    tableColumnsFinishedHO,
    tableColumnsVoid,
    perPageAssetsFGS,
    perPageFinishedAssetsFGS,
    totalPurchaseRequestsAssetsFGS,
    totalPurchaseRequestsFinishedAssetsFGS,
    currentPageAssetsFGS,
    currentPageFinishedAssetsFGS,
    perPageSupplies,
    perPageFinishedSupplies,
    totalPurchaseRequestsSupplies,
    totalPurchaseRequestsFinishedSupplies,
    currentPageSupplies,
    currentPageFinishedSupplies,
    perPageHO,
    perPageFinishedHO,
    perPageVoid,
    totalPurchaseRequestsHO,
    totalPurchaseRequestsFinishedHO,
    totalPurchaseRequestsVoid,
    currentPageHO,
    currentPageFinishedHO,
    currentPageVoid,
    searchQueryAssetsFGS,
    searchQueryFinishedAssetsFGS,
    sortByAssetsFGS,
    isSortDirDescAssetsFGS,
    startDateFilterAssetsFGS,
    endDateFilterAssetsFGS,
    startDateFilterFinishedAssetsFGS,
    endDateFilterFinishedAssetsFGS,
    searchQuerySupplies,
    searchQueryFinishedSupplies,
    sortBySupplies,
    isSortDirDescSupplies,
    startDateFilterSupplies,
    endDateFilterSupplies,
    startDateFilterFinishedSupplies,
    endDateFilterFinishedSupplies,
    searchQueryHO,
    searchQueryFinishedHO,
    searchQueryVoid,
    sortByHO,
    isSortDirDescHO,
    startDateFilterHO,
    endDateFilterHO,
    startDateFilterFinishedHO,
    startDateFilterVoid,
    endDateFilterFinishedHO,
    endDateFilterVoid,
    dataMeta,
    dataMetaFinished,
    tableColumnsFinished,
    tableColumnsAssetsFGSNA,
    tableColumnsSuppliesNA,
    tableColumnsHONA,
    perPageAssetsFGSNA,
    totalPurchaseRequestsAssetsFGSNA,
    currentPageAssetsFGSNA,
    perPageSuppliesNA,
    totalPurchaseRequestsSuppliesNA,
    currentPageSuppliesNA,
    perPageHONA,
    totalPurchaseRequestsHONA,
    currentPageHONA,
    searchQueryAssetsFGSNA,
    sortByAssetsFGSNA,
    isSortDirDescAssetsFGSNA,
    startDateFilterAssetsFGSNA,
    endDateFilterAssetsFGSNA,
    searchQuerySuppliesNA,
    sortBySuppliesNA,
    isSortDirDescSuppliesNA,
    startDateFilterSuppliesNA,
    endDateFilterSuppliesNA,
    searchQueryHONA,
    sortByHONA,
    isSortDirDescHONA,
    startDateFilterHONA,
    endDateFilterHONA,
    refPurchaseRequestListTableAssetsFGS,
    refPurchaseRequestListTableSupplies,
    refPurchaseRequestListTableHO,
    refPurchaseRequestListTableFinishedAssetsFGS,
    refPurchaseRequestListTableFinishedSupplies,
    refPurchaseRequestListTableFinishedHO,
    refPurchaseRequestListTableVoid,
    refPurchaseRequestListTableAssetsFGSNA,
    refPurchaseRequestListTableSuppliesNA,
    refPurchaseRequestListTableHONA,
    refetchDataAssetsFGS,
    refetchDataFinishedAssetsFGS,
    refetchDataSupplies,
    refetchDataFinishedSupplies,
    refetchDataHO,
    refetchDataFinishedHO,
    refetchDataVoid,
    resolveUserRoleVariant,
    resolveUserRoleIcon,
    resolveUserStatusVariant,

      // Filter
      avatarText,

      // UI
      resolvePurchaseRequestRoleVariant,
      resolvePurchaseRequestRoleIcon,
      resolvePurchaseRequestStatusVariant,
      roleOptions,
      statusOptions,
    }
  },
  methods: {
    checkRevise(item) {
      let isElig = false
      if (item.purchase_request_is_center === false) {
          if (this.userRole === 'purchasing-cabang' && item.purchase_request_is_confirmed_by_purchasing_cabang === null && item.purchase_request_is_confirmed_by_laboratory_manager == null) {
          isElig = true
          } else if (this.userRole === 'laboratory-manager' && item.purchase_request_is_confirmed_by_purchasing_cabang !== null && item.purchase_request_is_confirmed_by_laboratory_manager === null && item.purchase_request_is_confirmed_by_regional_manager == null) {
          isElig = true
          } else if (this.userRole === 'regional-manager' && item.purchase_request_is_confirmed_by_laboratory_manager !== null && item.purchase_request_is_confirmed_by_regional_manager === null && item.purchase_request_is_confirmed_by_division_head == null) {
          isElig = true
          } else if (this.userRole === 'division-head' && item.purchase_request_is_confirmed_by_regional_manager !== null && item.purchase_request_is_confirmed_by_division_head === null && item.purchase_request_is_confirmed_by_fa_coal_manager == null) {
          isElig = true
          } else if (this.userRole === 'fa-coal-manager' && item.purchase_request_is_confirmed_by_division_head !== null && item.purchase_request_is_confirmed_by_fa_coal_manager === null) {
          isElig = true
          }
      } else {
        if (this.userRole === 'laboratory-manager' && item.purchase_request_is_confirmed_by_laboratory_manager === null && item.purchase_request_is_confirmed_by_regional_manager == null) {
        isElig = true
        } else if (this.userRole === 'regional-manager' && item.purchase_request_is_confirmed_by_laboratory_manager !== null && item.purchase_request_is_confirmed_by_regional_manager === null && item.purchase_request_is_confirmed_by_division_head == null) {
        isElig = true
        } else if (this.userRole === 'division-head' && item.purchase_request_is_confirmed_by_regional_manager !== null && item.purchase_request_is_confirmed_by_division_head === null && item.purchase_request_is_confirmed_by_fa_coal_manager == null) {
        isElig = true
        } else if (this.userRole === 'fa-coal-manager' && item.purchase_request_is_confirmed_by_division_head !== null && item.purchase_request_is_confirmed_by_fa_coal_manager === null && item.purchase_request_is_confirmed_by_operation_director == null) {
        isElig = true
        } else if (this.userRole === 'operation-director' && item.purchase_request_is_confirmed_by_fa_coal_manager !== null && item.purchase_request_is_confirmed_by_operation_director !== null && item.purchase_request_is_confirmed_by_vice_president == null) {
        isElig = true
        } else if (this.userRole === 'vice-president' && item.purchase_request_is_confirmed_by_operation_director !== null && item.purchase_request_is_confirmed_by_vice_president == null && item.purchase_request_is_confirmed_by_president_director == null) {
        isElig = true
        } else if (this.userRole === 'president-director' && item.purchase_request_is_confirmed_by_vice_president !== null && item.purchase_request_is_confirmed_by_president_director === null) {
        isElig = true
        }
      }
      return isElig
    },
    setRevise(pr) {
      this.prId = pr
      this.modalRevise = true
    },
    sendRevise() {
      this.modalRevise = false
      const userToken = this.$cookies.get("userToken")
const headers = {
  "Content-Type": "application/json",
  Authorization: `Bearer ${userToken}`,
}
      const body = {
        purchase_request_id: this.prId,
        time: moment(),
      }
      const url = `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_REVISE_PURCHASE_REQUEST}`
      console.log(url)
      axios
        .post(url, body, { headers })
        .then(response => {
          this.submitButtonDisabled = false
          if (response.data.success === true) {
            this.$refs.refPurchaseRequestListTableAssetsFGS.refresh()
            this.$refs.refPurchaseRequestListTableSupplies.refresh()
            this.$refs.refPurchaseRequestListTableHO.refresh()
            this.$refs.refPurchaseRequestListTableSuppliesNA.refresh()
            this.$refs.refPurchaseRequestListTableAssetsFGSNA.refresh()
            this.$refs.refPurchaseRequestListTableHONA.refresh()
            this.$refs.refPurchaseRequestListTableFinishedAssetsFGS.refresh()
            this.$refs.refPurchaseRequestListTableFinishedSupplies.refresh()
            this.$refs.refPurchaseRequestListTableFinishedHO.refresh()
            this.$refs.refPurchaseRequestListTableVoid.refresh()
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Purchase Request Revise Success",
                icon: "CheckIcon",
                variant: "success",
              },
            })
            this.$router.replace({
              name: "apps-procurement-purchase-request-list",
            })
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Purchase Request Revise Failed",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            })
          }
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Purchase Request Failed",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          })
        })
    },
    exportPR() {
      const userToken = this.$cookies.get("userToken")
const headers = {
  "Content-Type": "application/json",
  Authorization: `Bearer ${userToken}`,
}
      const url = `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_EXPORT_PURCHASE_REQUEST}`
      this.isLoadingExport = true

      axios.get(url, {
          headers,
          responseType: 'arraybuffer',
          params: {
            date_start: this.startDateFilter,
            date_end: this.endDateFilter,
            type: "ongoing",
          },
      }).then(response => {
        let filename = "report_purchase_request"
        if (this.startDateFilter) {
          filename += `_${this.startDateFilter}`
        }
        if (this.endDateFilter) {
          filename += `_${this.endDateFilter}`
        }
        filename += ".xlsx"
        // Create a Blob from the response data
        const blob = new Blob([response.data], { type: response.headers['content-type'] })

        // Trigger the download using FileSaver.js
        saveAs(blob, filename)

        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Export Success",
            icon: "AlertTriangleIcon",
            variant: "success",
          },
        })
      }).finally(() => {
        this.isLoadingExport = false
      })
    },
    exportPRFinished() {
      const userToken = this.$cookies.get("userToken")
const headers = {
  "Content-Type": "application/json",
  Authorization: `Bearer ${userToken}`,
}
      const url = `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_EXPORT_PURCHASE_REQUEST}`
      this.isLoadingExportFinished = true

      axios.get(url, {
          headers,
          params: {
            startDate: this.startDateFilterFinished,
            endDate: this.endDateFilterFinished,
            type: "finished",
          },
      }).then(response => {
        if (response.data.success === true) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Export Success",
              icon: "AlertTriangleIcon",
              variant: "success",
            },
          })
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Export Failed, Something went wrong on the server",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          })
        }
      }).finally(() => {
        this.isLoadingExportFinished = false
      })
    },
    deletePurchaseRequest(id) {
      const userToken = this.$cookies.get("userToken")
const headers = {
  "Content-Type": "application/json",
  Authorization: `Bearer ${userToken}`,
}
      axios
        .get(
          `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_DELETE_MATERIAL_REQUEST}${id}`,
          { headers },
        )
        .then(response => {
          if (response.data.success === true) {
            this.$refs.refPurchaseRequestListTableAssetsFGS.refresh()
            this.$refs.refPurchaseRequestListTableAssetsFGSNA.refresh()
            this.$refs.refPurchaseRequestListTableSuppliesNA.refresh()
            this.$refs.refPurchaseRequestListTableHONA.refresh()
            this.$refs.refPurchaseRequestListTableSupplies.refresh()
            this.$refs.refPurchaseRequestListTableHO.refresh()
            this.$refs.refPurchaseRequestListTableFinishedAssetsFGS.refresh()
            this.$refs.refPurchaseRequestListTableFinishedSupplies.refresh()
            this.$refs.refPurchaseRequestListTableFinishedHO.refresh()
            this.$refs.refPurchaseRequestListTableVoid.refresh()
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: "Congratulation",
                icon: "CoffeeIcon",
                variant: "success",
                text: "Your data has been deleted!",
              },
            })
          } else {
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: "Notification",
                icon: "BellIcon",
                variant: "danger",
                text: "Something went wrong",
              },
            })
          }
        })
        .catch(err => {
          console.log(err)
        })
    },
    addNew() {
      this.$router.push({ name: "apps-purchase-request-create" })
    },
    dateSimple(value, format = "YYYY-MM-DD") {
      let dateRet = ""
      if (value !== null) {
        dateRet = moment(String(value)).format(format)
      } else {
        dateRet = null
      }
      return dateRet
    },
    showDetail(item) {
      localStorage.setItem("purchaseRequestDetailsId", item)
      this.$router.push({ name: "apps-purchase-request-detail" })
    },
    showReviseLog(item) {
      localStorage.setItem("purchaseRequestDetails", JSON.stringify(item))
      this.$router.push({ name: "apps-purchase-request-revise" })
    },
    hasPermission(permission) {
      const permissions = this.$cookies.get("userPermissions").split(",")
      return permissions.includes(permission) ? true : false
    },
    showMessages(purchaseRequest) {
      const userToken = this.$cookies.get("userToken")
const headers = {
  "Content-Type": "application/json",
  Authorization: `Bearer ${userToken}`,
}
      this.modalMessage = true
      this.purchaseRequestId = purchaseRequest.purchase_request_id
      this.messagesList.splice(0, this.messagesList.length)
      axios
        .get(
          `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_GET_PURCHASE_REQUESTS_SINGLE}${this.purchaseRequestId}`,
          { headers },
        )
        .then(async response => {
          if (response.data.success === true) {
            if (response.data.data.chats) {
              this.messagesList = JSON.parse(response.data.data.chats)
            }
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Something went wrong",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            })
          }
        })
    },
    confirmPurchaseRequestByLabManager(id) {
      localStorage.setItem("purchaseRequestDetailsId", id)
      this.$router.push({ name: "apps-purchase-request-confirm-lab-manager" })
    },
    confirmPurchaseRequestByPurchasing(id) {
      localStorage.setItem("purchaseRequestDetailsId", id)
      this.$router.push({ name: "apps-purchase-request-confirm-purchasing" })
    },
    confirmPurchaseRequestByRegionalManager(id) {
      localStorage.setItem("purchaseRequestDetailsId", id)
      this.$router.push({
        name: "apps-purchase-request-confirm-regional-manager",
      })
    },
    confirmPurchaseRequestByDivisionHead(id) {
      localStorage.setItem("purchaseRequestDetailsId", id)
      this.$router.push({
        name: "apps-purchase-request-confirm-division-head",
      })
    },
    confirmPurchaseRequestByFACoalManager(id) {
      localStorage.setItem("purchaseRequestDetailsId", id)
      this.$router.push({
        name: "apps-purchase-request-confirm-fa-coal-manager",
      })
    },
    confirmPurchaseRequestByOperationDirector(id) {
      localStorage.setItem("purchaseRequestDetailsId", id)
      this.$router.push({
        name: "apps-purchase-request-confirm-operation-director",
      })
    },
    confirmPurchaseRequestByVicePresident(id) {
      localStorage.setItem("purchaseRequestDetailsId", id)
      this.$router.push({
        name: "apps-purchase-request-confirm-vice-president",
      })
    },
    confirmPurchaseRequestByPresidentDirector(id) {
      localStorage.setItem("purchaseRequestDetailsId", id)
      this.$router.push({
        name: "apps-purchase-request-confirm-president-director",
      })
    },
    confirmPurchaseRequestByProcurementPusat(id) {
      localStorage.setItem("purchaseRequestDetailsId", id)
      this.$router.push({
        name: "apps-purchase-request-confirm-procurement-pusat",
      })
    },
    async sendMessage() {
      const userToken = this.$cookies.get("userToken")
const headers = {
  "Content-Type": "application/json",
  Authorization: `Bearer ${userToken}`,
}
      const Message = this.messageBox
      const newMessage = {
        user_id: userId,
        user_fullname: this.userdata.user_fullname,
        chat_text: Message,
        chat_created_time: moment(),
      }
      this.messagesList.push(newMessage)
      axios
        .post(
          `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_PURCHASE_REQUEST_CHATS}`,
          {
            id: this.purchaseRequestId,
            chats: this.messagesList,
          },
          { headers },
        )
        .then(async response => {
          if (response.data.success === true) {
            this.ref.messageListRef.refresh()
            this.$refs.refPurchaseRequestListTableAssetsFGS.refresh()
            this.$refs.refPurchaseRequestListTableAssetsFGSNA.refresh()
            this.$refs.refPurchaseRequestListTableSuppliesNA.refresh()
            this.$refs.refPurchaseRequestListTableHONA.refresh()
            this.$refs.refPurchaseRequestListTableSupplies.refresh()
            this.$refs.refPurchaseRequestListTableHO.refresh()
            this.$refs.refPurchaseRequestListTableFinishedAssetsFGS.refresh()
            this.$refs.refPurchaseRequestListTableFinishedSupplies.refresh()
            this.$refs.refPurchaseRequestListTableFinishedHO.refresh()
            this.$refs.refPurchaseRequestListTableVoid.refresh()
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Something went wrong",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            })
          }
        }).finally(() => {
          this.messageBox = ""
        })
    },
    showDocuments(id) {
const userToken = this.$cookies.get("userToken")
const headers = {
  "Content-Type": "application/json",
  Authorization: `Bearer ${userToken}`,
}
      this.purchaseRequestId = id
      this.modalUpload = true
      axios
        .get(
          `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_GET_DOCUMENTS}pr/${id}`,
          { headers },
        )
        .then(response => {
          if (response.data.success === true) {
            console.log("Fetching Data")
            if (response.data.data) {
              this.documentsList.splice(0, this.documentsList.length)
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Fetching Documents success",
                  icon: "CheckIcon",
                  variant: "success",
                },
              })
              response.data.data.map(value => {
                this.documentsList.push(value)
              })
            }
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Fetching Documents failed",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            })
          }
        })
    },
    handleFilesUpload(e) {
          this.file = e.target.files || e.dataTransfer.files
      },
    deleteAttachment(document) {
      const userToken = this.$cookies.get("userToken")
const headers = {
  "Content-Type": "application/json",
  Authorization: `Bearer ${userToken}`,
}
      const FileName = document.document_file_name
      const ObjectName = `${document.document_number}${FileName}`
      const body = {
        ObjectName,
        FileName,
        id: document.document_id,
      }
      axios
        .post(
          `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_DELETE_ASSET}`,
          body,
          { headers },
        )
        .then(response => {
          if (response.data.Status === 1) {
            this.showDocuments(document.document_purchase_request_id)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Delete Attachment success",
                icon: "CheckIcon",
                variant: "success",
              },
            })
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Something went wrong",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            })
          }
        })
    },
    downloadAttachment(document) {
      const userToken = this.$cookies.get("userToken")
const headers = {
  "Content-Type": "application/json",
  Authorization: `Bearer ${userToken}`,
}
      const FileName = document.document_file_name
      const ObjectName = `${document.document_number}${FileName}`
      const body = {
        ObjectName,
        FileName,
      }
      axios
        .post(
          `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_DOWNLOAD_ASSET}`,
          body,
          { headers },
        )
        .then(response => {
          if (response.data.Status === 1) {
            window.open(response.data.Payload, "_blank")
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Download Attachment success",
                icon: "CheckIcon",
                variant: "success",
              },
            })
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Something went wrong",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            })
          }
        })
    },
    truncateString(str, maxLength) {
      if (str.length <= maxLength) {
          return str
      } else {
          return `${str.slice(0, maxLength - 2)}..`
      }
  },
    editPurchaseRequest(data) {
      localStorage.setItem("prdetail", data.purchase_request_id)
      this.$router.push({ name: "apps-purchase-request-edit" })
    },
    sendDocument() {
      const userToken = this.$cookies.get('userToken')
      const header = {
        'Content-Type': 'multipart/form-dataitem',
        Authorization: `Bearer ${userToken}`,
      }
      const formData = new FormData()
      if (typeof this.file[0] !== 'undefined') {
      formData.append('file', this.file[0], this.file[0].name)
      formData.append('document_purchase_request_id', this.purchaseRequestId)
      formData.append('time', moment())
    axios
      .post(`${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_CREATE_DOCUMENT}`, formData, { headers: header })
      .then(async response => {
        if (response.data.success === true) {
            this.$refs.refPurchaseRequestListTableAssetsFGS.refresh()
            this.$refs.refPurchaseRequestListTableAssetsFGSNA.refresh()
            this.$refs.refPurchaseRequestListTableSuppliesNA.refresh()
            this.$refs.refPurchaseRequestListTableHONA.refresh()
            this.$refs.refPurchaseRequestListTableSupplies.refresh()
            this.$refs.refPurchaseRequestListTableHO.refresh()
            this.$refs.refPurchaseRequestListTableFinishedAssetsFGS.refresh()
            this.$refs.refPurchaseRequestListTableFinishedSupplies.refresh()
            this.$refs.refPurchaseRequestListTableFinishedHO.refresh()
            this.$refs.refPurchaseRequestListTableVoid.refresh()
            this.$refs.uploadFile.reset()
      axios
        .get(
          `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_GET_DOCUMENTS}pr/${this.purchaseRequestId}`,
          { headers: header },
        )
        .then(response2 => {
          if (response2.data.success === true) {
            if (response2.data.data) {
              this.documentsList.splice(0, this.documentsList.length)
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Fetching Documents success",
                  icon: "CheckIcon",
                  variant: "success",
                },
              })
              response2.data.data.map(value => {
                this.documentsList.push(value)
              })
            }
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Fetching Documents failed",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            })
          }
        })
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Confirm Purchase Request Success',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
        } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Something went wrong',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        }
        })
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Please Upload The Document!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss"
</style>
